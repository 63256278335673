import React, { Component, useEffect, useState } from "react";

import Sidebar from "react-sidebar";
import { I, Image, Label, RadioCustom, Div, If } from "../../../components";

import IconWhatsapp from "../../../assets/images/icones/icon-whatsapp.png";
import IconMensagem from "../../../assets/images/icones/icon-mensagem.png";
import { ReactComponent as IconMenuLeft } from "../../../assets/images/icon-menu-esquerdo.svg";
import IconApple from "../../../assets/images/icones/icon-apple.png";
import IconGooglePlay from "../../../assets/images/icones/icon-googleplay.png";
import IconFacebook from "../../../assets/images/icones/icon-facebook.png";
import IconInstagram from "../../../assets/images/icones/icon-instagram.png";

import IconArrowRight from "../../../assets/images/icones/icon-arrow-right.png";
import { ReactComponent as IconeLojaHome } from "../../../assets/images/icon-loja-home.svg";

import {
  Color,
  EndPoints,
  Http,
  Keys,
  Mask,
  Routes,
  Type,
  Utils,
} from "../../../utilities";
import { connect, useSelector } from "react-redux";

import "../header.css";
import UsuarioUtils from "../../../views/utils/UsuarioUtils";
import CarrinhoUtils from "../../../views/utils/CarrinhoUtils";
import Carrinho from "../../carrinho/Carrinho";
import { setProdutos } from "../../../redux/actions";
import Zindex from "../../../views/utils/Zindex";
import { EmpresaUtils } from "../..";

const styles = {
  menuCarrinho: {
    background: Color.ECOMMERCE.LARANJA,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 12,
    height: "45px",
    width: "45px",
    fontSize: "22px",
    color: "#FFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    
  },
  labelValorTotalCarrinho: {
    color: Color.EMPRESA.TERCIARIA,
  },
  labelSAC: {
    color: Color.ECOMMERCE.LARANJA,
    fontSize: 12,
  },
  labelServicoAtendimento: {
    color: Color.ECOMMERCE.LARANJA,
    fontSize: 12,
  },
  labelTelefone: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
  },
  labelEmail: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
  },
  labelQuantidadeTotalItens: {
    position: "absolute",
    top: 35,
    right: 12,
    width: "25px",
    backgroundColor: Color.EMPRESA.TERCIARIA,
    color: "white",
    borderRadius: "50%",
    padding: 5,
    fontSize: 10,
    textAlign: "center",
  },
  btnRegistrar: {
    backgroundColor: "#f2f2f2",
    padding: 10,
    borderRadius: 10,
    color: "#878787",
  },
  btnEntrar: {
    backgroundColor: "#f2f2f2",
    padding: 10,
    borderRadius: 10,
    color: Color.ECOMMERCE.LARANJA,
  },
  containerRodape: {
    backgroundColor: Color.ECOMMERCE.MARRON_ESCURO,
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "2%",
    paddingBottom: "2%",
    width: "100%",
    position: window.innerHeight < 600 ? "relative" : "fixed",
    bottom: 0,
  },
  containerRodapeMobileGrande: {
    backgroundColor: Color.ECOMMERCE.MARRON_ESCURO,
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "2%",
    paddingBottom: "2%",
    width: "100%",
    position: "absolute",
    bottom: 0,
  },
  containterLocaisEntrega: {
    height: 155,
    overflowY: "scroll",
  },
};

function IconeCarrinho(props) {
  const [quantidade, setQuantidade] = useState("0")
  const carrinho = useSelector(state => state)

  useEffect(() => {
    let itens = carrinho.carrinhoState.carrinho.itens.length || "0"
    setQuantidade(itens)
  }, [carrinho])

  return (
    <Div
      className="d-flex flex-column align-items-end"
      style={{ width: 88 }}
      handlerClick={() => {
        props.onSetSidebarOpen(true);
      }}
    >
      <Div>
        <I
          icon={Type.ICON.SALE_BASKET}
          style={{
            ...styles.menuCarrinho,
            background: Color.EMPRESA.PRIMARIA,
          }}
          pointer
        />
      </Div>
      <Label
        value={quantidade || "0"}
        style={styles.labelQuantidadeTotalItens}
        family="Bold"
        pointer
      />
      {/*<Label
          value={"10 produtos"}
          style={styles.labelValorTotalCarrinho}
          family="Bold"
          margin="2"
                /> */}
    </Div>
  )
}


class HeaderNavegaoWebMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
      isSideBarMenuLeft: true,
      propsSideBar: {},
      carrinho: props.carrinho,
      abrirNossasLojas: false,
      abrirMapa: false,
      abrirSobreEmpresa: false,
      abrirPoliticaRegulamento: false,
      abrirMenuUsuario: false,
      sobreEmpresa: {},
      politicaEmpresa: {},
      filialMapa: {},
    };

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      propsSideBar: this.getPropsSideBarMenuLeft(),
    });
  }

  adicionarItemNoCarrinho(produto) {
    let { carrinho, produtos } = this.props;
    CarrinhoUtils.adicionarItemNoCarrinho(
      produto,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      setProdutos,
      null,
      this.state.parametros
    );
  }

  removerItemCarrinho(produto) {
    let { carrinho, produtos } = this.props;

    produto.quantidade = 0;

    CarrinhoUtils.adicionarItemNoCarrinho(
      produto,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      setProdutos,
      null,
      this.state.parametros
    );
  }


  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open }, () => {
      if (open) {
        Utils.toggleScrollBody("hidden");
      } else {
        Utils.toggleScrollBody("auto");
      }
    });
  }

  getPropsSideBarMenuLeft = () => {
    return {
      sidebar: this.renderTemplateMenuCarrinho(),
      open: this.state.sidebarOpen,
      onSetOpen: open => this.setState({ sidebarOpen: open }, () => {
        if (open) {
          Utils.toggleScrollBody("hidden");
        } else {
          Utils.toggleScrollBody("auto");
        }
      }),
      pullRight: true,
      styles: {
        sidebar: {
          background: "white",
          width: Utils.detectMob() ? "80%" : "25%",
          height: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: 1011,
        },
      },
    };
  };


  renderTemplateMenuCarrinho = () => {
    return (
      <Carrinho
        {...this.props}
        handlerAddItem={(produto) => this.adicionarItemNoCarrinho(produto)}
        handlerRemoveItem={(produto) => this.removerItemCarrinho(produto)}
        handlerFecharMenu={() => this.onSetSidebarOpen(false)}
      />

    );
  };



  // renderTemplateMenuCarrinho = () => {
  //   let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);
  //   return (
  //     <CarrinhoMobile
  //       {...this.props}
  //       handlerAddItem={(produto) => this.props.handlerAddItem(produto)}
  //       handlerRemoveItem={(produto) => this.props.handlerRemoveItem(produto)}
  //       handlerFecharMenu={() => this.onSetSidebarOpen(false)}
  //       corPrimaria={corPrimaria}
  //     />
  //   );
  // };

  getPropsSideBarMenuCarrinho = () => {
    return {
      sidebar: this.renderTemplateMenuCarrinho(),
      open: this.state.sidebarOpen,
      onSetOpen: this.onSetSidebarOpen,
      pullRight: true,
      styles: {
        sidebar: {
          background: "white",
          width: "100%",
          height: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: Zindex.MENU,
        },
      },
    };
  };

  getPropsSideBarMenuUsuario = () => {
    return {
      sidebar: this.renderMenuUsuario(),
      open: this.state.sidebarOpen,
      onSetOpen: this.onSetSidebarOpen,
      pullRight: true,
      styles: {
        sidebar: {
          background: "white",
          width: "100%",
          height: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: Zindex.MENU,
        },
      },
    };
  };

  getPropsSideBar() {
    const { isSideBarMenuLeft, abrirMenuUsuario } = this.state;

    let propsSideBar = null;
    if (isSideBarMenuLeft) {
      propsSideBar = this.getPropsSideBarMenuLeft();
    } else {
      if (abrirMenuUsuario) {
        propsSideBar = this.getPropsSideBarMenuUsuario();
      } else {
        propsSideBar = this.getPropsSideBarMenuCarrinho();
      }
    }

    return propsSideBar;
  }

  render() {
    const { empresa } = this.props;
    let parametros = Utils.isValueValid(empresa) ? empresa.parametros : {};

    return (
      <Div inline="between">
        <Sidebar {...this.getPropsSideBar()} className="scrollhost">
          <Div inline="between" style={{ padding: "0px 11px" }}>
            {/* <IconMenuLeft
              className="gray-svg"
              style={{
              //  marginLeft: "12px",
                marginTop: "5px",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() =>
                this.setState({ isSideBarMenuLeft: true }, () => {
                  this.onSetSidebarOpen(true);
                })
              }
            /> */}

            <Image
              src={parametros.urlFoto}
              width="130px"
              className="imagelogo imagelogo-mobile"
              handlerClick={() => this.props.history.replace("/")}
              pointer
              style={{ marginTop: 10 }}
            />
            <Div inline="start">
              <IconeCarrinho onSetSidebarOpen={this.onSetSidebarOpen} />
            </Div>
          </Div>
        </Sidebar>
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  let div = document.getElementById("forceUpdateGambiarra");
  if (Utils.isValueValid(div)) {
    setTimeout(() => {
      div.click();
    }, 100);
  }
  return {
    ...store.carrinhoState,
    ...store.parametrosPedidoState,
  };
};

export default connect(
  mapStateToProps
)(HeaderNavegaoWebMobile);
