import React, { Component, useEffect, useState } from "react";
import { Div, I, If, Image, InputDefault, Label, ModalNotification, Notification, Row, TextArea, Title } from "../../components";
import { Color, Mask, Type, Utils } from "../../utilities";
import { useSelector } from "react-redux";
import { ProdutoUtils } from "..";
import CarrinhoUtils from "../utils/CarrinhoUtils";
import { ReactComponent as IconProduto } from "../../assets/images/icon-produto.svg";
import "./carrinho.css";
import OrcamentoService from "../../service/OrcamentoService";


const styles = {
  containerCarrinho: {
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  labelBlue: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
    lineHeight: "10px",
  },
  labelRed: {
    color: "#EA4E25",
    fontSize: 16,
    lineHeight: "10px",
  },
  taxaEntregaGratis: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
    lineHeight: "10px",
    textDecoration: "line-through",
  },
  labelCinza: {
    color: "#878787",
    fontSize: 14,
    lineHeight: "10px",
  },
  labelDescricaoItem: {
    fontSize: 14,
    lineHeight: "14px",
    color: "#878787",
    width: 150,
  },
  labelNenhumItemNoCarrinho: {
    fontSize: 14,
    lineHeight: "14px",
    color: "#878787",
  },
  labelPrecoItem: {
    fontSize: 14,
    color: Color.ECOMMERCE.LARANJA,
  },
  labelPrecoItemAtacado: {
    fontSize: 10,
    color: Color.ECOMMERCE.MARRON_ESCURO,
  },
  image: {
    // border: "1px #878787 solid",
    borderRadius: "10px",
    backgroundColor: "white",
    width: 70,
    height: 70,
  },
  containterPagamento: {
    textAlign: "center",
    bottom: 0,
    position: "absolute",
    width: "100%",
  },
  labelIrPagamento: {
    color: Color.ECOMMERCE.WHITE,
    padding: 10,
    width: "100%",
    marginBottom: 0,
  },
  backgroundContainer: {
    backgroundColor: `rgb(242, 242, 242)`,
    width: "100%",
    height: "90vh",
    margin: "0em",
    overflowY: "auto",
  },
  backgroundContainerItem50: {
    backgroundColor: `rgb(242, 242, 242)`,
    width: "100%",
    height: "65vh",
    margin: "0em",
    overflowY: "auto",
  },

  labelValidacao: {
    fontSize: "12px",
    margin: "-14px 0px 5px 5px"
  }
};

function ModalComponente(props) {

  const [nome, setNome] = useState("")
  const [email, setEmail] = useState("")
  const [tel, setTel] = useState("")
  const [cpfCnpj, setCpfCnpj] = useState("")
  const [obs, setObs] = useState("")
  const [formValido, setFormValido] = useState(false)

  function confirm() {

    let obj = {

      idlancamentosOrcamento: Utils.uuidv4(),
      nomeCliente: nome,
      cpfCnpj: cpfCnpj,
      telefoneCliente: tel,
      emailCliente: email,
      obs: obs,
      itens: props.itens
    }

    if(nome === "") {
      setFormValido(true)
      return
    }

    if(email === "") {
      setFormValido(true)
      return
    }

    if(tel === "") {
      setFormValido(true)
      return
    }

    if(cpfCnpj === "") {
      setFormValido(true)
      return
    }

    // let mensagem = `
    // Nome: ${nome} 
    // Email: ${email}
    // Telefone: ${tel}
    // Obs: ${obs}
    // Itens: ${props.itens.map(item => {
    //   return (`Cod.: ${item.codigoBarras}, Descrição: ${item.descricao} qtd: ${item.quantidade} - `)
    // })}
    // `
    // enviarMensagemWhatsapp(mensagem)
    // props.close()

    OrcamentoService.cadastrarOrcamento(obj).then( data => {
      if(data) {
        props.history.push("/finalizacao");
        props.close()
      } else {
        Notification.danger("Falha ao enviar orçamento");
      }
    })
  }

  function enviarMensagemWhatsapp(mensagem) {

    let options = `top=${window.innerHeight / 4},left=${window.innerWidth / 4
      },location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=0`;

    let empresa = props.empresa || {};
    let parametros = Utils.isValueValid(empresa) ? empresa.parametros : {};

    window.open(
      `https://api.whatsapp.com/send?phone=55${Mask.clearMask(
        parametros.telefoneWhatsapp
      )}&text=${mensagem}`,
      "self",
      options
    );

    Utils.refreshPage();
  }

  // const carrinhoRedux = useSelector(state => state)

  return (
    <Div col="12">
      <InputDefault
        label="Nome Completo: *"
        handlerChange={(e) => {
          setNome(e.target.value)
        }}

        value={nome}
      />
      <If and value1={nome === "" && formValido}>
        <Row col="12">
          <Title
            value={"Este Campo é Obrigatório *"}
            type="h6"
            className="label-valor-total-carrinho text-danger"
            style={{ ...styles.labelValidacao, margin: "-16px 0px 22px -10px" }}
            family="Bold"
          />
        </Row>
      </If>

      <Row responsive="12" margin="0" marginBottom="3" padding="0">
        <InputDefault
          padd="0"
          paddingRight="3"
          responsive="6"
          label="Telefone ou Whatsapp: *"
          handlerChange={(e) => {
            setTel(Mask.getValueMaskPhone(e.target.value))
          }}
          value={tel}
        />
        <If and value1={tel === "" && formValido === true}>
          <Title
            value={"Este Campo é Obrigatório *"}
            type="h6"
            className="label-valor-total-carrinho text-danger"
            style={{ 
              ...styles.labelValidacao, 
              position: "absolute",
              margin: "72px 0px 20px 5px",

            }}
            family="Bold"
          />
        </If>

        <InputDefault
          responsive="6"
          padd="0"
          label="CPF/CNPJ:"
          handlerChange={(e) => {
            setCpfCnpj(Mask.getValueMaskCpfCnpj(e.target.value))
          }}
          value={cpfCnpj}
        />

        <If and value1={cpfCnpj === "" && formValido === true}>
          <Title
            value={"Este Campo é Obrigatório *"}
            type="h6"
            className="label-valor-total-carrinho text-danger"
            style={{ 
              ...styles.labelValidacao, 
              position: "absolute",
              margin: "72px 0px 22px 0px",
              right: 0
            }}
            family="Bold"
          />
        </If>

      </Row>
      <InputDefault
        label="Email:"
        handlerChange={(e) => {
          setEmail(e.target.value)
        }}
        value={email}
      />
      <If and value1={email === "" && formValido === true}>
        <Row col="12">
          <Title
            value={"Este Campo é Obrigatório *"}
            type="h6"
            className="label-valor-total-carrinho text-danger"
            style={{ ...styles.labelValidacao, margin: "-16px 0px 22px -10px" }}
            family="Bold"
          />
        </Row>
      </If>
      <TextArea
        paddingLeft="0"
        paddingRight="0"
        responsive="12"
        placeHolder="Observações"
        style={{ minHeight: "124px" }}
        handlerChange={(e) => {
          setObs(e.target.value)
        }}
      />


      <Div
        className="d-flex flex-row justify-content-center"
        style={{ height: 60, padding: "10px 0", marginTop: "15px" }}
      >

        <Label
          handlerClick={() => { props.close() }
          }
          family="SemiBold"
          style={{
            backgroundColor: Color.ECOMMERCE.CINZA,
            color: "white",
            // width: widthButtons,
            minWidth: "130px",
            borderRadius: "5px",
            textAlign: "center",
            height: "100%",
            ...props.styleButtonCancel,
          }}
          className="d-flex justify-content-center align-items-center"
          value={
            props.labelButtonCancel
              ? props.labelButtonCancel
              : "Cancelar"
          }
          pointer
        />

        <Label
          handlerClick={() => confirm()}
          family="SemiBold"
          style={{
            backgroundColor: Color.EMPRESA.PRIMARIA,
            color: "white",
            //width: widthButtons,
            minWidth: "130px",
            borderRadius: "5px",
            marginLeft: 20,
            padding: 10,
            height: "100%",
            ...props.styleButtonConfirm,
          }}
          className="d-flex justify-content-center align-items-center"
          value={
            props.labelButtonConfirm
              ? props.labelButtonConfirm
              : "Confirmar"
          }
          pointer
        />

      </Div>
    </Div>
  )
}

function IconProdutoCarrinho(props) {
  const carrinhoRedux = useSelector(state => state)

  return (
    <Div
      bg={Color.NODE.WHITE}
      className="px-auto"
      style={{ overflow: "hidden" }}
    >
      <Div inline="start" style={{ paddingLeft: "80px" }}>
        <Div className="d-flex flex-column  align-self-start">
          <IconProduto style={{ height: "31px", marginTop: "15px" }} />
        </Div>
        <Div
          className="d-flex flex-column align-self-start"
          marginTop="2"
          marginBottom="2"
        >
          <Label
            marginTop="2"
            marginLeft="2"
            value={`${carrinhoRedux.carrinhoState.carrinho.itens.length} produtos`}
            style={{ ...styles.labelCinza, lineHeight: "37px", }}
            family="Bold"
          />
        </Div>
      </Div>
    </Div>
  )
}

function RenderTemplateItensCarrinho(props) {

  const [itens, setItens] = useState([])
  const [idsItensCarrinhoParaRolagem, setIdsItensCarrinhoParaRolagem] = useState([])
  const carrinhoRedux = useSelector(state => state)

  useEffect(() => {
    let itensCarrinho = carrinhoRedux.carrinhoState.carrinho.itens || []
    let idItens = []
    for (let i = 0; i < Array.from(itens).length; i++) {
      idItens.push(Utils.uuidString());
    }

    setItens(itensCarrinho)
    setIdsItensCarrinhoParaRolagem(idItens)

  }, [carrinhoRedux])

  const addQuantidadeItem = (item) => {
    CarrinhoUtils.addQuantidadeItem(item);
    props.handlerAddItem(item);
  }

  const removeQuantidadeItem = (item) => {
    CarrinhoUtils.removeQuantidadeItem(item);
    props.handlerAddItem(item);
  }

  return (
    <Div style={styles.backgroundContainer}>
      {Array.from(itens)
        .reverse()
        .map((item, index) => {
          return (
            <Div
              className="border-bottom d-flex align-items-self-start justify-content-between"
              id={idsItensCarrinhoParaRolagem[index]}
            >
              <Div
                style={{ height: 140, transform: `translateY(-25px)` }}
                className="mx-auto"
              >
                <Div
                  key={index}
                  inline="start"
                  paddingTop="1"
                  paddingLeft="3"
                  paddingBottom="1"
                  style={{ height: 100 }}
                >
                  <Div>
                    <Label
                      style={{
                        transform: `translateY(70px)`,
                        marginLeft: 10,
                        backgroundColor: Color.EMPRESA.TERCIARIA,
                        color: "white",
                        padding: 2,
                        width: 50,
                        fontSize: 12,
                        textAlign: "center",
                        borderRadius: 5,
                      }}
                      family="Light"
                      value={props.getQuantidadeLabel(item)}
                    />
                    <Image
                      src={item.urlImagemPrincipalThumbnail}
                      responsive
                      styleImage={styles.image}
                      border
                    />
                  </Div>
                  <Div
                    className="d-flex flex-column justify-content-start"
                    marginTop="2"
                    marginLeft="2"
                    style={{ transform: `translateY(20px)` }}
                  >
                    <Label
                      value={String(item.descricao).substr(0, 50)}
                      style={styles.labelDescricaoItem}
                    />
                    {/* <Label
                      value={Mask.formatValueBr(item.valorSubTotalItem)}
                      style={{
                        ...styles.labelPrecoItem,
                        color: Color.EMPRESA.PRIMARIA
                      }}
                      bold
                    /> */}
                  </Div>
                </Div>
                <Div
                  inline="center"
                  style={{
                    color: Color.EMPRESA.PRIMARIA,
                    width: 100,
                    padding: 5,
                  }}
                >
                  <I
                    icon={Type.ICON.MINUS_SQUARE}
                    style={{ fontSize: 30 }}
                    margin="1"
                    pointer
                    handlerClick={() => {
                      removeQuantidadeItem(item)
                    }}
                  />
                  <I
                    icon={Type.ICON.PLUS_SQUARE}
                    style={{ fontSize: 30 }}
                    margin="1"
                    pointer
                    handlerClick={() => {
                      if (props.isProdutoValido(item)) {
                        addQuantidadeItem(item)
                      }
                    }}
                  />
                </Div>
              </Div>
              <I
                icon={Type.ICON.CLOSEX}
                marginRight="3"
                marginTop="3"
                colorText={Color.NODE.DANGER}
                pointer
                handlerClick={() => {
                  props.handlerRemoveItem(item)
                }}
              />
            </Div>
          );
        })}
    </Div>
  );
};

class Carrinho extends Component {
  constructor(props) {
    super(props);

    this.state = {
      carrinho: props.carrinho,
      posicaoCarrinho: 0,
      parametros: {},
      nome: "",
      tel: "",
      email: "",
    };
  }

  isProdutoValido(produto) {
    return ProdutoUtils.validarPreco(
      produto,
      "Produto indisponível , não possui preço de venda"
    );
  }

  getQuantidadeLabel(item) {
    let quantidade = item.quantidade || 0;

    let labelQuantidade = `${quantidade} ${item.possuiQuantidadeFracionada ? "g" : "un"
      }`;

    return quantidade > 0 ? labelQuantidade : "0";
  }

  modalPedidoCompra() {
    let { itens } = this.props.carrinho;
    let { empresa } = this.props.empresa
    ModalNotification.render(
      "Pedido Lista de Compra",
      close => (<ModalComponente history={this.props.history} empresa={empresa} itens={itens} close={close} setState={() => this.setState} />)
      // handlerConfirm: () => { console.log(this.state.nome) },
      // handlerCancel: () => { },
      // labelButtonCancel: "Cancelar",
    );
  }

  getPrevisaoEntrega(parametros) {
    let previsao = "";

    if (Utils.isValueValid(parametros)) {
      let horarios = Array.from(parametros.horarios);
      previsao = `${horarios[0].dataEntrega} - ${horarios[horarios.length - 1].dataEntrega
        }`;
    }

    return previsao;
  }

  render() {

    const { itens } = this.props.carrinho;

    let idsItensCarrinhoParaRolagem = [];

    for (let i = 0; i < Array.from(itens).length; i++) {
      idsItensCarrinhoParaRolagem.push(Utils.uuidString());
    }

    let empresa = this.props.empresa || {};
    let parametrosEmpresa = Utils.isValueValid(empresa)
      ? empresa.parametros
      : {};

    return (
      <Div>
        <IconProdutoCarrinho />
        <If and value1={Array.from(itens).length > 0}>
          {
            <RenderTemplateItensCarrinho
              handlerRemoveItem={this.props.handlerRemoveItem}
              handlerAddItem={this.props.handlerAddItem}
              isProdutoValido={this.isProdutoValido}
              getQuantidadeLabel={this.getQuantidadeLabel}
              parametros={parametrosEmpresa}
            />
          }

          <Div style={styles.containterPagamento}>
            <Div inline="center" className="bg-carrinho">
              <I
                icon={Type.ICON.ARROW_CHEV_DOWN}
                style={{ fontSize: 20, margin: 10, color: "#C4C4C4" }}
                pointer
                handlerClick={() => {
                  let itemCarrinho = document.getElementById(
                    idsItensCarrinhoParaRolagem[this.state.posicaoCarrinho + 1]
                  );
                  if (Utils.isValueValid(itemCarrinho)) {
                    itemCarrinho.scrollIntoView({ behavior: "smooth" });
                    this.setState({
                      posicaoCarrinho: this.state.posicaoCarrinho + 1,
                    });
                  }
                }}
              />

              <I
                icon={Type.ICON.ARROW_CHEV_UP}
                style={{ fontSize: 20, margin: 10, color: "#C4C4C4" }}
                pointer
                handlerClick={() => {
                  let itemCarrinho = document.getElementById(
                    idsItensCarrinhoParaRolagem[this.state.posicaoCarrinho - 1]
                  );
                  if (Utils.isValueValid(itemCarrinho)) {
                    itemCarrinho.scrollIntoView({ behavior: "smooth" });
                    this.setState({
                      posicaoCarrinho: this.state.posicaoCarrinho - 1,
                    });
                  }
                }}
              />
            </Div>

            <Label
              value="Enviar Orçamento"
              style={{
                ...styles.labelIrPagamento,
                backgroundColor: parametrosEmpresa.corPrimaria
              }}
              family="SemiBold"
              pointer
              handlerClick={() => {
                this.modalPedidoCompra()
              }}
            />
          </Div>
        </If>
        <If and value1={Array.from(itens).length === 0}>
          <Div inline="center" marginTop="3" className="border">
            <I icon={Type.ICON.INFO} colorText={Color.NODE.WARNING} />
            <Label
              margin="3"
              value="Nenhum item foi adicionado a lista"
              style={styles.labelNenhumItemNoCarrinho}
              family="Light"
            />
          </Div>
        </If>
      </Div>
    );
  }
}

export default Carrinho;
