import React, { Component } from "react";
import {
  ButtonIconOutLine,
  Div,
  If,
  Label,
  Notification,
  Row,
  Table,
  Title,
} from "../../components";
import OrcamentoService from "../../service/OrcamentoService";
import { Color, DateUtils, Mask, Pattern, Type, Utils } from "../../utilities";

const styles = {
  boxInfo: {
    marginBottom: "50px",
    padding: "10px 0 20px 0",
    backgroundColor: "#FFF",
    alignItems: "end"
  },
  labelCinza: {
    color: "#878787",
    fontSize: "16px",
    marginBottom: "35px",
  },
  labelInfo: {
    padding: "10px",
    backgroundColor: "#878787",
    color: "#FFF",
    fontSize: "12px",
    margin: "0px 15px",
    borderRadius: "3px"
  },
  valueInfo: {
    margin: "5px"
  },
  imageIcon: {
    marginBottom: 15,
    marginLeft: 5,
    marginRight: 5,
  },
  checkedBox: {
    margin: "8px 5px"
  },
  colorInput: {
    color: "#c5c5c5",
  }
}

class DetalhesPedidoListagem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pedido: {},
      loading: false
    }
  }

  UNSAFE_componentWillMount() {
    let stateHistory = { ...this.props.history.location.state }

    if (Utils.isNotObjectEmpty(stateHistory.orcamento)) {
      this.getOrcamento( stateHistory.orcamento && stateHistory.orcamento.idlancamentosOrcamento)
    }

    if (Utils.isNotObjectEmpty(stateHistory.orcamento)) {
      this.setState({
        pedido: {}
      })
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.idPedido !== this.props.idPedido) {

  //     if(this.props.idPedido !== 0) {
  //       this.getOrcamento(this.props.idPedido)
  //     }

  //     if(this.props.idPedido ===  0 ) {
  //       this.setState({
  //         pedido: {}
  //       })
  //     }
  //   }
  // }

  getOrcamento(idOrcamento) {
    OrcamentoService.getOrcamento(idOrcamento).then(data => {
      if (data) {
        this.setState({
          loading: false,
          pedido: data
        })
      }
    })
  }

  labelButtonPedido() {
    let label = "Separação"

    switch (this.props.posicao) {
      case Type.ORCAMENTO.ABERTO:
        label = "Separação"
        break;

      case Type.ORCAMENTO.SEPARACAO:
        label = "Finalizar"
        break;

      default:
        label = "Separação"
        break;
    }


    return label
  }

  confirmarPedido() {
    let posicao = this.props.posicao

    let obj = {
      idlancamentosOrcamento: this.props.idPedido
    }

    if (posicao === Type.ORCAMENTO.ABERTO) {
      OrcamentoService.atualizarEmSeparacao(obj).then(data => {
        if (data) {
          Notification.success(data.mensagem);
          this.props.setAbasStatus("3")
        } else {
          Notification.danger("Falha ao atualizar situação do orçamento");
        }
      })
    }

    if (posicao === Type.ORCAMENTO.SEPARACAO) {
      OrcamentoService.atualizarConcluido(obj).then(data => {
        if (data) {
          Notification.success(data.mensagem);
          this.props.setAbasStatus("3")
        } else {
          Notification.danger("Falha ao atualizar situação do orçamento");
        }
      })
    }
  }

  formatarPosicao(posicao) {
    let obj = {
      EM_ABERTO: "ABERTO",
      EM_SEPARACAO: "EM SEPARACAO",
      CONCLUIDO: "CONCLUIDO",
      CANCELADO: "CANCELADO",
    }

    return obj[posicao]
  }

  render() {
    const { pedido } = this.state;

    return (
      <Div
        margin="0"
      >
        <Row col="12" shadow className="border-left-primary" style={styles.boxInfo}>


          <Title
            value="Informações do Orçamento"
            type="h6"
            className="label-valor-total-carrinho"
            style={{
              ...styles.labelCinza,
              marginTop: "0px"
            }}
            family="Bold"
            col="12"
          />


          <If and value1={Utils.isNotObjectEmpty(pedido)}>
            <Row
              col="12"
              style={{
                margin: "5px 5px 20px 11px",
                fontWeight: "bold",
                color: Color.ECOMMERCE.CINZA

              }}
            >
              <Label
                value={`Pedido: ${pedido && pedido.referencia}`}
                style={styles.valueInfo}
              />

              <Label
                value={`Feito às: ${DateUtils.getDateFormat(pedido && pedido.dataLancamento, Pattern.DATE.DD_MM_YYYY_HH_MM)}`}
                style={styles.valueInfo}
              />

              <Label
                value={`Situação: ${pedido && this.formatarPosicao(pedido.posicao)}`}
                style={styles.valueInfo}
              />
            </Row>
            <Row col="12">
              <Label family="Bold" value="CLIENTE" style={styles.labelInfo} />
              <Label value={pedido && pedido.nomeCliente} style={styles.valueInfo} />
              <Label family="Bold" value="EMAIL" style={styles.labelInfo} />
              <Label value={pedido && pedido.emailCliente} style={styles.valueInfo} />
            </Row>

            <Row col="12" marginTop="4">
              <Label family="Bold" value="CPF/CNPJ" style={styles.labelInfo} />
              <Label value={pedido && pedido.cpfCnpj} style={styles.valueInfo} />
              <Label family="Bold" value="TEL" style={styles.labelInfo} />
              <Label value={Mask.maskValuePhone(pedido && pedido.telefoneCliente)} style={styles.valueInfo} />
            </Row>

            <Row
              col="12"
              style={{
                margin: "15px 5px 10px 11px",
                fontWeight: "bold",
                fontSize: "14px",
                color: Color.ECOMMERCE.CINZA

              }}
            >
              <Label
                value={`Observações: ${pedido && pedido.obs}`}
                style={styles.valueInfo}
              />
            </Row>

            <Div
              padding="2"
              col="12"
              style={{
                maxHeight: "44vh",
                overflow: "scroll",
                paddingTop: "0px !important"
              }}
            >
              <Table
                columns={[
                  { value: "Cod. de Barras", align: "center", },
                  { value: "Descrição", align: "left" },
                  { value: "Quantidade", align: "center", width: "30px" },
                ]}
                fields={[
                  {
                    value: "codigoBarras",
                    type: Type.DADO.STRING,
                    align: "center",
                    col: 2,
                  },
                  {
                    value: "descricao",
                    type: Type.DADO.STRING,
                    align: "center",
                    col: 2,
                  },
                  {
                    value: "quantidade",
                    type: Type.DADO.INTEGER,
                    align: "center",
                    width: "30px",
                    col: 3,
                  },
                ]}
                bordered
                orientation="center"
                data={pedido ? pedido.itens : []}
                error="Nenhum Produto encontrado"
              ></Table>
            </Div>
          </If>

          <If and value1={!Utils.isNotObjectEmpty(pedido)}>
            <Title
              value="Não foi possivel carregar informações do Orçamentos!"
              type="h6"
              className="label-valor-total-carrinho"
              style={{
                color: Color.CUSTOM_COLORS.DANGER,
                fontSize: "16px",
                marginBottom: "35px",
              }}
              family="Bold"
              col="12"
            />
          </If>

        </Row>
      </Div>
    )

  }


}

export default DetalhesPedidoListagem;