import React, { Component } from "react";
import { Color, Type } from "../../utilities";

import {
  Row,
  Form,
  SectionForm, SectionButtonForm,
  Button,
  Div,
  Image,
  Label,
  I,
} from "../../components";
import FileUpload from "../../components/forms/input/FileUpload";
import ImagemIndisponivel from "../../assets/images/imgindisponivel_thumb.webp";

const styles = {
  imageIcon: {
    marginBottom: 15,
    marginLeft: 5,
    marginRight: 5,
    maxWidth: "100%",
  },
}

class GaleriaFotos extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nameButton: "Salvar",
      urlFoto: "",
      imagens: []
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.imagens) {
      this.setState({
        idProdutoWeb: this.props.idProdutoWeb,
        imagens: this.props.imagens
      })
    }
  }


  handleFileUpload = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  handlerSelectConcorrente = (e) => {
    this.setState({
      ativo: e.target.value
    })
  }

  render() {
    return (
      <Div>
        <Form id="formCadastroConcorrente">
          <SectionForm borderLeft="primary">
            <Div>
              <Div
                padding="1"
                inline="start"
                bg={Color.NODE.WHITE}
                colorText={Color.NODE.DEFAULT}
                className="titulo-precificacao"
              >
                <I icon={Type.ICON.USD} marginLeft="2" marginBottom="2" />
                <Label bold value="Galerias de Fotos" rounded marginLeft="2" />
                {/* <Label bold value="1/5" rounded marginLeft="2" style={{ position: "absolute", right: "26px" }} /> */}
              </Div>
              <Div 
                shadow 
                inline="start" 
                style={{ 
                  width:"100%",
                  padding: "20px",
                  minHeight: "260px"
                }} 
                paddingTop="2" 
                paddingBottom="2"
              >
                {
                  this.state.imagens.map(img => {
                    return (
                      <Div
                        style={{
                          textAlign: "center",
                          height: "195px",
                          width: "235px",
                          marginBottom: "10px",
                          padding: "20px"
                        }}
                      >
                        {/* <I
                          icon={Type.ICON.DELETE}
                          color={Color.CUSTOM_COLORS.DANGER}
                          style={{
                            position: "absolute",
                            color: Color.CUSTOM_COLORS.DANGER,
                            cursor: "pointer",
                            fontSize: "26px",
                            margin: "-11px 0px 0px 94px"
                          }}
                          title="Remover Imagem"
                        /> */}
                        <Image
                          src={img.url}
                          style={styles.imageIcon}
                          styleImage={{ maxWidth: "100%" }}
                        />
                      </Div>
                    )
                  })
                }
              </Div>
            </Div>

            {/* <Row col="12" >
              <Div col="4" style={{ marginTop: "36px" }}>
                <Div col="8" style={{ textAlign: "center" }} >
                  <Image
                    src={this.state.urlFoto ? this.state.urlFoto : ImagemIndisponivel}
                    style={styles.imageIcon}
                    styleImage={{ maxWidth: "100%" }}
                    //className="color-icon-menu"
                    pointer
                  />
                </Div>
                <FileUpload
                  col="8"
                  isIcon
                  label="Importar Foto"
                  handlerChange={e => this.handleFileUpload({ name: "urlFoto", value: e.fileUpload })}
                  classNameIcon="col-12 btn bg-primary text-white rounded pt-3"
                  name="urlFoto"
                  tipos={["png"]}
                ></FileUpload>

                <Div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    position: "absolute",
                    margin: "-60px 0px 0px 240px"
                  }}
                >
                  <Button
                    btn={Color.NODE.SUCCESS}
                    value={this.state.nameButton}
                    margin="1"
                    style={{
                      height: "56px",
                      width: "110px"
                    }}
                  />
                </Div>
              </Div>
            </Row> */}
          </SectionForm>
        </Form>

      </Div >

    )
  }
}



export default GaleriaFotos;
