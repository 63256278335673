import { Http } from '../utilities';

class MenuDepartamentoService {
  static async getMenu() {

    let res = null;
    await Http.get(`/menu`).then(
      response => {
        res = response.data;
      },
    );
    return res;
  }

  static async getMenuAdmin() {

    let res = null;
    await Http.get(`/menu/admin`).then(
      response => {
        res = response.data;
      },
    );
    return res;
  }

  static async putMenu(obj) {
    let res = null;

    await Http.put('/menu', obj).then(response => {
      res = response.data;
    });

    return res;

  }

  static async getIconAdmin() {

    let res = null;
    await Http.get(`/departamentosecao/icones/padrao`).then(
      response => {
        res = response.data;
      },
    );
    return res;
  }

}

export default MenuDepartamentoService;

