export default class Keys {

  static KEY_VERSION_PAGE = "v40.2022.12.26";

  static KEY_USUARIO_LOJA_WEB = "usuario-loja-web";
  static KEY_USUARIO_SECRET_LOJA_WEB = "secret-usuario-loja-web-secret";
  static KEY_CARRINHO_LOJA_WEB = "carrinho-loja-web";
  static KEY_CARRINHO_SECRET_LOJA_WEB = "secret-carrinho-loja-web-secret"
  static KEY_CLIENTE_LOJA_WEB = "usuario-loja-web";
  static KEY_SALVAR_ACESSO_LOGIN = "acesso-login";
  static KEY_SALVAR_ACESSO_LOGIN_SECRET = "secret-acesso-login-secret";
  static KEY_BUSCAS_DIGITADAS = "buscas-anteriores-loja-web";
  static KEY_RELOAD_PAGE = "reload-page-maiitre";

  static VIEW_PRINCIPAL = "Principal";
  static VIEW_PRINCIPAL_COLORAU = "PrincipalColorau";

}
