import React, { Component } from "react";
import { Div, I, Label } from "../../..";
import "./inputlojainstantanea.css";

import { Color, Keys, Type, Utils } from "../../../../utilities";
import Zindex from "../../../../views/utils/Zindex";
import { ProdutoUtils } from "../../../../views";

const ENTER = 13;
const ESC = 27;

class InputSearchLojaInstantaneaMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      texto: "",
      activePesquisa: false,
      css: props.css,
      idInput: Utils.uuidString(),
    };
  }

  UNSAFE_componentWillReceiveProps({ textoPesquisa, css }) {
    this.setState({ texto: textoPesquisa, css }, () => {
      let input = document.getElementById(this.state.idInput);
      if (Utils.isValueValid(input)) {
        input.focus();
      }
    });
  }

  adicionarBusca = () => {
    let buscas = Array.from(
      JSON.parse(localStorage.getItem(Keys.KEY_BUSCAS_DIGITADAS) || "[]")
    );

    if (Utils.isValueValid(buscas)) {
      let buscaJaDigitada = Array.from(buscas).filter(
        (b) =>
          String(b).toUpperCase() === String(this.state.texto).toUpperCase()
      )[0];
      if (!Utils.isValueValid(buscaJaDigitada)) {
        if (buscas.length === 8) buscas.splice(0, 1);
        buscas.push(this.state.texto);
      }
    } else {
      buscas.push(this.state.texto);
    }

    localStorage.setItem(Keys.KEY_BUSCAS_DIGITADAS, JSON.stringify(buscas));
  };

  abrirMenuBusca() {
    let div = document.getElementById(this.props.idPesquisa);
    if (Utils.isValueValid(div)) {
      if (!div.classList.contains("collapsed")) {
        document.getElementById(this.props.idInput).click();
      }
      div.classList.add("collapsed");
    }
  }

  getWidthInput = () => {
    let width = 150;

    if (Utils.isViewMobileGrande()) width = 150;
    if (Utils.isViewTabletAll()) width = window.innerWidth / 1.5;

    return width;
  };

  render() {
    let textPlaceholder = "PESQUISAR";

    return (
      <Div>
        <Div
          target={`#${this.props.idPesquisa}`}
          toggle="collapse"
          style={{ width: 0 }}
          id={this.props.idInput}
          inline="start"
        />
        <input
          placeholder={textPlaceholder}
          style={{
            fontFamily: "MetropolisLight",
            border: `0.1px solid ${Color.ECOMMERCE.CINZA_ESCURO}`,
            position: "absolute",
            width: "90%",
            left: 10,
            right: 10,
            marginTop: 10,
            borderRadius: 5,
            height: 40
          }}
          onClick={() => {
            this.abrirMenuBusca();
            this.props.handlerAbrirPesquisa();
          }}
          onChange={(e) =>
            this.setState({ texto: e.target.value }, () =>
              this.props.handlerChangeTexto(this.state.texto)
            )
          }
          onKeyUp={(e) => {
            if (e.keyCode === ENTER) {
              if (Utils.isValueValid(this.state.texto)) {
                this.adicionarBusca();
                this.props.handlerChangePesquisa(this.state.texto);
              }
            } else if (e.keyCode === ESC) {
              this.setState({ texto: "" });
              this.props.handlerFecharPesquisa();
            }
          }}
          value={this.state.texto}
        />

        <I
          icon={Type.ICON.SEARCH}
          style={{
            borderRadius: 5,
            paddingTop: 2,
            width: 40,
            height: 40,
            color: Color.ECOMMERCE.WHITE,
            backgroundColor: this.props.corPrimaria,
            position: "absolute",
            right: 10,
            marginTop: 10,
            textAlign: "center",
            zIndex: Zindex.BACKGROUND_MENU_CLIENTE
          }}
          sizeIcon="2"
          handlerClick={() => {
            if (Utils.isValueValid(this.state.texto)) {
              ProdutoUtils.adicionarBusca(this.state.texto);
              this.props.handlerChangePesquisa(this.state.texto);
            }
          }}
        />
      </Div>
    );
  }
}

export default InputSearchLojaInstantaneaMobile;
