import React, {useEffect, useState} from "react"
import LazyLoad from "react-lazyload";
import { Div, I, If, Label, ModalNotification } from "../components"
import { Color, Type, Utils } from "../utilities";
import ProdutoUtils from "./utils/ProdutoUtils";
import "./produtos.css"
import ProdutoDetalhe from "./produto/web/ProdutoDetalhe";
import { useSelector } from "react-redux";

const WIDTH = 230;
const HEIGHT = 230;

const styles = {
  descricao: {
    fontSize: "16px",
    lineHeight: "16px",
    color: "black",
    height: 40,
  },
  precoUnitario: {
    color: Color.ECOMMERCE.LARANJA,
    width: 100,
    borderRadius: 5,
    lineHeight: "20px",
    textDecoration: "line-through",
  },
  precoVenda: {
    borderRadius: 5,
    lineHeight: "5px",
    color: Color.ECOMMERCE.LARANJA,
    width: 100,
    fontWeight: "bold",
    fontSize: 20,
  },
  precoVendaIndisponivel: {
    textDecoration: "line-through",
  },
  precoAtacado: {
    color: "#46160E",
    lineHeight: "13px",
    fontSize: 13,
  },
  label: {
    marginLeft: 10,
    marginTop: 100,
    color: Color.ECOMMERCE.LARANJA,
    width: WIDTH,
    fontWeight: "bold",
  },
  containerPercentualEconomia: {
    backgroundColor: "#31CDFF",
    color: "white",
    borderRadius: 50,
    padding: 5,
    position: "absolute",
    transform: "translate(50px, -80px)",
    textAlign: "center",
  },
};

export function ItemProduto(props) {

  const [quantidade, setQuantidade] = useState(0)
  const [show, setShow] = useState(false)
  const [imagemDefault, setImagemDefault] = useState(null)
  const carrinho = useSelector(state => state)

  useEffect(() => {
    let itens = carrinho.carrinhoState.carrinho.itens || []

    let produtoEncontrado = itens.filter(
      (p) => p.idProdutoWeb === props.idProdutoWeb
    )[0];

    if (Utils.isNotNull(produtoEncontrado)) {
      setQuantidade(produtoEncontrado.quantidade) 
    } else {
      setQuantidade(0)
    }
  }, [carrinho])


  const getQuantidadeLabel = (q) => {
    let quantidade = q || 0;

    let labelQuantidade = `${quantidade} ${props.possuiQuantidadeFracionada ? "g" : "un"
      }`;

    return quantidade > 0 ? labelQuantidade : "0";
  }

  const getDescricaoProduto = (descricao) => {
    let desc = String(descricao);
    let descricaoFormatada = String(
      Utils.capitalize(String(descricao).slice(0, 45))
    );
    if (desc.length > 40) {
      descricaoFormatada = descricaoFormatada.concat("...");
    }

    return descricaoFormatada;
  }

  const visualizarProduto = (produto) => {
    ModalNotification.render(
      "Informações do produto",
      e =>
      <ProdutoDetalhe
        produto={produto}
        handlerAddItem={(produto, atualizarProdutoDetalhe) => {
          // this.adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe);
          // toast.info("Produto adicionado no carrinho!", {
          //   position: "top-right",
          // });
        }}
        addQuantidadeItem={addQuantidadeItem}
        removeQuantidadeItem={removeQuantidadeItem}
        qdt={quantidade}
        closeModal={e}
        history={props.history}
        empresa={props.empresa}
      />
    );
  };

  const addQuantidadeItem = () => {
    props.handlerAddItemCarrinho(true, quantidade, p => {
      setQuantidade(p.quantidade)
    })
  }

  const removeQuantidadeItem = () => {
    props.handlerAddItemCarrinho(false, quantidade, p => {
      setQuantidade(p.quantidade)
    })
  }

  const {
    descricao,
    disponivel,
    precosConcorrentes,
    background,
    proibidaVenda,
    image,
    produto,
  } = props;

  return (
    <Div
      style={{ minWidth: WIDTH, height: Utils.isValueValid(precosConcorrentes) ? 450 : 360, margin: 25 }}
      col="2"
      handleMouseEnter={() =>
        setShow(true)
      }
      handleMouseLeave={() => setShow(false)}
    >
      <Div style={{ margin: 5 }} inline="center" padding="2">
        <If and value1={quantidade > 0}>
          <Label
            style={{
              position: "absolute",
              transform: `translate(-85px, 95px)`,
              backgroundColor: Color.EMPRESA.TERCIARIA,
              color: "white",
              padding: 2,
              fontSize: 25,
              textAlign: "center",
              borderRadius: 5,
              width: "70px"
            }}
            family="Light"
            value={getQuantidadeLabel(quantidade)}
          />
        </If>
        <If and value1={props.showHover ? props.showHover : show} value2={disponivel}>
          <Div
            className="view-opaca-item"
            style={{
              width: WIDTH,
              borderRadius: 10,
              height: HEIGHT,
            }}
          >
            <Div
              className="view-absolute-label"
              handlerClick={() => visualizarProduto(produto)}
              pointer
            >
              <Label
                style={{ fontSize: 25 }}
                value="Visualizar"
                colorText={Color.NODE.WHITE}
                pointer
                bold
              />
            </Div>
            <If and value1={!proibidaVenda}>
              <Div
                inline="between"
                className="view-absolute-item"
                style={{
                  backgroundColor: props.corTerciaria ? props.corTerciaria : Color.EMPRESA.TERCIARIA,
                  height: 50,
                }}
              >
                <Div style={{ width: "100%" }} inline="between">
                  <I
                    icon={Type.ICON.MINUS}
                    style={{ color: props.corPrimaria ? props.corPrimaria : Color.EMPRESA.PRIMARIA, fontSize: 40 }}
                    pointer
                    handlerClick={() => {
                      removeQuantidadeItem(quantidade);
                    }}
                  />

                  <Label
                    value={quantidade || "0"}
                    marginTop="1"
                    colorText={Color.NODE.WHITE}
                    style={{ fontSize: 30 }}
                  />
                  <I
                    icon={Type.ICON.PLUS}
                    style={{ color: props.corPrimaria ? props.corPrimaria : Color.EMPRESA.PRIMARIA, fontSize: 40 }}
                    pointer
                    handlerClick={() => {
                      addQuantidadeItem(quantidade);
                    }}
                  />
                </Div>
              </Div>
            </If>
          </Div>
        </If>
        <Div>
          <LazyLoad>
            <img
              src={imagemDefault || image}
              alt={`Imagem do produto ${descricao}`}
              style={{
                width: WIDTH,
                height: HEIGHT,
                paddingBottom: 10,
                backgroundColor: Utils.isValueValid(background)
                  ? background
                  : null,
                borderRadius: 5,
              }}
              // className="border-bottom"
              onError={() => {
                setImagemDefault(ProdutoUtils.URL_IMAGEM_PRODUTO_DEFAULT);
              }}
            />
          </LazyLoad>
        </Div>
      </Div>
      <Div style={{ paddingLeft: 10 }}>
        <If and value1={Utils.isValueValid(descricao)}>
          <Label
            value={getDescricaoProduto(descricao)}
            className="descricao-produto d-flex align-self-start"
            style={styles.descricao}
            family="Light"
          />
        </If>
      </Div>
    </Div>
  )
}

export default ItemProduto;
