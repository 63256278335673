import React, { Component } from "react";
import { Color, Http, PathRoute } from "../../utilities";

import {
  Row,
  InputDefault,
  InputInteger,
  InputNumberBR,
  Form,
  SelectValue,
  Select,
  Notification,
  SectionForm,
  SectionButtonForm, NavbarTab,
  Button,
  Div, If
} from "../../components";
import { connect } from "react-redux";


class Cadastrar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nameButton: "Salvar",
      nameButtonProdFornecedor: "Adicionar",
      user: props.user,
      classificações: [],
      produtoFornecedores: [],
      pessoas: [],
      departamentos: [],
      grupos: [],
      itemActive: {
        target: "DADOS",
        value: "DADOS",
        active: true
      },
      marcas: [],
    };

    this.submit = this.submit.bind(this);
  }

  UNSAFE_componentWillMount() {
    let produto = this.state.produto;
    this.getDataDepartamentos();
    this.getDataFornecedor();
    this.getDataMarcas();
    if (this.state.editar) {
      this.getDataGrupo(produto.idDepartamento);
      this.getDataProdutoFornecedor(produto.idProduto);
      this.setState({
        nameButton: "Alterar",
        idProduto: produto.idProduto,
        idMarca: produto.idMarca,
        idClassificacao: produto.idClassificacao,
        idDepartamento: produto.idDepartamento,
        idGrupo: produto.idGrupo,
        idProdutoPrincipal: produto.idProdutoPrincipal,
        codigoProduto: produto.codigoProduto,
        descricao: produto.descricao,
        descricaoCurta: produto.descricaoCurta,
        descricaoLonga: produto.descricaoLonga,
        descricaoFabricacao: produto.descricaoFabricacao,
        ean: produto.ean,
        margemPrevista: produto.margemPrevista,
        ncm: produto.ncm,
        prazoval: produto.prazoval,
        altura: produto.altura,
        largura: produto.largura,
        profundidade: produto.profundidade,
        embalagem: produto.embalagem,
        pesoLiquido: produto.pesoLiquido,
        pesoBruto: produto.pesoBruto,
        situacao: produto.situacao,
      });
    }

  }

  getDataDepartamentos() {
    this.setState({ departamentos: [] });
    Http.get(`/departamento/listar/` + this.state.user.idGestorCompras).then(({ data }) => {
      if (data) {
        let depComDados = [];
        depComDados.push(new SelectValue(0, "SELECIONE", true));
        Array.from(data).map((dep) => {
          depComDados.push(
            new SelectValue(dep.idDepartamento, dep.descricao, false)
          );
        });
        this.setState({ departamentos: depComDados });
      }
    });
  }

  getDataGrupo(idDepartamento) {
    this.setState({ grupos: [] });
    Http.get(`/grupo/listargrupopordepartamento/` + idDepartamento).then(({ data }) => {
      if (data) {
        let grupoComDados = [];
        grupoComDados.push(new SelectValue(0, "SELECIONE", true));
        Array.from(data).map((grup) => {
          grupoComDados.push(
            new SelectValue(grup.idGrupo, grup.descricao, false)
          );
        });
        this.setState({ grupos: grupoComDados });
      }
    });
  }

  listaProdutos() {
    this.props.history.push({ pathname: `/compras/produto` });
  }

  getDataFornecedor() {
    Http.get(`/pessoa/listarFornecedor/` + this.state.user.idGestorCompras)
      .then(({ status, data }) => {
        if (data) {
          let pessoasComDados = [];
          pessoasComDados.push(new SelectValue(0, "SELECIONE", true));
          Array.from(data).map((pessoa) => {
            pessoasComDados.push(
              new SelectValue(pessoa.idPessoa, pessoa.nome, false)
            );
          });
          this.setState({ pessoas: pessoasComDados });
        }
      })
      .catch((error) => console.log(error));
  }

  getDataProdutoFornecedor(idProduto) {
    this.setState({ produtoFornecedores: [] });
    var prodFornec = {
      idGestorCompras: this.state.user.idGestorCompras,
      idProduto: idProduto
    }
    Http.post(`/produtofornecedor/listar`, prodFornec)
      .then(({ status, data }) => {
        if (data) {
          this.setState({ produtoFornecedores: data });
        }
      })
      .catch((error) => console.log(error));
  }

  getTipoSituacaoSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("LIBERADO-VENDA", "LIBERADO-VENDA", false),
      new SelectValue("BLOQUEADO-COMPRA", "BLOQUEADO-COMPRA", false),
      new SelectValue("BLOQUEADO-VENDA", "BLOQUEADO-VENDA", false),
      new SelectValue("PRODUTO-EXCLUIDO", "PRODUTO-EXCLUIDO", false)
    ];
  }

  getTipoUnidadeMedidaSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("CAIXA", "CAIXA", false),
      new SelectValue("DISPLAY", "DISPLAY", false),
      new SelectValue("FARDO", "FARDO", false),
      new SelectValue("GARRAFA", "GARRAFA", false),
      new SelectValue("LITRO", "LITRO", false),
      new SelectValue("METRO", "METRO", false),
      new SelectValue("MILHEIRO", "MILHEIRO", false),
      new SelectValue("PACOTE", "PACOTE", false),
      new SelectValue("PEÇA", "PEÇA", false),
      new SelectValue("QUILO", "QUILO", false),
      new SelectValue("ROLO", "ROLO", false),
      new SelectValue("UNIDADE", "UNIDADE", false),
      new SelectValue("VIDRO", "VIDRO", false)
    ];
  }

  async submit(event) {
    event.preventDefault();
    var valida = this.validaFormProd();
    if (valida) {
      if (event.target.reportValidity()) {
        if (this.state.idProduto === undefined) {
          this.cadastrar(this.state);
        } else {
          this.update(this.state);
        }
      }
    }
  }

  cadastrar(produto) {
    produto.idGestorCompras = this.state.user.idGestorCompras
    Http.post("/produto", produto).then(({ data }) => {
      if (data.status) {
        produto.idProduto = data.id;
        this.props.history.push({
          pathname: PathRoute.PATH.LOGIN,
          state: { produto: produto, editar: true },
        });
        Notification.success(data.mensagem);
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  validaFormProd() {

    var valida = true;

    if (this.state.idDepartamento === undefined) {
      Notification.danger("Selecione um Departamento!");
      return false;
    }

    if (this.state.idGrupo === undefined) {
      Notification.danger("Selecione um Grupo!");
      return false;
    }

    return valida;
  }

  update(produto) {
    produto.idUsuarioAtualizacao = this.state.user.idUsuario;
    Http.put("/produto", produto).then(({ data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.listaProdutos();
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }



  getDataMarcas() {
    this.setState({ marcas: [] });
    Http.get(`/marca/listar/` + this.state.user.idGestorCompras).then(({ data }) => {
      if (data) {
        let marcasComDados = [];
        marcasComDados.push(new SelectValue(0, "SELECIONE", true));
        Array.from(data).map((marca) => {
          marcasComDados.push(
            new SelectValue(marca.idMarca, marca.descricao, false)
          );
        });
        this.setState({ marcas: marcasComDados });
      }
    });
  }

  getItens = () => {
    let item = this.state.itemActive;
    return [
      {
        target: "DADOS",
        value: "DADOS DO PRODUTO",
        active: item.value === "DADOS",
      }
    ];

  };

  renderProduto() {

    return <Div>
      <Form id="formCadastroProduto" handlerSubmit={this.submit}>
        <SectionForm borderLeft="primary">
          <Row col="12">
            <Select required
              responsive="4"
              label="Departamentos"
              valueSelected={this.state.idDepartamento}
              handlerChange={(e) => {
                this.setState({ idDepartamento: e.target.value });
                this.getDataGrupo(e.target.value);
              }}
              values={this.state.departamentos}
            />
            <Select disabled={this.state.tipo === "DEPARTAMENTO" ? true : false}
              responsive="4"
              label="Grupo"
              valueSelected={this.state.idGrupo}
              handlerChange={(e) => {
                this.setState({ idGrupo: e.target.value });
              }}
              values={this.state.grupos}
            />
            <Select required
              responsive="4"
              label="Marcas"
              valueSelected={this.state.idMarca}
              handlerChange={(e) => {
                this.setState({ idMarca: e.target.value });
              }}
              values={this.state.marcas}
            />
          </Row>
          <Row col="12">
            <Select required
              responsive="4"
              label="Situação"
              valueSelected={this.state.situacao}
              handlerChange={(e) => {
                this.setState({ situacao: e.target.value });
              }}
              values={this.getTipoSituacaoSelect()}
            />

            <InputDefault
              label="ID Produto Principal"
              name="idProdutoPrincipal"
              responsive="4"
              value={this.state.idProdutoPrincipal}
              handlerChange={(e) => {
                this.setState({ idProdutoPrincipal: e.target.value });
              }}
            />

            <InputDefault required
              label="Código Produto"
              name="codigoProduto"
              responsive="4"
              value={this.state.codigoProduto}
              handlerChange={(e) => {
                this.setState({ codigoProduto: e.target.value });
              }}
            />
          </Row>
          <Row col="12">
            <InputDefault required
              label="Descrição"
              name="descricao"
              responsive="6"
              value={this.state.descricao}
              handlerChange={(e) => {
                this.setState({ descricao: e.target.value });
              }}
            />
            <InputDefault
              label="Descrição Curta"
              name="descricaoCurta"
              responsive="6"
              value={this.state.descricaoCurta}
              handlerChange={(e) => {
                this.setState({ descricaoCurta: e.target.value });
              }}
            />
          </Row>
          <Row col="12">
            <InputDefault
              label="Descrição Longa"
              name="descricaoLonga"
              responsive="6"
              value={this.state.descricaoLonga}
              handlerChange={(e) => {
                this.setState({ descricaoLonga: e.target.value });
              }}
            />
            <InputDefault
              label="Descrição Fabricação"
              name="descricaoFabricacao"
              responsive="6"
              value={this.state.descricaoFabricacao}
              handlerChange={(e) => {
                this.setState({ descricaoFabricacao: e.target.value });
              }}
            />
          </Row>
          <Row col="12">
            <InputInteger
              label="Altura"
              name="altura"
              responsive="4"
              value={this.state.altura}
              handlerChange={(e) => {
                this.setState({ altura: e.target.value });
              }}
            />
            <InputInteger
              label="Largura"
              name="largura"
              responsive="4"
              value={this.state.largura}
              handlerChange={(e) => {
                this.setState({ largura: e.target.value });
              }}
            />
            <InputInteger
              label="Profundidade;"
              name="profundidade;"
              responsive="4"
              value={this.state.profundidade}
              handlerChange={(e) => {
                this.setState({ profundidade: e.target.value });
              }}
            />

          </Row>
          <Row col="12">
            <Select required
              responsive="4"
              label="Unidade de Venda"
              valueSelected={this.state.embalagem}
              handlerChange={(e) => {
                this.setState({ embalagem: e.target.value });
              }}
              values={this.getTipoUnidadeMedidaSelect()}
            />
            <InputDefault
              label="Peso Líquido"
              name="pesoLiquido"
              responsive="4"
              value={this.state.pesoLiquido}
              handlerChange={(e) => {
                this.setState({ pesoLiquido: e.target.value });
              }}
            />
            <InputDefault
              label="Peso Bruto"
              name="pesoBruto"
              responsive="4"
              value={this.state.pesoBruto}
              handlerChange={(e) => {
                this.setState({ pesoBruto: e.target.value });
              }}
            />
          </Row>
          <Row col="12">
            <InputDefault required
              label="EAN"
              name="ean"
              responsive="3"
              value={this.state.ean}
              handlerChange={(e) => {
                this.setState({ ean: e.target.value });
              }}
            />
            <InputDefault
              label="NCM"
              name="ncm"
              responsive="3"
              value={this.state.ncm}
              handlerChange={(e) => {
                this.setState({ ncm: e.target.value });
              }}
            />
            <InputNumberBR
              label="Margem Prevista"
              name="margemPrevista"
              responsive="3"
              value={this.state.margemPrevista}
              handlerChange={(e) => {
                this.setState({ margemPrevista: e.target.value });
              }}
            />
            <InputInteger
              label="Prazo de Validade"
              name="prazoval"
              responsive="3"
              value={this.state.prazoval}
              handlerChange={(e) => {
                this.setState({ prazoval: e.target.value });
              }}
            />
          </Row>
          <SectionButtonForm 
            style={{ 
              display: "flex",
              justifyContent: "end",
              paddingRight: "25px",
              marginTop: "15px"
            }}
          >
            <Button
              btn={Color.NODE.PRIMARY}
              value={this.state.nameButton}
              col="1"
              margin="1"
            />

            <Button
              btn={Color.NODE.DANGER}
              value="Cancelar"
              col="1"
              handlerClick={(_e) => this.listaProdutos()}
              margin="1"
            />
          </SectionButtonForm>
        </SectionForm>
      </Form>
    </Div>
  }

  render() {
    let { target } = this.state.itemActive;
    return (
      <Div inline="left" shadow rounded marginLeft="2" padding="2">
        <Div className="w-100">
          <NavbarTab
            itens={this.getItens()}
            handlerClick={(itemActive) => {
              this.setState({ itemActive });
            }}
          />
        </Div>
        <If and value1={target === "DADOS"}>
          {this.renderProduto()}
        </If>
      </Div>
    );
  }

}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(Cadastrar);
