import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Checkbox, Div, I, Input, InputDefault, Label, Notification, Title } from "../../components";
import Tree from 'react-animated-tree';
import { Utils } from "../../utilities";
import DepartamentoService from "../../service/DepartamentoService";

const treeStyles = {
  color: "rgb(62, 65, 73)",
  width: '100%',
  marginLeft: "15px",
  paddingRight: "25px",
  fontWeight: "bolder",

}

const treeDepart = {
  padding: "10px 5px 10px 10px",
  border: "1px solid #dee2e6",
  borderRadius: "11px",
  marginBottom: "15px"

}

const treeSec = {
  color: "rgb(158, 158, 158)",
  padding: "10px 0px 10px 10px",
  borderTop: "1px solid #dee2e6",
  fontWeight: "400"

}

const styles = {
  label: {
    color: "#9e9e9e",
    fontSize: "17px",
    fontWeight: "bold"
  }
}

class VincularSecao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      departamentos: [],
      secao: [],
      departamentoCustom: "",
      secaoCustom: "",
      secoesSelectedVincular: [],
      principal: this.props.departamentos ? this.props.departamentos : [], 
      principalFilter: this.props.departamentos ? this.props.departamentos : []
    };

  }

  handleChange = (e) => {
    e.stopPropagation();
    let { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  getObjectDepartamento() {
    const { secoesSelectedVincular } = this.state;
    const { secaoVincular } = this.props

    let newSecao = []

    secoesSelectedVincular.forEach( secao => {
      newSecao.push({
        idEmpresa: this.props.idEmpresa,
        idFilial: this.props.idFilial,
        idClassificacao: secao.idClassificacao,
        idClassificacaoWeb: secaoVincular.idClassificacaoWeb,
        idDepartamentoWeb: secaoVincular.idDepartamentoWeb
      })
    })

    return newSecao
  }

  confirmVincular() {

    if(this.state.secoesSelectedVincular.length > 0) {
      DepartamentoService.vincularSecao(this.getObjectDepartamento()).then(({ status, message }) => {
        if (status) {
          Notification.success(message)
          this.props.closeComponente()
        } else {
          Notification.danger("Falha ao Criar Departamento/Seção")
        }
      })
    }
  }

  handleSelectedSecoes = (checked, secao) => {
    const { secoesSelectedVincular } = this.state;
    secao.selected = !secao.selected

    let newSecoesSelectedVincular = [ ...secoesSelectedVincular ]

    let secaoSelecionado = newSecoesSelectedVincular.filter(
      (p) => p.idClassificacao === secao.idClassificacao
    )[0];

    if (Utils.isValueValid(secaoSelecionado)) {
      newSecoesSelectedVincular = secoesSelectedVincular.filter(s => s.descricao !== secaoSelecionado.descricao)
    } else {
      newSecoesSelectedVincular.push(secao);
    }

    this.setState({
      secoesSelectedVincular: newSecoesSelectedVincular,
    })
  }

  handleChangeSearch(e) {
    let { value } = e.target;
    let { principal, principalFilter } = this.state;

    let newFilter = [...principalFilter]

    if (value.trim() !== "") {
      newFilter = principal.filter(item =>
        (item.descricao !== null && item.descricao.toLowerCase().indexOf(value.toLowerCase()) >-1 ) ||
        (item.codigoClassificacao !== null && item.codigoClassificacao.toLowerCase().indexOf(value.toLowerCase()) >-1 )
      )

      this.setState({
        principal: newFilter
      })
    } else {
      this.setState({
        principal: principalFilter
      })
    }


  }

  getClassSearch() {
    return this.state.active ? "active" : "";
  }

  searchPrincipal = () => {
    return (
      <Div className="input-group" margin="2" marginBottom="3">
        <Div col="12" style={{ paddingRight: "42px" }} className={`translateXmenos15 translateXmais15 input-group-prepend searchbar d-flex ${this.getClassSearch()}`}>
          <I
            icon="fa fa-search search-table"
            bg="secondary"
            colorText="white"
            handlerClick={e => {
              this.setState({
                active: !this.state.active
              });
            }}
            className="inputgrouptext d-flex"
          ></I>
          <Input
            display="block"
            type="search"
            placeholder="Pesquisar Departamento"
            className="inputsearch formcontrol"
            handlerChange={(e) => { this.handleChangeSearch(e) }}
          ></Input>
        </Div>
      </Div>
    )
  }



  renderTreePrincipal = (idChecked) => {
    const { secoesSelectedVincular, principal } = this.state;

    let iconCheckbox = (secao) => {

      const index = secoesSelectedVincular.indexOf(secao);
      secao.selected = index < 0 ? idChecked === secao.idSecaoWeb : true

      return (
        <>
          <span
            style={{
              position: "absolute",
              margin: "-9px 0px 0px -10px"
            }}
          >
            <Checkbox
              handlerChange={(e) => {
                this.handleSelectedSecoes(e.checked, secao)
              }
              }
              margin="2"
              checked={secao.selected}
            //danger
            />
          </span>
          <span
            style={{
              marginRight: "15px"
            }}
          > </span>
        </>
      )
    }

    return (
      <Div col="12" shadow className="border" style={{ padding: "15px" }}>
        {this.searchPrincipal()}
        <Tree content="Departamentos Principais" open={false} style={treeStyles}>
          {
            principal.map(departamento => {
              return (
                <Tree
                  type={<span style={{ marginRight: "-15px" }}> </span>}
                  content={departamento.codigoClassificacao + " - " +  departamento.descricao}
                  style={treeDepart}
                >
                  {Utils.isNotNull(departamento.secoes) &&
                    <>
                      {
                        departamento.secoes.map(secao => {
                          return (
                            <Tree
                              type={iconCheckbox(secao)}
                              content={secao.codigoClassificacao + " - " + secao.descricao}
                              style={treeSec}
                            />
                          )
                        })
                      }
                    </>
                  }
                </Tree>
              )
            })
          }
        </Tree>
      </Div>
    )
  }

  bodyNovoDepartamento() {

    return (
      <>

        <Div style={{
          margin: "25px 15px",
          overflowY: "scroll",
          maxHeight: "65vh"
        }}>
          <Label value="Vincular Seção a Categoria Principal" style={styles.label} />
          {this.renderTreePrincipal()}
        </Div>

        <Div style={{
          margin: "25px 15px",
          display: "flex",
          justifyContent: "end"
        }}>
          <Button
            handlerClick={(e) => this.confirmVincular()}
            value="Confirmar"
            btn="primary"
            marginRight="3"
          />

          <Button
            handlerClick={
              (e) => this.setState({
                departamentoCustom: "",
                secaoCustom: "",
              },
                this.props.closeComponente()
              )
            }
            value="Cancelar"
            btn="secondary"
          />
        </Div>
      </>
    )
  }

  render() {

    return (
      <Div style={{ width: '100%', margin: "25px 10px 0px 0px" }} shadow className="border">
        <Title
          value={`Seção Personalizado ${this.props.secaoVincular.descricao}`}
          type="h4"
          paddingLeft="3"
          paddingRight="4"
          paddingTop="3"
        />

        <Div
          col="12"
          padding="0"
          style={{
            margin: "35px 0px",
          }}
        >
          {this.bodyNovoDepartamento()}
        </Div>

      </Div>
    )
  }

}

const mapStateToProps = (store) => ({
  idEmpresa: store.loginState.user.idEmpresa,
  idFilial: store.loginState.user.idFilial,
  idFuncionario: store.loginState.user.idFuncionario
});

export default connect(mapStateToProps)(VincularSecao);
