import React from "react";
import "./div.css";
import {
  display,
  margin,
  padding,
  responsive,
  offset,
  inline,
  border,
  column,
  colorText,
  underline,
  rounded,
  collapse,
  pointer,
  active,
  show,
  backGround,
  float,
  textAlign,
  col,
  className,
  invisible,
  shadow,
  family
} from "../../css";

const getClassDefault = props => {
  let classe = "";
  classe = classe.concat(col(props));
  classe = classe.concat(textAlign(props));
  classe = classe.concat(colorText(props));
  classe = classe.concat(float(props));
  classe = classe.concat(offset(props));
  classe = classe.concat(border(props));
  classe = classe.concat(backGround(props));
  classe = classe.concat(display(props));
  classe = classe.concat(pointer(props));
  classe = classe.concat(show(props));
  classe = classe.concat(active(props));
  classe = classe.concat(inline(props));
  classe = classe.concat(collapse(props));
  classe = classe.concat(responsive(props));
  classe = classe.concat(column(props));
  classe = classe.concat(rounded(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(underline(props));
  classe = classe.concat(invisible(props));
  classe = classe.concat(shadow(props));
  classe = classe.concat(className(props));

  return classe;
};

const getStyleFontFamily = (props) => {
  let style = {
    ...props.style,
    ...family(props),
  };
  return style;
};

const Div = props => {
  return (
    <div
      className={getClassDefault(props)}
      role={props.role}
      id={props.id}
      width={props.width}
      height={props.height}
      style={getStyleFontFamily(props)}
      data-toggle={props.toggle}
      data-dismiss={props.dismiss}
      data-target={props.target}
      data-title={props.dataTitle}
      data-ride={props.dataRide}
      data-interval={props.dataInterval}
      aria-expanded={props.expanded}
      data-parent={props.parent}
      aria-controls={props.controls}
      aria-labelledby={props.labelledby}
      draggable={props.draggable}
      onClick={props.handlerClick}
      onMouseEnter={props.handleMouseEnter}
      onMouseLeave={props.handleMouseLeave}
      onDragLeave={props.onDragLeave}
      onDragStart={props.onDragStart}
      onDragEnter={props.onDragEnter}
      onDragOver={props.onDragOver}
      onDragEnd={props.onDragEnd}
      onDrop={props.onDrop}
      onScroll={props.handlerScroll}
      data={props.data}
      onBlur={props.handlerBlur}
      disabled={props.disabled}
    >
      {props.body}
      {props.children}
    </div>
  );
};

export default Div;
