import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Checkbox, Div, I, InputDefault, Label, Notification, Row, Title } from "../../components";
import DepartamentoService from "../../service/DepartamentoService";
import { Color, Type } from "../../utilities";

class NovaSecao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      departamentoCustom: "",
      secaoCustom: "",
      secoes: [],
    };

  }

  handleChange = (e) => {
    e.stopPropagation();
    let { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  getObjectDepartamento() {

    const { departamentoSelecionado } = this.props

    let newSecao = []
    this.state.secoes.forEach(secao => {
      newSecao.push(
        {
          idEmpresa: this.props.idEmpresa,
          idFilial: this.props.idFilial,
          descricao: secao.descricao,
          tipoClassificacao: "SECAO",
          idDepartamentoWeb: departamentoSelecionado.idClassificacaoWeb,
          idFuncionarioAtualizacao: this.props.idFuncionario
        }
      )
    })

    return newSecao
  }

  validacao() {
    const { secoes } = this.state;
    let valido = true

    if (secoes.length < 1) {
      valido = false
      Notification.danger("Nenhuma Seção Confirmada!")
    }
    return valido
  }

  confirmNovasSecoes() {
    let novoSecoes = this.getObjectDepartamento()

    if (this.validacao()) {
      DepartamentoService.createSecao(novoSecoes).then(({ status, message }) => {
        if (status) {
          Notification.success(message)
          this.props.closeComponente()
        } else {
          Notification.danger(message)
        }
      })
    }
  }


  adicionarSecao(nome) {
    const { secoes, } = this.state;

    this.setState({
      secaoCustom: "",
      secoes: [
        ...secoes,
        { descricao: nome }
      ]
    })
  }

  removerSecao(index) {
    const { secoes } = this.state;

    let newSecoes = []
    newSecoes = secoes.filter(s => s.descricao !== secoes[index].descricao)

    this.setState({
      secoes: [
        ...newSecoes
      ]
    })
  }

  bodyNovoDepartamento() {
    const { secaoCustom, secoes } = this.state;
    const { departamentoSelecionado } = this.props

    return (
      <>
        <Label
          value={departamentoSelecionado.descricao}
          style={{
            color: "#9e9e9e",
            fontSize: "20px",
            fontWeight: "bold",
            marginLeft: "16px",
            marginBottom: "21px"
          }} />

        <Row col="12" padding="0">
          <InputDefault
            maxLength={30}
            responsive="6"
            name="secaoCustom"
            placeholder="Escreva uma Descrição para a Seção"
            required
            handlerChange={(e) => this.handleChange(e)}
            value={secaoCustom}
          />
          <Div
            col="4"
            style={{
              marginTop: "8px",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Button
              handlerClick={() => this.adicionarSecao(secaoCustom)}
              value="Adicionar"
              btn="primary"
              marginRight="3"
            />
          </Div>
        </Row>

        <Div style={{ paddingLeft: "15px" }}>
          {
            secoes.map((secao, index) => {
              return (
                <>
                  <Label
                    className="border"
                    value={secao.descricao}
                    style={{
                      color: "#9e9e9e",
                      fontSize: "15px",
                      padding: "10px 30px 10px 17px",
                      marginRight: "10px"
                    }}
                  />
                  <I
                    pointer
                    icon={Type.ICON.CLOSEX}
                    style={{
                      color: Color.RGBA.CINZA,
                      fontSize: 21,
                      position: "absolute",
                      margin: "12px 0px 0px -36px"
                    }}
                    handlerClick={() => {
                      this.removerSecao(index)
                    }}
                  />
                </>
              )
            })
          }
        </Div>

        <Div style={{
          margin: "25px 15px",
          display: "flex",
          justifyContent: "end"
        }}>
          <Button
            handlerClick={(e) => this.confirmNovasSecoes()}
            value="Confirmar"
            btn="primary"
            marginRight="3"
          />

          <Button
            handlerClick={
              (e) => this.setState({
                departamentoCustom: "",
                secaoCustom: "",
              },
                this.props.closeComponente()
              )
            }
            value="Cancelar"
            btn="secondary"
          />
        </Div>
      </>
    )
  }

  render() {

    return (
      <Div style={{ width: '100%', margin: "25px 10px 0px 0px" }} shadow className="border">
        <Title
          value="Nova Seção Personalizado"
          type="h4"
          paddingLeft="3"
          paddingRight="4"
          paddingTop="3"
        />

        <Div
          col="12"
          padding="0"
          style={{
            margin: "35px 0px",
          }}
        >
          {this.bodyNovoDepartamento()}
        </Div>

      </Div>
    )
  }

}

const mapStateToProps = (store) => ({
  idEmpresa: store.loginState.user.idEmpresa,
  idFilial: store.loginState.user.idFilial,
  idFuncionario: store.loginState.user.idFuncionario
});

export default connect(mapStateToProps)(NovaSecao);
