import React, { Component } from "react";

import { Navbar, NavbarItemMenu } from "../../components";
import { Type, Utils } from "../../utilities";
import NavbarSubMenu from "../navbar/menu-left/NavbarSubMenu";
import NavbarItem from "../navbar/menu-left/NavbarItem";
import { connect } from "react-redux";

const ID_NAVBAR = Utils.uuidString();

const ID_PERSONALIZACAO = Utils.uuidString();
const ID_PRODUTOS = Utils.uuidString();
const ID_ORCAMENTOS = Utils.uuidString();
const ID_SAIR = Utils.uuidString();


class MenuLeft extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loja: true,
		};
	}

	isUserMaster() {
		return false;
	}


	renderNavBarUser() {
		return (
			<Navbar title="Mobioh" id={ID_NAVBAR} active={this.props.active}>

				<NavbarItemMenu
					iconLeft={Type.ICON.COG}
					iconRight={Type.ICON.ARROW_ANGLE_DOWN}
					value="Personalização"
					target={`#${ID_PERSONALIZACAO}`}
					rota="#"
				/>
				<NavbarSubMenu id={ID_PERSONALIZACAO} parent={ID_PERSONALIZACAO}>
					<NavbarItem
						iconLeft={Type.ICON.INFO}
						value="Informações Gerais"
						rota="/admin/personalizacao/geral"
					/>
					<NavbarItem
						iconLeft={Type.ICON.PLUS_SQUARE}
						value="Personalização de cores"
						rota="/admin/personalizacao/cores"
					/>
					<NavbarItem
						iconLeft={Type.ICON.REORDER}
						value="Menu de Departamentos"
						rota="/admin/personalizacao/menu"
					/>

				</NavbarSubMenu>

				<NavbarItemMenu
					iconLeft={Type.ICON.ARCHIVE}
					iconRight={Type.ICON.ARROW_ANGLE_DOWN}
					value="Produtos"
					target={`#${ID_PRODUTOS}`}
					rota="#"
				/>
				<NavbarSubMenu id={ID_PRODUTOS} parent={ID_PRODUTOS}>
					<NavbarItem
						iconLeft={Type.ICON.SHOPPING_BAG}
						value="Catálogos"
						rota="/admin/produtos"
					/>
					<NavbarItem
						iconLeft={Type.ICON.LIST_UL}
						value="Departamento e Seção"
						rota="/admin/departamentos"
					/>

				</NavbarSubMenu>

				<NavbarItemMenu
					iconLeft={Type.ICON.MONEY}
					iconRight={Type.ICON.ARROW_ANGLE_DOWN}
					value="Orçamentos"
					target={`#${ID_ORCAMENTOS}`}
					//rota="/admin/orcamentos"
				/>

				<NavbarSubMenu id={ID_ORCAMENTOS} parent={ID_ORCAMENTOS}>
					<NavbarItem
						iconLeft={Type.ICON.LIST_UL}
						value="Listar Orçamentos"
						rota="/admin/orcamentos"
					/>
					<NavbarItem
						iconLeft={Type.ICON.TICKET}
						value="Gestor de Orçamentos"
						rota="/admin/orcamentos/gestor"
					/>

				</NavbarSubMenu>

				<NavbarItemMenu
					iconLeft={Type.ICON.SIGNOUT}
					//	iconRight={Type.ICON.ARROW_ANGLE_DOWN}
					value="Sair"
					target={`#${ID_SAIR}`}
					rota="#"
				/>

			</Navbar>
		);
	}

	render() {
		return this.isUserMaster() ? this.renderNavBarUserMaster() : this.renderNavBarUser();
	}
}

const mapStateToProps = (store) => ({
	user: {},
});

export default connect(mapStateToProps)(MenuLeft);
