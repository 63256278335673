
import React from "react";
import { Page, Text, Image, View, Document, StyleSheet } from "@react-pdf/renderer";
import { Color, DateUtils, Mask, Pattern, Type, Utils } from "../../utilities";
import moment from "moment";

const styles = StyleSheet.create({
  body: {
    paddingTop: 15,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  header: {
    width: "100%",
    backgroundColor: Color.RGBA.CINZA_CLARO,
    zIndex: "888",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 15px"
  },
  numPedido: {
    border: `2px solid ${Color.RGBA.CINZA}`,
    borderStyle: "dotted",
    maxWidth: "300px",
    display: "flex",
    justifyContent: "center",
    fontSize: "12px",
    padding: "10px 25px"
  },
  titulo: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "300px"
  },
  empresa: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px"
  },
  tituloCatalogo: {
    margin: "5px 0px",
    fontSize: "15px"
  },
  textoEmpresa: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px"
  },
  cliente: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    border: `2px solid ${Color.RGBA.CINZA}`,
    padding: "5px",
    margin: "5px 0"
  },
  dados: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "70%",
    fontSize: "12px"
  },
  textoDados: {
    margin: "5px"
  },
  retirada: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "30%",
    fontSize: "14px"
  },
  textoRetirada: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "5px 0",
    fontSize: "14px",
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    marginTop: "15px",
    width: "100%",
    borderTop: `1px solid ${Color.RGBA.CINZA}`,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottom: "none"
  },
  tableTitulo: {
    border: `1px solid ${Color.RGBA.CINZA}`,
    borderTop: "none",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    fontSize: "10px",
    alignItems: "center"
  },
  responsavel: {
    padding: "10px",
    margin: "20px 0 0",
    width: '100%',
    border: `2px solid ${Color.RGBA.CINZA}`,
    borderStyle: "dotted",
  },
  boxResponsavel: {
    border: `2px solid ${Color.RGBA.CINZA}`,
    height: "25px",
    width: "100%"
  },
  textoResponsavel: {
    marginBottom: "5px",
    fontSize: "12px",
    fontWeight: "bold"
  }
});

export default function MapaSeparacaoPDF(props) {
  const { pedido, empresa } = props;


  let itens = Utils.isNotObjectEmpty(pedido) ? pedido.itens : []
  let dados = Utils.isNotObjectEmpty(pedido) ? pedido : {
    referencia: "",
    dataLancamento: "",
    nomeCliente: "",
    cpfCnpj: "",
    telefoneCliente: "",
    emailCliente: "",
    obs: "",
  }

  let empresaDados = Utils.isNotObjectEmpty(empresa) ? empresa : {
    razaoSocial: "",
    fantasia: "",
    enderecoCompleto: ""
  }


  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.header}>
          <View style={styles.titulo}>
            <Text>FICHA DE SEPARAÇÃO</Text>
          </View>
          <View style={styles.numPedido} >
            <Text>N° pedido: {dados.referencia}</Text>
          </View>
        </View>

        <View style={styles.empresa}>
          <View style={styles.tituloCatalogo}>
            <Text>CATÁLOGO</Text>
          </View>
          <View style={styles.textoEmpresa} >
            <Text>Empresa {empresaDados.fantasia} - {empresaDados.enderecoCompleto.substring(0, 40)}</Text>
            <Text>Impresso em: {DateUtils.addDiasDataHojeBr(0)}</Text>
          </View>
        </View>

        <View style={styles.cliente}>
          <View style={styles.dados}>
            <Text style={styles.textoDados}>Cliente: {dados.nomeCliente}</Text>
            <Text style={styles.textoDados}>Email: {dados.emailCliente}</Text>
            <Text style={styles.textoDados}>Telefone: {Mask.getValueMaskPhone(dados.telefoneCliente)}</Text>
            <Text style={styles.textoDados}>Observações: {dados.obs} </Text>
          </View>
          <View style={styles.retirada}>
            <View style={styles.textoRetirada}>
              <Text>EMISSÃO</Text>
            </View>
            <View>
              <Text>{moment(dados.dataLancamento).format(Pattern.DATE.DD_MM_YYYY_HH_MM)}</Text>
            </View>
          </View>
        </View>

        <View style={styles.tableHeader}>
          <View style={{ ...styles.tableTitulo, width: "20%" }}>
            <Text>Cod. de Barras</Text>
          </View>
          <View style={{ ...styles.tableTitulo, width: "50%" }}>
            <Text>Descrição</Text>
          </View>
          <View style={{ ...styles.tableTitulo, width: "15%" }}>
            <Text>Qdt. Requerida</Text>
          </View>
          <View style={{ ...styles.tableTitulo, width: "15%" }}>
            <Text>Qdt. Disponível</Text>
          </View>
        </View>

        {
          itens.map((item, index) => {
            return (
              <View
                style={{
                  ...styles.tableRow,
                  backgroundColor: index % 2 !== 0 ? "#FFF" : Color.RGBA.CINZA_MEDIO
                }}
              >
                <View style={{ ...styles.tableTitulo, width: "20%" }}>
                  <Text>{item.codigoBarras}</Text>
                </View>
                <View style={{ ...styles.tableTitulo, width: "50%" }}>
                  <Text>{item.descricao}</Text>
                </View>
                <View style={{ ...styles.tableTitulo, width: "15%" }}>
                  <Text>{item.quantidade}</Text>
                </View>
                <View style={{ ...styles.tableTitulo, width: "15%" }}>
                  <Text
                    style={{
                      border: `2px solid ${Color.RGBA.CINZA}`,
                      height: "25px",
                      width: "90%"
                    }}
                  > </Text>
                </View>
              </View>
            )
          })
        }

        <View style={styles.responsavel}>
          <View style={styles.textoResponsavel}>
            <Text>Responsável pelo Pedido</Text>
          </View>
          <View style={styles.boxResponsavel}>
            <Text></Text>
          </View>
        </View>

        {/* <Text
          style={styles.pageNumber}
          fixed
        />
        <Text
          style={styles.boxNumber}
          render={
            ({ pageNumber }) => `${pageNumber}`
          }
          fixed
        /> */}

      </Page>
    </Document>
  )
}