import React, { Component } from "react";

import {
  Div,
  Label,
  I,
  Checkbox,
  Notification,
  Table,
  InputNumberBR,
  InputDefault,
  ButtonIconOutLine,
  ModalNotification,
  If,
} from "../../components";
import ProdutoCataloService from "../../service/ProdutoCatalogoService";
import SelectReact from 'react-select';
import { Color, Type, Utils } from "../../utilities";

const styles = {
  listaFiltro: {
    color: Color.ECOMMERCE.CINZA,
    padding: "10px",
    border: `1px solid ${Color.CUSTOM_COLORS.PRIMARY}`,
    maxWidth: "450px",
    fontSize: 16,
    marginTop: 10,
    marginLeft: 19,
    borderRadius: "5px"
  },
  iconFiltro: {
    color: Color.CUSTOM_COLORS.PRIMARY,
    float: "right",
    margin: "7px 6px 4px 4px"
  }
}

class FiltrosAdicionar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filtros: [],
      selectFiltro: {},
      inputFiltro: "",
      dataFiltros: [
        {}
      ],
      filtrosSelecionados: [],
      selecionarTodos: false,
      filtro: "",
      descricao: "",
      idProdutoWeb: "",
      idProdutoWebFiltro: "",
      adicionar: false,
      editar: false
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.filtros) {
      this.setState({
        idProdutoWeb: this.props.idProdutoWeb,
        filtros: this.props.filtros
      })
    }

    this.getFiltrosGeral()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filtros !== this.props.filtros) {
      this.setState({
        idProdutoWeb: this.props.idProdutoWeb,
        filtros: this.props.filtros
      })
      this.getFiltrosGeral()
    }
  }

  getFiltrosGeral() {
    ProdutoCataloService.getProdutosFiltros().then((response) => {
      if (Utils.isNotNull(response)) {

        let newData = []

        response.data.map(filtro => {
          newData.push({ value: filtro.nome, label: filtro.nome })
        })

        this.setState({
          dataFiltros: newData
        })
      }
    })
  }

  confirmarFiltro() {
    const { selectFiltro, descricao, idProdutoWebFiltro } = this.state

    let newFiltro = [{
      idProdutoWeb: this.state.idProdutoWeb,
      nome: Utils.isNotObjectEmpty(selectFiltro) ? selectFiltro.label : "",
      valor: descricao
    }]

    let editarFiltro = {
      nome: Utils.isNotObjectEmpty(selectFiltro) ? selectFiltro.label : "",
      idProdutoWebFiltro: idProdutoWebFiltro,
      valor: descricao
    }

    if (!Utils.isNotObjectEmpty(selectFiltro)) {
      Notification.danger("Selecione ou Escreva um nome para o Filtro!")
      return
    }

    if (selectFiltro.label.length < 3) {
      Notification.danger("Escreva um nome para o filtro com 3 ou mais caracteres!")
      return
    }

    if (descricao.length < 3) {
      Notification.danger("Escreva um valor para o filtro com 3 ou mais caracteres!")
      return
    }

    if (!this.state.editar) {

      ProdutoCataloService.criarNovoFiltro(newFiltro).then((data) => {
        if (data.status) {
          Notification.success(data.mensagem)
          this.setState(
            {
              selectFiltro: {},
              descricao: "",
              editar: false
            },
            this.props.consultarProduto()
          )
        } else {
          Notification.danger(data.mensagem)
        }
      })
    }

    if (this.state.editar) {
      ProdutoCataloService.atualizarFiltro(editarFiltro).then((data) => {
        if (data.status) {
          Notification.success(data.mensagem)
          this.setState(
            {
              selectFiltro: {},
              descricao: "",
              editar: false
            },
            this.props.consultarProduto()
          )
        } else {
          Notification.danger(data.mensagem)
          this.setState(
            {
              selectFiltro: {},
              descricao: "",
              editar: false
            }
          )
        }
      })
    }


  }

  editarFiltro(filtro) {
    this.setState({
      descricao: filtro.valor,
      idProdutoWeb: filtro.idProdutoWeb,
      idProdutoWebFiltro: filtro.idProdutoWebFiltro,
      editar: true,
      inputFiltro: filtro.nome,
      selectFiltro: { value: filtro.nome, label: filtro.nome }
    })
  }

  cancelarEdicao() {
    this.setState({
      inputFiltro: "",
      selectFiltro: {},
      descricao: "",
      idProdutoWebFiltro: "",
      editar: false
    })
  }

  removerFiltro(filtro) {
    let remover = {
      idProdutoWebFiltro: filtro.idProdutoWebFiltro
    }

    ProdutoCataloService.removerFiltro(remover).then((data) => {
      if (data.status) {
        Notification.success(data.mensagem)
        this.setState(
          {
            filtro: "",
            descricao: "",
            editar: false
          },
          this.props.consultarProduto()
        )
      } else {
        Notification.danger(data.mensagem)
      }
    })
  }

  modalRemoverFiltro(filtro) {

    ModalNotification.renderMessage({
      title: "Deseja Remover esse Filtro? ",
      body: (
        `O Filtro ${filtro.nome} Será removido desse produto!`
      ),
      handlerConfirm: () => this.removerFiltro(filtro),
      handlerCancel: () => { },
      labelButtonCancel: "Cancelar",
    });
  }

  handlerSelect(e) {
    this.setState({
      selectFiltro: e,
      inputFiltro: e.label
    })
  }

  handlerInputSelect(value, action) {
    if (action.action === "input-change") {
      this.setState({
        inputFiltro: value,
        selectFiltro: { value: value, label: value }
      })
    }

  }

  render() {
    const {
      filtros,
      selectFiltro,
      dataFiltros,
      descricao,
      filtrosSelecionados,
    } = this.state

    console.log(Utils.isNotObjectEmpty(selectFiltro))

    return (
      <Div marginBottom="2">
        <Div>
          <Div
            padding="1"
            inline="start"
            bg={Color.NODE.WHITE}
            colorText={Color.NODE.DEFAULT}
            className="titulo-precificacao"
          >
            <I icon={Type.ICON.USD} marginLeft="2" marginBottom="2" />
            <Label bold value="Adicionar Filtros" rounded marginLeft="2" />
          </Div>
          <Div shadow border>
            <Div
              paddingTop="2"
              paddingBottom="4"
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "15px"
              }}
            >

              <Div inline="start">
                <Div style={{ width: "300px", marginTop: "-10px" }} marginLeft="2">
                  <Label style={{ marginBottom: "21px" }} value="Lista de Filtros Ativos" rounded />
                  <SelectReact
                    placeholder="Selecione ou Crie uma Novo Filtro"
                    options={dataFiltros}
                    value={selectFiltro}
                    onChange={e => this.handlerSelect(e)}
                    onInputChange={(value, action) => this.handlerInputSelect(value, action)}
                    noOptionsMessage={() => "Nenhum Filtro Encontrado"}
                    autoFocus
                  />
                </Div>
                <InputDefault
                  handlerChange={(e) => this.setState({ descricao: e.target.value })}
                  responsive={"3"}
                  marginTop="2"
                  value={descricao}
                  label="Valor"
                />

                <ButtonIconOutLine
                  value={this.state.editar ? "Editar" : "Confirmar"}
                  style={{ maxWidth: "170px", marginTop: "33px" }}
                  btn={this.state.editar ? Color.NODE.SUCCESS : Color.NODE.PRIMARY}
                  handlerClick={() => this.confirmarFiltro()}
                  col="2"
                  className="custom-btn"
                />

                <If and value1={this.state.editar}>
                  <ButtonIconOutLine
                    value="Cancelar"
                    style={{ maxWidth: "170px", marginTop: "33px" }}
                    btn={Color.NODE.DANGER}
                    handlerClick={() => this.cancelarEdicao()}
                    col="2"
                    className="custom-btn"
                  />
                </If>
              </Div>
            </Div>


          </Div>
        </Div>

        <Table
          columns={[
            { value: "Nome", align: "center" },
            { value: "Valor", align: "center" },
          ]}
          fields={[
            {
              value: "nome",
              type: Type.DADO.STRING,
              align: "left",
              col: 2,
            },
            {
              value: "valor",
              type: Type.DADO.STRING,
              align: "center",
              col: 2,
            },

          ]}
          title="Filtros Cadastros ao Produto"
          delete
          handlerDelete={(filtro) => this.modalRemoverFiltro(filtro)}
          bordered
          orientation="center"
          data={filtros}
          iconEdit={Type.ICON.EDIT}
          handlerEdit={(produto) => this.editarFiltro(produto)}
          edit
          error="Nenhum Filtro encontrado"
          //checkbox={{ nameChecked: "selected", valueChecked: true }}
          handlerCheckbox={(produto) => {
            if (Utils.isNotNull(produto)) {
              produto.selected = !produto.selected;
              let filtroEncontrado = filtrosSelecionados.filter(
                (p) => p.codigoBarras === produto.codigoBarras
              )[0];

              if (Utils.isValueValid(filtroEncontrado)) {
                filtroEncontrado = produto;
              } else {
                filtrosSelecionados.push(produto);
              }
            } else {
              produto.selected = false;
            }
            this.setState({
              filtrosSelecionados,
            });
          }}
        // labelCheckBox={
        //   <Checkbox
        //     width="50px"
        //     label="Selecione Todos"
        //     checked={selecionarTodos}
        //     handlerChange={({ checked }) => {

        //       let filtroList = [...filtros];

        //       if (Utils.isArrayNotEmpty(filtroList)) {
        //         filtroList.forEach((p) => {
        //           p.selected = checked;
        //         });

        //         this.setState({
        //           filtrosSelecionados: checked ? filtroList : [],
        //           selecionarTodos: checked,
        //         });
        //       } else {
        //         Notification.info(
        //           "Nenhum produto selecionado"
        //         );
        //       }

        //     }}
        //   ></Checkbox>
        // }
        ></Table>
      </Div>
    );
  }
}

export default FiltrosAdicionar;