import React, { Component } from "react";
import { Div, I, Image, Label } from "../../components";
import { Color, Type, Utils } from "../../utilities";

import IconCarrinho from "../../assets/images/icon-menu-carrinho.png";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setPedidoFinalizado } from "../../redux/actions";


class FinalizacaoPedido extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.location.state,
    };
  }

  componentDidMount() {
    this.props.setPedidoFinalizado(this.props.carrinho);
  }

  render() {

    const { empresa } = this.props;
    let parametros = Utils.isValueValid(empresa) ? empresa.parametros : {};

    return (
      <Div>
        <Div>
          <Label
            value="Orçamento Enviado com sucesso!"
            style={{ fontSize: 30, color: "#b7b5b5" }}
            family="SemiBold"
          />
          <Div inline="center">
            <Image
              src={IconCarrinho}
              style={{
                backgroundColor: Color.EMPRESA.PRIMARIA,
                width: 50,
                textAlign: "center",
                padding: 10,
                borderRadius: 5,
              }}
            />
            <Div className="d-flex flex-column justify-content-start align-items-start">
              <Label
                value={`Seu Orçamento está sendo processado e entraremos em contado por email ou telefone `}
                margin="2"
                style={{ lineHeight: "5px" }}
                family="Light"
              />
            </Div>
          </Div>
         
          <Div
            marginTop="3"
            style={{
              backgroundColor: Color.EMPRESA.PRIMARIA,
              color: "white",
              textAlign: "center",
              padding: 10,
              borderRadius: 5,
            }}
            pointer
            handlerClick={() => {
              Utils.refreshPage();
              this.props.history.replace("/");
            }}
          >
            <Label
              value="Ir para Pagina Inicial"
              family="Bold"
              style={{ fontSize: 25 }}
              pointer
            />
            <I icon={Type.ICON.SALE_CART} sizeIcon="2" margin="2" pointer />
          </Div>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.empresaState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setPedidoFinalizado }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinalizacaoPedido);
