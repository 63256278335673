import React, { Component } from "react";
import {
  Checkbox,
  Div,
  I,
  If,
  Image,
  Label,
  MessageSpinner,
} from "../../../components";
import { Color, EndPoints, Http, Type, Utils } from "../../../utilities";
import "../produtos.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { atualizarCarrinho, setProdutos } from "../../../redux/actions";
import IconFiltros from "../../../assets/images/icon-filtros.png";
import BotaoPaginaInicial from "../../utils/BotaoPaginaInicial";
import { ProdutoUtils } from "../..";
import ContatoWhatsapp from "../../ContatoWhatsapp";
import CarrinhoUtils from "../../utils/CarrinhoUtils";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import ItemProduto from "../../ItemProduto";

const styles = {
  title: {
    color: "#878787",
  },
  labelFiltros: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    paddingTop: 12,
    paddingLeft: 10,
  },
  labelResultado: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    fontSize: 25,
  },
  labelPrecoActive: {
    border: "2px solid #E94E25",
    width: "100%",
    margin: 3,
  },
  labelPreco: {
    width: "100%",
    margin: 3,
  },
  containerVerMais: {
    position: "fixed",
    bottom: 0,
    backgroundColor: Color.ECOMMERCE.LARANJA,
    color: Color.ECOMMERCE.WHITE,
    padding: 10,
    fontSize: 20,
    width: "100%",
    textAlign: "center",
    zIndex: 1000,
  },
};

class ProdutosPorAnuncioOferta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: [],
      produtosFiltrados: [],
      parametros: {},
      filtros: [],
      pagina: 1,
      verMais: true,
      anuncio: { ...props.location.state },
      pesquisando: false,
      paginarConsulta: false,
    };

    this.adicionarItemNoCarrinho = this.adicionarItemNoCarrinho.bind(this);
  }

  componentDidMount() {
    this.setState({ pesquisando: true }, () => {
      this.listarProdutos();
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    let state = props.location.state;
    this.setState(
      {
        anuncio: { ...state },
        pesquisando: true,
      },
      () => this.listarProdutos()
    );
  }



  getFiltrosMapeados() {
    const { filtros } = this.state;

    let templates = [];

    let filtroDepartamento,
      filtroSecao,
      filtroMarca = [];

    if (Utils.isValueValid(filtroDepartamento)) {
      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label value="Departamentos" family="SemiBold" style={styles.title} />
          {Array.from(filtroDepartamento.itens).map((item, index) => {
            return (
              <Checkbox
                danger
                value={item.idFiltro}
                label={`${item.descricao} (${item.quantidadeEncontrada}/${item.quantidadeTotal})`}
                key={index}
                handlerChange={({ checked }) => {
                  item.active = checked;
                  this.setState({ filtros });
                  this.aplicarFiltros();
                }}
                checked={item.active}
              />
            );
          })}
        </Div>
      );
    }

    if (Utils.isValueValid(filtroSecao)) {
      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label value="Categorias" family="SemiBold" style={styles.title} />
          {Array.from(filtroSecao.itens).map((item, index) => {
            return (
              <Checkbox
                danger
                label={`${item.descricao} (${item.quantidadeEncontrada}/${item.quantidadeTotal})`}
                key={index}
                handlerChange={({ checked }) => {
                  item.active = checked;
                  this.setState({ filtros });
                  this.aplicarFiltros();
                }}
                checked={item.active}
              />
            );
          })}
        </Div>
      );
    }

    if (Utils.isValueValid(filtroMarca)) {
      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label value="Marca" family="SemiBold" style={styles.title} />
          {Array.from(filtroMarca.itens).map((item, index) => {
            return (
              <Checkbox
                danger
                label={`${item.descricao} (${item.quantidadeEncontrada}/${item.quantidadeTotal})`}
                key={index}
                handlerChange={({ checked }) => {
                  item.active = checked;
                  this.setState({ filtros });
                  this.aplicarFiltros();
                }}
                checked={item.active}
              />
            );
          })}
        </Div>
      );
    }

    return templates;
  }

  aplicarFiltros() {
    let produtos = JSON.parse(JSON.stringify(this.state.produtos));

    let filtroPreco = Array.from(this.state.filtros).filter(
      (filtro) => filtro.tipo === "PRECO"
    )[0];

    let itensPrecoMarcados = Array.from(filtroPreco.itens).filter(
      (item) => item.active
    );

    let filtroDepartamento = Array.from(this.state.filtros).filter(
      (filtro) => filtro.tipo === "DEPARTAMENTO"
    )[0];

    let itensDepartamentoMarcados = Array.from(filtroDepartamento.itens).filter(
      (item) => item.active
    );

    let filtroSecao = Array.from(this.state.filtros).filter(
      (filtro) => filtro.tipo === "SECAO"
    )[0];

    let itensSecaoMarcados = Array.from(filtroSecao.itens).filter(
      (item) => item.active
    );

    let filtroMarca = Array.from(this.state.filtros).filter(
      (filtro) => filtro.tipo === "MARCA"
    )[0];

    let itensMarcaMarcados = Array.from(filtroMarca.itens).filter(
      (item) => item.active
    );

    let produtosFiltrados = [];

    if (Utils.isValueValid(itensDepartamentoMarcados)) {
      let filtros = itensDepartamentoMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (filtros.includes(filtroProduto.departamento)) {
            produtosFiltrados.push(produto);
          }
        });
      }
    }

    if (Utils.isValueValid(itensSecaoMarcados)) {
      let filtros = itensSecaoMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (filtros.includes(filtroProduto.secao)) {
            let produtoExiste = produtosFiltrados.indexOf(produto);
            if (produtoExiste === -1) {
              produtosFiltrados.push(produto);
            }
          }
        });
      }
    }

    if (Utils.isValueValid(itensMarcaMarcados)) {
      let filtros = itensMarcaMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (filtros.includes(filtroProduto.marca)) {
            let produtoExiste = produtosFiltrados.indexOf(produto);
            if (produtoExiste === -1) {
              produtosFiltrados.push(produto);
            }
          }
        });
      }
    }

    if (!Utils.isValueValid(produtosFiltrados)) {
      produtosFiltrados = produtos;
    }

    if (Utils.isValueValid(itensPrecoMarcados)) {
      let filtros = itensPrecoMarcados.map((item) => item.idFiltro);

      let produtosExcluir = [];

      if (Utils.isValueValid(filtros)) {
        produtosFiltrados.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (!filtros.includes(filtroProduto.preco)) {
            produtosExcluir.push(produto);
          }
        });

        produtosExcluir.forEach((produto) => {
          produtosFiltrados.splice(produtosFiltrados.indexOf(produto), 1);
        });
      }
    }

    this.setState({
      produtosFiltrados,
    });
  }

  getFiltrosPaginados(filtrosNovaPagina) {
    let filtros = Array.from(this.state.filtros);

    if (Utils.isValueValid(filtrosNovaPagina)) {
      Array.from(filtrosNovaPagina).forEach((filtro) => {
        let filtroPorTipo = filtros.filter((f) => f.tipo === filtro.tipo)[0];
        if (Utils.isValueValid(filtroPorTipo)) {
          let itens = Array.from(filtroPorTipo.itens);
          let itensNovaPagina = Array.from(filtro.itens);

          if (Utils.isValueValid(itensNovaPagina)) {
            itensNovaPagina.forEach((item) => {
              let filtroJaAdicionado = itens.filter(
                (i) => i.idFiltro === item.idFiltro
              )[0];
              if (!Utils.isValueValid(filtroJaAdicionado)) {
                itens.push(item);
              } else {
                filtroJaAdicionado.quantidadeEncontrada =
                  Number(filtroJaAdicionado.quantidadeEncontrada) +
                  Number(item.quantidadeEncontrada);
              }
            });
          }
        } else {
          filtros.push(filtro);
        }
      });
    }

    return filtros;
  }

  getParamListarProdutos() {
    const { pagina, anuncio } = this.state;

    let param = {
      numeroPagina: pagina,
      idAnuncioOferta: anuncio.idAnuncioOferta,
    };

    return param;
  }

  listarProdutos(carregarMais = true) {
    let param = this.getParamListarProdutos(carregarMais);

    Http.post(EndPoints.ANUNCIO_PRODUTOS, param)
      .then(({ status, data }) => {
        if (status && Utils.isValueValid(data.listaProdutos)) {
          if (this.state.pagina > 1) {
            this.setState(
              {
                produtos: [...this.state.produtos, ...data.listaProdutos],
                filtros: this.getFiltrosPaginados(data.filtros),
                verMais: true,
                pesquisando: false,
              },
              () => this.aplicarFiltros(param)
            );
          } else {
            this.setState(
              {
                produtos: data.listaProdutos,
                filtros: this.getFiltrosPaginados(data.filtros),
                verMais: true,
                pesquisando: false,
              },
              () => this.aplicarFiltros(param)
            );
          }
        } else {
          if (this.state.pagina === 1) {
            this.setState({
              produtos: [],
              produtosFiltrados: [],
              // filtros: [],
              verMais: false,
              pesquisando: false,
            });
          } else {
            this.setState({ verMais: false, pesquisando: false });
          }
        }
      })
      .catch((erro) => console.log(erro));
  }

  getProdutosPorDepartamento = () => {
    let itensParaRenderizar = [];

    let produtosAgrupados = Utils.groupBy(
      this.state.produtosFiltrados,
      "descricaoDepartamento"
    );

    for (const item of produtosAgrupados) {
      let itens = [];

      let departamento = item[0];
      let produtos = Array.from(item[1]);

      let produtosSeparados = produtos;

      itensParaRenderizar.push({
        tamanho: produtosSeparados.length,
        produtos: produtosSeparados,
        departamento,
      });

      itensParaRenderizar = Utils.sortReverse(itensParaRenderizar, "tamanho");
    }

    return itensParaRenderizar;
  };


  renderProdutos() {
    return (
      <Div>
        {Array.from(this.getProdutosPorDepartamento()).map((data, index) => {
          return (
            <Div className="d-flex flex-column" marginTop="2" key={index}>
              <Div inline="start">
                <Label
                  style={styles.labelDepartamento}
                  value={data.departamento}
                />
              </Div>
              <Label className="border-label-departamento" />
              <Label className="border-bottom border-label-departamento-bottom" />
              <Div inline="start">
                {Array.from(data.produtos).map((prod, index) => {
                  return (
                    <Div className="align-self-start" >
                      <ItemProduto
                        key={index}
                        history={this.props.history}
                        image={prod.urlImagemPrincipalThumbnail}
                        descricao={prod.descricao}
                        quantidade={prod.quantidade}
                        disponivel={prod.disponivel}
                        proibidaVenda={prod.proibidaVenda}
                        produto={prod}
                        idProdutoWeb={prod.idProdutoWeb}
                        handlerAddItemCarrinho={(adicionar, quantidade, callback) => {
                          this.adicionarItemNoCarrinho(prod, quantidade, adicionar).then(() => {
                            if (callback) {
                              callback(prod)
                            }
                          });
                        }}
                        empresa={this.state.empresa}
                      />
                    </Div>
                  );
                })}
              </Div>
            </Div>
          );
        })}
      </Div>
    );
  }

  async adicionarItemNoCarrinho(produto, quantidade, adicionar) {

    let qdt = quantidade || 0

    if (adicionar) {
      qdt = quantidade ? quantidade + 1 : 1
    } else {
      if (produto.quantidade >= 1) {
        qdt = quantidade - 1
      }
    }

    let { carrinho, produtos } = this.props;
    let prod = ProdutoUtils.calcularValorProduto(produto, qdt);

    CarrinhoUtils.adicionarItemNoCarrinho(
      prod,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      null,
      this.props.parametros
    );
  }

  limparFiltros() {
    let filtros = this.state.filtros;
    Array.from(filtros).forEach((filtro) => {
      Array.from(filtro.itens).map((item) => (item.active = false));
    });

    this.setState({ filtros }, () => this.aplicarFiltros());
  }

  render() {
    const { anuncio, produtosFiltrados } = this.state;

    return (
      <>
        <Div col="12" style={{ margin: 30 }}>
          <Div className="d-flex align-self-center justify-content-center">
            <If and value1={Utils.isValueValid(produtosFiltrados)}>
              <Div responsive="3" className="align-self-start">
                <Div inline="center">
                  <Image src={IconFiltros} paddingBottom="1" />
                  <Label
                    value="FILTROS"
                    style={styles.labelFiltros}
                    family="SemiBold"
                  />
                </Div>
                <Div
                  inline="center"
                  pointer
                  handlerClick={() => this.limparFiltros()}
                  className="bg-light border"
                  rounded
                  padding="2"
                >
                  <I
                    icon={Type.ICON.CLOSEX}
                    colorText={Color.NODE.DANGER}
                    paddingBottom="2"
                    pointer
                    handlerClick={() => this.limparFiltros()}
                  />
                  <Label
                    value="Limpar filtros"
                    family="Light"
                    marginLeft="2"
                    pointer
                    handlerClick={() => this.limparFiltros()}
                  />
                </Div>
                {this.getFiltrosMapeados().map((item, index) => (
                  <Div key={index}>{item}</Div>
                ))}
              </Div>
            </If>
            <Div responsive={this.state.pesquisando ? "12" : "9"}>
              <Div inline="between">
                <Label
                  value={`Resultados da busca pelo anúncio "${anuncio.titulo}"`}
                  style={styles.labelResultado}
                  family="SemiBold"
                />
                <Div inline="center">
                  <BotaoPaginaInicial
                    history={this.props.history}
                    label="Início"
                  />
                </Div>
              </Div>
              <If
                and
                value1={!Utils.isValueValid(produtosFiltrados)}
                value2={!this.state.pesquisando}
              >
                <Div inline="center">
                  <I
                    icon={Type.ICON.INFO}
                    sizeIcon="3"
                    style={{ color: Color.ECOMMERCE.LARANJA }}
                    margin="2"
                  />
                  <Label
                    value="Nenhum produto encontrado para o anúncio selecionado"
                    family="Light"
                    style={{ fontSize: 25 }}
                    margin="2"
                  />
                </Div>
              </If>
              <If and value1={this.state.pesquisando}>
                <Div inline="center">
                  <MessageSpinner
                    style={{ backgroundColor: Color.EMPRESA.PRIMARIA }}
                    colorText={Color.NODE.WHITE}
                  >
                    <Label
                      value="Consultando produtos, aguarde"
                      family="Light"
                      style={{ fontSize: 30 }}
                      margin="2"
                    />
                  </MessageSpinner>
                </Div>
              </If>
              <If and value1={Utils.isValueValid(produtosFiltrados)}>
                <Div inline="start" style={{ paddingBottom: 100 }}>
                  {this.renderProdutos()}
                </Div>
              </If>
            </Div>
          </Div>
        </Div>
        {/**
           * <If and value1={this.state.verMais} value2={this.state.paginarConsulta}>
          <Div
            style={styles.containerVerMais}
            pointer
            handlerClick={() =>
              this.setState({ pagina: this.state.pagina + 1 }, () =>
                this.listarProdutos()
              )
            }
            inline="center"
          >
            <Image src={ArrowDownVerMais} className="arrow-ver-mais" />
            <Label
              value="Carregar mais itens..."
              margin="2"
              family="Bold"
              pointer
            />
          </Div>
        </If>
           */}
        <ContatoWhatsapp empresa={this.props.empresa} />
        <BottomScrollListener
          onBottom={() => {
            if (this.state.verMais) {
              this.setState({ pagina: this.state.pagina + 1 }, () =>
                this.listarProdutos(true)
              );
            }
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.listasDeCompraState,
    ...store.empresaState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ atualizarCarrinho, setProdutos }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProdutosPorAnuncioOferta);
