import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Utils, Color, Http, Mask, Type, Id } from "../../utilities";
import {
  Page,
  Pagination,
  PaginationItem,
  PaginationNext,
  PaginationPrevios,
  TableCell,
  TableColumn,
  TableColumnButtonDelete,
  TableColumnButtonEdit,
  Div,
  SelectValue,
  Title,
  TableColumnCheckBox,
  Row,
  InputSearchTable,
  TableColumnActive,
  Notification,
  Image
} from "../../components";

import { className, col, textAlign } from "../css";

import "./table.css";
import I from "../i/I";
import Label from "../texts/label/Label";
import ButtonIconOutLine from "../buttons/ButtonIconOutLine";
import If from "../if/If";
import TableColumnInput from "./columnbutton/TableColumnInput";
import CheckboxToggle from "../forms/checkbox/CheckboxToggle";

class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : [],
      dataFilter: props.data ? props.data : [],
      columns: props.columns,
      quantidadeExibicao: 10,
      paginas: this.getPages(props.data, 10),
      paginaAtual: 1,
      search: false,
      showError: false,
      textSearch: "",
      numberItensPage: this.getNumberItensPage(),
    };
  }

  getNumberItensPage() {
    return [
      new SelectValue(10, 10),
      new SelectValue(15, 15),
      new SelectValue(20, 20),
      new SelectValue(25, 25),
      new SelectValue(30, 30),
      new SelectValue(50, 50),
    ];
  }

  UNSAFE_componentWillMount() {
    if (this.props.url) {
      this.loadData();
    } else {
      // Se os dados forem passados para a tabela então somente aplicar a máscara
      if (Utils.isValueValid(this.state.data)) {
        this.aplicarMascaras();
      } else {
        this.setState({
          showError: true,
        });
      }
    }
  }

  loadData() {
    Http.get(this.props.url).then(({ data, status }) => {
      if (status) {
        this.setState({
          data,
          dataFilter: data,
          paginas: this.getPages(data, 10),
          showError: false,
        });

        this.aplicarMascaras();
      } else {
        this.setState({
          showError: true,
        });
      }
    });
  }

  aplicarMascaras() {
    const { data } = this.state;
    let fields = Array.from(this.getFieldsNameTable(data));

    if (Utils.isValueValid(data)) {
      Array.from(data).map((data) => {
        return fields.map((field) => {
          return this.applyMasks(field, data);
        });
      });

      this.setState({
        data,
        dataFilter: data,
      });
    }
  }

  applyMasks(field, data) {
    if (field.type) {
      if (field.type === Type.DADO.CURRENCY) {
        field.valueMask = String(field.value).concat("Mask");
        data[field.valueMask] = Mask.convertNumberBr(data[field.value]);
      }

      if (field.type === Type.DADO.DATE_TIME) {
        field.valueMask = String(field.value).concat("Mask");
        data[field.valueMask] = Mask.maskDatePattern(
          data[field.value],
          "DD/MM/YYYY HH:mm:ss"
        );
      }

      if (field.type === Type.DADO.DATE) {
        field.valueMask = String(field.value).concat("Mask");
        data[field.valueMask] = Mask.maskDatePattern(
          data[field.value],
          "DD/MM/YYYY"
        );
      }

      if (field.type === Type.DADO.CPF_CNPJ) {
        field.valueMask = String(field.value).concat("Mask");
        data[field.valueMask] = Mask.maskValueCpfCnpj(data[field.value]);
      }

      if (field.type === Type.DADO.LABEL) {
        field.valueMask = String(field.value).concat("Mask");
        data[field.valueMask] = data[field.value];
      }

      if (field.type === Type.DADO.PHONE) {
        field.valueMask = String(field.value).concat("Mask");
        data[field.valueMask] = Mask.maskValuePhone(data[field.value]);
      }

      if (field.type === Type.DADO.INTEGER) {
        field.valueMask = String(field.value).concat("Mask");
        data[field.valueMask] = parseInt(data[field.value] || 0);
      }

      if (
        field.type === Type.DADO.BOOLEAN &&
        field.valueTrue &&
        field.valueFalse
      ) {
        field.valueMask = String(field.value).concat("Mask");
        data[field.valueMask] = Boolean(data[field.value])
          ? field.valueTrue
          : field.valueFalse;
      }
    }
  }

  colorCustom(field, data) {
    let cor = Utils.isValueValid(field.background) ? field.background : Color.CUSTOM_COLORS.PRIMARY

    switch (data[field.valueMask || field.value || field]) {
      case Type.CATALOGO.DISPONIVEL:
        cor = Color.CUSTOM_COLORS.PRIMARY
        break;
      
      case Type.CATALOGO.BLOQUEADOS:
        cor = Color.CUSTOM_COLORS.DANGER
        break

      case Type.CATALOGO.PENDENTES:
        cor = Color.RGBA.LARANJA
        break

      default:
        cor = Color.CUSTOM_COLORS.PRIMARY
        break;
    }

    return cor
  }

  //Renderiza as cÃ©lulas com os dados da tabela
  renderData() {
    let { dataFilter, quantidadeExibicao } = this.state;
    let data = this.props.pagination
      ? Array.from(dataFilter || []).slice(0, quantidadeExibicao)
      : Array.from(dataFilter);

    let fields = Array.from(this.getFieldsNameTable(data));
    let columns = Array.from(this.state.columns);

    return data.map((data, keyCell) => {
      let classeSelected = data.selected
        ? this.props.colorSelected || "color-selected-default"
        : "";
      return (
        <TableCell key={keyCell} className={classeSelected}>
          {fields.map((field, key) => {
            return (
              <React.Fragment key={key}>
                <If
                  and
                  value1={!Utils.isValueValid(field.input) || !field.input}
                >
                  <TableColumn
                    className={
                      field.align ? this.getFieldAlign(columns[key]) : ""
                    }
                    key={key}

                  >
                    {/* LABEL */}
                    <If and value1={(
                      field.type === Type.DADO.LABEL
                    )}>
                      <Div
                        style={{
                          padding: "8px 4px",
                          alignItems: "center",
                          background: this.colorCustom(field, data),
                          color: Utils.isValueValid(field.color) ? field.color : "#000", 
                          margin: "7px",
                          borderRadius: "3px",
                          fontWeight: "700"
                        }}
                      >
                        {data[field.valueMask || field.value || field]} 
                      </Div>
                    </If>
                    
                    {/* ROW */}
                    <If and value1={(
                      field.type !== Type.DADO.LABEL && field.type !== Type.DADO.IMG
                    )}>
                      {data[field.valueMask || field.value || field]}   
                    </If>
                    
                    {/* IMAGEM */}
                    <If and value1={(
                      field.type === Type.DADO.IMG
                    )}>
                      <Div padding="2">
                        <Image
                          responsive
                          src={data[field.valueMask || field.value || field]}
                          width={"50px"}
                        ></Image>
                      </Div>
                    </If>

                  </TableColumn>
                </If>
                <If and value1={Utils.isValueValid(field.input) && field.input}>
                  <TableColumnInput
                    className={
                      field.align ? this.getFieldAlign(columns[key]) : ""
                    }
                    handlerChangeInput={this.props.handlerChangeInput}
                    handlerEnterInput={this.props.handlerEnterInput}
                    key={key}
                    name={field.value}
                    data={data}
                    type={field.typeInput}
                    width={field.width}
                    col={field.col}
                    value={data[field.valueMask || field.value || field]}
                  ></TableColumnInput>
                </If>
              </React.Fragment>
            );
          })}
          {this.renderColumnCheckBoxToggle(keyCell)}
          {this.renderActiveButton(keyCell)}
          {this.renderEditButton(keyCell)}
          {this.renderDeleteButton(keyCell)}
          {this.renderColumnCheckBox(keyCell)}
        </TableCell>
      );
    });
  }

  renderColumnCheckBox(keyCell) {
    let data = this.getDataCell(keyCell);
    let checkbox = this.props.checkbox;
    if (checkbox) {
      return (
        <TableColumnCheckBox
          checked={
            checkbox.checked ||
            data[checkbox.nameChecked] === checkbox.valueChecked
          }
          handlerCheckBox={(e) => this.props.handlerCheckbox(data, e)}
        ></TableColumnCheckBox>
      );
    }
  }

  renderColumnCheckBoxToggle(keyCell) {
    let data = this.getDataCell(keyCell);
    let checkbox = this.props.checkedToggle;
    if (checkbox) {
      return (
        <TableColumnCheckBox
          checked={
            checkbox.checked ||
            data[checkbox.nameChecked] === checkbox.valueChecked
          }
          checkedToggle={checkbox.checkboxToggle}
          handlerCheckBox={(e) => this.props.handlerCheckboxToggle(data, e)}
        ></TableColumnCheckBox>
      );
    }
  }

  renderActiveButton(keyCell) {
    let data = this.getDataCell(keyCell);
    let active = this.props.active;
    if (active) {
      return (
        <TableColumnActive
          checked={
            data[this.props.active.nameChecked] ===
            this.props.active.valueChecked
          }
          active={this.props.active}
          handlerActive={(e) => this.props.handlerActive(data, e)}
        ></TableColumnActive>
      );
    }
  }

  //Renderiza botÃ£o de ediÃ§ao para a cÃ©lula da tabela
  renderEditButton(keyCell) {
    let data = this.getDataCell(keyCell);
    if (this.props.edit) {
      return (
        <TableColumnButtonEdit
          type={this.props.iconEdit}
          sizeIcon={this.props.sizeEdit}
          colorText={this.props.colorEdit}
          edit={this.props.edit}
          handlerClick={(e) => this.props.handlerEdit(data)}
        ></TableColumnButtonEdit>
      );
    }
  }

  //Renderiza botÃ£o de exclusÃ£o para a cÃ©lula da tabela
  renderDeleteButton(keyCell) {
    if (this.props.delete) {
      return (
        <TableColumnButtonDelete
          type={this.props.iconDelete}
          sizeIcon={this.props.sizeDelete}
          colorText={this.props.colorDelete}
          delete={this.props.delete}
          handlerClick={(e) =>
            this.props.handlerDelete(this.getDataCell(keyCell))
          }
        ></TableColumnButtonDelete>
      );
    }
  }

  //Renderiza o header das colunas
  renderHeader() {
    let columns = Array.from(this.state.columns);
    let fields = this.getFieldsNameTable(this.state.data);

    let header = columns.map((fieldHeader, index) => {
      return (
        <th
          key={index}
          onClick={(e) => this.sort(fields[index])}
          className={`cursor-pointer ${this.getFieldAlign(fieldHeader)}`}
        >
          {fieldHeader.value || fieldHeader}
        </th>
      );
    });

    //Adicionar as colunas de ediÃ§Ã£o e exclusÃ£o
    if (this.props.checkedToggle) header.push(this.getColumnCheckBoxToggle(header.length));
    if (this.props.active) header.push(this.getColumnActive(header.length));
    if (this.props.edit)
      header.push(this.getColumnEditDelete(header.length, true));
    if (this.props.delete)
      header.push(this.getColumnEditDelete(header.length, false));
    if (this.props.checkbox) header.push(this.getColumnCheckBox(header.length));

    return header;
  }

  getFieldAlign(field) {
    let align = field.align ? `text-${field.align} ` : "";
    if (field.align !== "center") {
      align = align.concat("p-2");
    }
    return align;
  }

  getCurrentPage() {
    return new Map(this.state.paginas).get(this.state.paginaAtual);
  }

  getDataCell(keyCell) {
    let data = Array.from(this.state.dataFilter);
    return data[keyCell];
  }

  getColumnCheckBox(key) {
    let valueHeader = Utils.isValueValid(this.props.labelCheckBox)
      ? this.props.labelCheckBox
      : "";
    return <th key={key}>{valueHeader}</th>;
  }

  getColumnCheckBoxToggle(key) {
    let valueHeader = Utils.isValueValid(this.props.labelCheckBoxToggle)
      ? this.props.labelCheckBoxToggle
      : "";
    return <th key={key}>{valueHeader}</th>;
  }


  getColumnActive(key) {
    return <th key={key}>{this.props.active.labelActive}</th>;
  }

  getColumnEditDelete(key, isEdit) {
    return (
      <th key={key}>
        {isEdit ? this.props.labelEdit : this.props.labelDelete}
      </th>
    );
  }

  getFieldsNameTable(data) {
    let fields = [];

    if (Utils.isArrayNotEmpty(this.props.fields)) {
      fields = this.props.fields;
    } else {
      let dados = Array.from(data);
      if (Utils.isArrayNotEmpty(data)) {
        fields = Object.keys(Object.values(dados)[0]);
      }
    }

    return fields;
  }

  sort(field) {
    let keyField = field.value || field;

    let data = Array.from(this.state.data);
    let dataSort = this.state.dataFilter.sort((cel1, cel2) => {
      return cel1[keyField] > cel2[keyField] ? 1 : -1;
    });

    if (Utils.isArrayNotEmpty(dataSort)) {
      this.setState({
        dataFilter: dataSort,
      });
    } else {
      this.setState({
        dataFilter: data,
      });
    }
  }

  // Retorna os itens da pÃ¡gina atual
  // Caso a tabela esteja em pesquisa, os itens serÃ£o pesquisados novamente e aplicado o filtro da pÃ¡gina
  getDataFilter(page) {
    if (this.state.search) {
      let dataFilter = this.getDataFilterTextSearch(this.state.textSearch);
      return dataFilter.slice(page.indexStart, page.indexEnd);
    } else {
      return Array.from(this.state.data).slice(page.indexStart, page.indexEnd);
    }
  }

  //Executado quando a paginaÃ§Ã£o da tabela Ã© modificada
  filterPagination(indexPage, page) {
    let data = this.getDataFilter(page);

    this.setState({
      dataFilter: data,
      paginaAtual: indexPage,
    });
  }

  // Filtra os elementos da tabela pelo campo de pesquisa
  filter(search) {
    let dataFilter = this.getDataFilterTextSearch(search);
    this.setState({
      dataFilter: dataFilter,
      search: this.setSearchActive(search),
      textSearch: search,
      paginaAtual: 1,
      paginas: this.getPages(dataFilter, this.state.quantidadeExibicao),
    });
  }

  getDataFilterTextSearch(search) {
    let data = Array.from(this.state.data);
    let dataFilter = data.filter((value) => {
      return this.filterObject(value, search, data);
    });

    return dataFilter;
  }

  setSearchActive(search) {
    return search !== "";
  }

  // NÃ£o mexer
  filterObject(value, search, data) {
    let fields = Array.from(this.getFieldsNameTable(data));
    let find = false;
    let i;
    for (i = 0; i < fields.length; i++) {
      find = String(
        value[fields[i].valueMask] || value[fields[i].value] || value[fields[i]]
      )
        .toUpperCase()
        .includes(String(search).toUpperCase());
      if (find) break;
    }

    return find;
  }

  getPages(dataList, quantityDisplayed) {
    let data = dataList ? dataList : [];
    //Quantidade total de pÃ¡ginas
    let quantityPages = parseInt(data.length / quantityDisplayed);

    //Adicionar Ãºltima pÃ¡gina para os itens restantes
    let rest = data.length % quantityDisplayed;
    if (rest > 0) quantityPages++;

    return this.getAllPages(quantityPages, quantityDisplayed);
  }

  getPagesFilter(data, quantityDisplayed) {
    let quantityPages = parseInt(data.length / quantityDisplayed);
    //Adicionar Ãºltima pÃ¡gina para os itens restantes
    let rest = data.length % quantityDisplayed;
    if (rest > 0) quantityPages++;

    return this.getAllPages(quantityPages, quantityDisplayed);
  }

  getAllPages(quantityPages, quantityDisplayed) {
    let allPages = new Map();
    let indexes = parseInt(quantityDisplayed);
    let i;
    for (i = 1; i <= quantityPages; i++) {
      allPages.set(i, new Page(i, indexes - quantityDisplayed, indexes));
      indexes += parseInt(quantityDisplayed);
    }
    return allPages;
  }

  getClassContainer() {
    let classe = "";
    classe = classe.concat(col(this.props));
    classe = classe.concat(textAlign(this.props));
    classe = classe.concat(className(this.props));
    return classe;
  }

  getClassTable() {
    let classe = "table tablenone ";
    classe = classe.concat(this.props.striped ? "table-striped " : "");
    classe = classe.concat(this.props.bordered ? "table-bordered " : "");
    classe = classe.concat(this.props.dark ? "table-dark " : "");
    classe = classe.concat(this.props.hover ? "table-hover " : "");
    classe = classe.concat(this.props.fontSmall ? "font-small " : "");
    return classe;
  }

  setQuantidadeExibicao(quantity) {
    if (
      parseInt(this.state.dataFilter.length - this.state.paginaAtual) >
      parseInt(quantity)
    ) {
      let pages = this.getPages(this.state.dataFilter, quantity);

      this.setState({
        quantidadeExibicao: quantity,
        paginas: pages,
      });
    }
  }

  isLastPage() {
    return this.state.paginaAtual === this.state.paginas.size;
  }

  isPaginaAtual(pagina) {
    return this.state.paginaAtual === pagina;
  }

  getPaginationItems() {
    let paginas = new Map(this.state.paginas);
    let arrayPaginas = this.getPaginasLimitandoPaginacao(paginas, 4);
    return arrayPaginas.map(([indexPage, pagina]) => {
      return (
        <PaginationItem
          pageIconSm={this.props.pageIconSm}
          color={Color.NODE.SECONDARY}
          key={indexPage}
          pagina={indexPage}
          active={this.getPageActive(indexPage)}
          handlerClick={(e) => this.filterPagination(indexPage, pagina)}
        ></PaginationItem>
      );
    });
  }

  getPageActive(page) {
    return this.state.paginaAtual === page;
  }

  getPaginasLimitandoPaginacao(paginas, limit) {
    let pages = [];
    const sizePages = this.state.paginas.size;
    const { paginaAtual } = this.state;

    let range = parseInt(sizePages - paginaAtual);

    if (sizePages <= limit) {
      pages = Array.from(paginas).slice(0, sizePages);
    } else if (range <= limit) {
      pages = Array.from(paginas).slice(
        parseInt(paginaAtual) - limit,
        parseInt(paginaAtual)
      );
    } else {
      if (parseInt(limit - paginaAtual) <= 2) {
        pages = Array.from(paginas).slice(
          parseInt(paginaAtual) - 1,
          parseInt(paginaAtual) + parseInt(limit)
        );
      } else {
        pages = Array.from(paginas).slice(
          parseInt(paginaAtual) - 1,
          parseInt(paginaAtual) + parseInt(limit)
        );
      }
    }

    return pages;
  }

  changePage(next) {
    let indexPage = parseInt(this.state.paginaAtual) + (next ? 1 : -1);
    let page = new Map(this.state.paginas).get(indexPage);
    if (Utils.isValueValid(page)) {
      this.filterPagination(indexPage, page);
    }
  }

  changeInitPage() {
    let page = new Map(this.state.paginas).get(1);
    this.filterPagination(1, page);
  }

  changeLastPage() {
    let indexPage = this.state.paginas.size;
    let page = new Map(this.state.paginas).get(indexPage);
    this.filterPagination(indexPage, page);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const data = nextProps.data || this.state.data;

    if (Utils.isValueValid(data) && data) {
      this.setState(
        {
          data: data,
          dataFilter: data,
          paginas: this.getPages(data, 10),
          showError: Utils.isValueValid(data) ? false : true,
        },
        () => {
          this.aplicarMascaras();
        }
      );
    } else if (!Utils.isNotArrayEmpty(data)) {
      this.setState({
        data: [],
        dataFilter: [],
        paginas: this.getPages(data, 10),
        showError: Utils.isValueValid(data) ? false : true,
      });
    }
  }

  getSizeInputSearch({ rota, filter }) {
    if (rota && filter) {
      return "8";
    } else if (rota || filter) {
      return "10";
    } else {
      return "12";
    }
  }

  render() {
    let existePedido = Array.from(this.state.data).length > 0;

    return (
      <Div padding="3" bg={Color.NODE.WHITE} borderLeft={this.props.borderLeft}>
        {this.props.title && (
          <Div inline="start">
            <Title value={this.props.title} type="h4" float="left"></Title>
          </Div>
        )}
        <Div className={this.getClassContainer()} id="table-mobioh">
          <Div inline="between">
            {this.props.search && (
              <Div responsive={this.getSizeInputSearch(this.props)}>
                <InputSearchTable
                  handlerChange={(e) => this.filter(e.target.value)}
                ></InputSearchTable>
              </Div>
            )}
            <Div float="right" inline="center">
              {this.props.filter && (
                <Div margin="2">
                  <ButtonIconOutLine
                    className="btn-sm"
                    btn="bege"
                    toggle="collapse"
                    icon="fa fa-filter"
                    value="Filtrar"
                    target={`#${Id.TABLE.FILTER}`}
                  />
                </Div>
              )}
              {this.props.rota && (
                <Div inline="end">
                  <Link to={this.props.rota}>
                    <ButtonIconOutLine
                      className="btn-sm"
                      btnOutLine={Color.NODE.PRIMARY}
                      icon={Type.ICON.PLUS}
                      value="Adicionar"
                    />
                  </Link>
                </Div>
              )}
            </Div>
          </Div>
          <Div className="collapse" id={Id.TABLE.FILTER}>
            {this.props.filter}
          </Div>
          <br />
          <Div className="table-responsive">
            <table className={this.getClassTable()}>
              <thead>
                <tr>{this.renderHeader()}</tr>
              </thead>
              <tbody>{this.renderData()}</tbody>
            </table>
          </Div>

          {this.props.pagination && existePedido && (
            <Row>
              <Div col="12">
                <Pagination float="right">
                  <PaginationPrevios
                    color={Color.NODE.SECONDARY}
                    text="Primeiro"
                    handlerClick={(e) => this.changeInitPage()}
                  ></PaginationPrevios>
                  <PaginationPrevios
                    color={Color.NODE.SECONDARY}
                    text="<<"
                    handlerClick={(e) => this.changePage(false)}
                  ></PaginationPrevios>
                  {this.getPaginationItems()}
                  <PaginationNext
                    color={Color.NODE.SECONDARY}
                    text=">>"
                    handlerClick={(e) => this.changePage(true)}
                  ></PaginationNext>
                  <PaginationNext
                    color={Color.NODE.SECONDARY}
                    text="Último"
                    handlerClick={(e) => this.changeLastPage()}
                  ></PaginationNext>
                </Pagination>
              </Div>
            </Row>
          )}
        </Div>

        {this.state.showError && (
          <Div id="cardmessage" bg="light" padding="1" inline="center">
            <I
              icon={Type.ICON.INFO}
              sizeIcon="2"
              colorText={Color.NODE.WARNING}
            ></I>
            <Label value={this.props.error} marginLeft="3" marginTop="2" />
          </Div>
        )}
      </Div>
    );
  }
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  fields: PropTypes.array,
  title: PropTypes.string,
  textAlign: PropTypes.string,
  bordered: PropTypes.bool,
  dark: PropTypes.bool,
  striped: PropTypes.bool,
  edit: PropTypes.any,
  delete: PropTypes.any,
  handlerEdit: PropTypes.func,
  handlerDelete: PropTypes.func,
};

Table.defaultProps = {
  striped: true,
  textAlign: "center",
  pageIconSm: true,
};

export default Table;
