import { Http } from '../utilities';

class DepartamentoService {
  static async getDepartamentos() {

    let res = null;
    await Http.get(`/departamentosecao/departamentos`).then(
      response => {
        res = response.data;
      },
    );
    return res;
  }

  static async adminGetDepartamentos() {

    let res = null;
    await Http.get(`/departamentosecao`).then(
      response => {
        res = response.data;
      },
    );
    return res;
  }

  static async adminCreateDepartamentoSec(obj) {
    let res = null;

    await Http.post('/departamentosecao/departamento', obj).then(response => {
      res = response.data;
    });

    return res;

  }

  static async adminDelDepartamentoSec(obj) {
    let res = null;

    await Http.delete('/departamentosecao', obj).then(response => {
      res = response.data;
    });

    return res;

  }

  static async adminPutDepartamentoSec(obj) {
    let res = null;

    await Http.put('/departamentosecao', obj).then(response => {
      res = response.data;
    });

    return res;

  }

  

  static async getSecoes(idDepartamento) {

    let res = null;
    await Http.get(`/departamentosecao/departamentos/${idDepartamento}/secoes`).then(
      response => {
        res = response.data;
      },
    );
    return res;
  }

  static async putDepartamentos(obj) {
    let res = null;

    await Http.put('/menu', obj).then(response => {
      res = response.data;
    });

    return res;

  }
}

export default DepartamentoService;

