import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Div, InputDefault, Notification, Row, Title } from "../../components";
import DepartamentoService from "../../service/DepartamentoService";

class EditarSecao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      departamentos: [],
      secao: [],
      departamentoCustom: "",
      secoesSelectedVincular: [],
    };

  }

  handleChange = (e) => {
    e.stopPropagation();
    let { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };


  confirmEdicao() {
    const { objEdicao } = this.props;

    let newObjEdicao = {
      idDepartamentoSecao: objEdicao.idDepartamentoSecao,
      idDepartamentoSecaoExterno: objEdicao.idDepartamentoSecaoExterno,
      descricao: this.state.departamentoCustom,
    }

    DepartamentoService.adminPutDepartamentoSec(newObjEdicao).then(({ status, mensagem }) => {
      if (status) {
        Notification.success(mensagem)
        this.props.closeComponente()
      } else {
        Notification.danger(mensagem)
      }
    })
  }



  bodyNovoDepartamento() {
    const { departamentoCustom } = this.state;
    const { objEdicao } = this.props;

    console.log(objEdicao)

    return (
      <>
        <Row col="12" padding="0">

          <Div col="11" padding="0">
            <InputDefault
              maxLength={30}
              responsive="12"
              label={`Descrição do ${objEdicao.descricao}`}
              name="departamentoCustom"
              placeholder="Escreva uma Descrição"
              required
              handlerChange={(e) => this.handleChange(e)}
              value={departamentoCustom}
            />
          </Div>

        </Row>

        <Div style={{
          margin: "25px 15px",
          display: "flex",
          justifyContent: "end"
        }}>
          <Button
            handlerClick={(e) => this.confirmEdicao()}
            value="Confirmar"
            btn="primary"
            marginRight="3"
          />

          <Button
            handlerClick={
              (e) => this.setState({
                departamentoCustom: "",
                secaoCustom: "",
              },
                this.props.closeComponente()
              )
            }
            value="Cancelar"
            btn="secondary"
          />
        </Div>
      </>
    )
  }

  render() {
    const { objEdicao } = this.props

    return (
      <Div style={{ width: '100%', margin: "25px 10px 0px 0px" }} shadow className="border">
        <Title
          value={`Editar ${objEdicao.descricao}`}
          type="h4"
          paddingLeft="3"
          paddingRight="4"
          paddingTop="3"
        />

        <Div
          col="12"
          padding="0"
          style={{
            margin: "35px 0px",
          }}
        >
          {this.bodyNovoDepartamento()}
        </Div>

      </Div>
    )
  }

}

const mapStateToProps = (store) => ({
  idEmpresa: store.loginState.user.idEmpresa,
  idFilial: store.loginState.user.idFilial,
  idFuncionario: store.loginState.user.idFuncionario
});

export default connect(mapStateToProps)(EditarSecao);
