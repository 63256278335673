import React, { Component } from "react";
import { Div, I} from "../../..";
import Input from "../Input";
import "./inputlojainstantanea.css";
import If from "../../../if/If";
import { Type, Utils } from "../../../../utilities";
import Zindex from "../../../../views/utils/Zindex";

const ENTER = 13;
const ESC = 27;

class InputSearchLojaInstantanea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      texto: "",
    };
  }

  componentWillReceiveProps({ textoPesquisa }) {
    this.setState({ texto: textoPesquisa });
  }

  adicionarBusca = () => {
    let buscas = Array.from(
      JSON.parse(localStorage.getItem("buscas-anteriores-loja-web") || "[]")
    );

    if (Utils.isValueValid(buscas)) {
      let buscaJaDigitada = Array.from(buscas).filter(
        (b) =>
          String(b).toUpperCase() === String(this.state.texto).toUpperCase()
      )[0];
      if (!Utils.isValueValid(buscaJaDigitada)) {
        if (buscas.length === 8) buscas.splice(0, 1);
        buscas.push(this.state.texto);
      }
    } else {
      buscas.push(this.state.texto);
    }

    localStorage.setItem("buscas-anteriores-loja-web", JSON.stringify(buscas));
  };

  abrirMenuBusca() {
    let div = document.getElementById(this.props.idPesquisa);
    if (Utils.isValueValid(div)) {
      if (!div.classList.contains("collapsed")) {
        document.getElementById(this.props.id).click();
      }
      div.classList.add("collapsed");
    }
  }

  render() {
    let parametros = this.props.empresa.parametros || {};
    return (
      <Div
        style={{ zIndex: 1 }}
        className="input-group container-input-search-lojainstantanea"
      >
        <If and value1={window.innerWidth > 600}>
          <I
            pointer
            handlerClick={(e) => {
              if (Utils.isValueValid(this.state.texto)) {
                this.adicionarBusca();
                this.props.handlerChangePesquisa(
                  this.state.texto,
                  this.props.id
                );
              }
            }}
            icon={Type.ICON.SEARCH}
            width="25px"
            style={{
              position: "absolute",
              color: parametros.corPrimaria,
              left: 5,
              bottom: 5,
              zIndex: Zindex.MIL_E_DOIS,
              transform: `rotate(90deg)`,
            }}
          />
        </If>

        <Div
          target={`#${this.props.idPesquisa}`}
          toggle="collapse"
          id={this.props.id}
        />

        <Input
          className="input-search-lojainstantanea pl-5"
          placeholder="PESQUISAR PRODUTOS"
          style={{ fontFamily: "MetropolisLight" }}
          handlerClick={() => {
            this.abrirMenuBusca();
            this.props.handlerAbrirPesquisa();
          }}
          handlerChange={(e) => this.setState({ texto: e.target.value })}
          handlerKeyUp={(e) => {
            if (e.keyCode === ENTER) {
              if (Utils.isValueValid(this.state.texto)) {
                this.adicionarBusca();
                this.props.handlerChangePesquisa(
                  this.state.texto,
                  this.props.id
                );
              }
            } else if (e.keyCode === ESC) {
              this.setState({ texto: "" });
              this.props.handlerFecharPesquisa(this.props.id);
            }
          }}
          /**
          *  handlerBlur={(e) => {
            if (Utils.isValueValid(this.state.texto)) {
              this.adicionarBusca();
              this.props.handlerChangePesquisa(
                this.state.texto,
                this.props.id
              );
            }
          }}
          */
          value={this.state.texto}
        />
      </Div>
    );
  }
}

export default InputSearchLojaInstantanea;
