import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ItemProdutoMobile } from ".";
import { CarouselBannerGenericoMobile, Div, ModalNotification } from "../components";
import {
  atualizarCarrinho,
  setProdutos,
  setEmpresa,
  setParametrosPedido,
} from "../redux/actions";
import { EndPoints, Http, Utils } from "../utilities";
import CarrinhoUtils from "./utils/CarrinhoUtils";
import ProdutoUtils from "./utils/ProdutoUtils";

const styles = {
  titleCarouselOferta: {
    fontSize: Utils.isViewMobileLarguraPequeno() ? 12 : 15,
    color: "white",
    whiteSpace: "wrap",
    width: Utils.isViewMobileLarguraPequeno() ? 100 : 200,
    position: "absolute",
    transform: Utils.isViewMobileLarguraPequeno()
      ? `translateX(12px)`
      : `translateX(30px)`,
  },
  titleCarouselOfertaLight: {
    fontSize: Utils.isViewMobileLarguraPequeno() ? 12 : 15,
    color: "white",
    whiteSpace: "wrap",
    width: Utils.isViewMobileLarguraPequeno() ? 100 : 200,
    position: "absolute",
    transform: Utils.isViewMobileLarguraPequeno()
      ? `translateX(12px)`
      : `translateX(30px)`,
  },
};

class PrincipalMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anuncios: [],
      produtos: [],
      menus: [],
      produtosVisualizados: [],
      produtosSimilares: [],
      carrinho: props.carrinho,
      empresa: {},
      zIndexHeader: 1001,
      listaSelecionada: null,
    };

    this.visualizarProdutosAnuncio = this.visualizarProdutosAnuncio.bind(this);
  }

  componentDidMount() {
    this.listarProdutos()
  }

  listarProdutos() {
    let param = {
      "numeroPagina": 1,
      "descricao": "",
      "departamentos": [],
      "secoes": [],
      "marcas": [],
      "destaque": true
    }

    Http.post(EndPoints.PRODUTO_BUSCA_FILTRO, param)
      .then(({ status, data }) => {
        if (status) {
          this.setState(
            {
              produtos: [...data.listaProdutos],
            }
          );
        }
      })
      .catch((erro) => console.log(erro));
  }

  visualizarProdutosAnuncio({
    titulo,
    idAnuncioOferta,
    idDepartamentoSecaoFiltro,
    tipoDepartamentoSecao,
    marcaFiltro,
    possuiProdutos,
    estatico,
  }) {
    if (!estatico) {
      this.props.history.push("/anuncio/produtos", {
        titulo,
        idAnuncioOferta,
        idDepartamentoSecaoFiltro,
        tipoDepartamentoSecao,
        marcaFiltro,
        empresa: this.props.empresa,
        possuiProdutos,
      });
    }
  }

  getItensBannerTopo() {
    const anuncios = this.props.anuncios;
    let itens = [];

    let itensTopo = Array.from(anuncios.banner || []);

    if (Utils.isValueValid(itensTopo)) {
      itensTopo.forEach((item) => {
        itens.push(
          <>
            {item.exibirVideo ? (
              <img
                src={item.urlFotoMobile}
                onClick={() => this.renderVideo(item)}
                width="100%"
                className="cursor-pointer"
              />
            ) : (
              <img
                src={item.urlFotoMobile}
                width="100%"
                className="cursor-pointer"
                onClick={() => this.visualizarProdutosAnuncio(item)}
              />
            )}
          </>
        );
      });
    }

    return itens;
  }

  renderVideo(item) {
    ModalNotification.renderMessage({
      title: item.titulo,
      body: (
        <Div inline="center">
          <video src={item.urlVideo} controls style={{ width: "100%" }} />
        </Div>
      ),
      responsive: "6",
      handlerCancel: () => {
        ModalNotification.removeModal();
      },
      labelButtonCancel: "Fechar",
    });
  }

  async adicionarItemNoCarrinho(produto, quantidade, adicionar) {
    let qdt = quantidade || 0

    if (adicionar) {
      qdt = quantidade ? quantidade + 1 : 1
    } else {
      if (produto.quantidade >= 1) {
        qdt = quantidade - 1
      }
    }

    let { carrinho, produtos } = this.props;
    let prod = ProdutoUtils.calcularValorProduto(produto, qdt);

    CarrinhoUtils.adicionarItemNoCarrinho(
      prod,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      null,
      this.props.parametros
    );
  }



  render() {

    return (
      <>
        <Div className="w-100 overflow-hidden" style={{ marginTop: 20 }}>
          <CarouselBannerGenericoMobile
            timeSlide={7000}
            itens={this.getItensBannerTopo()}
            slidesPerPage={1}
            dots
            isMobile={true}
          />
        </Div>
        <Div
          marginTop="2"
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {
            this.state.produtos.map(p => {
              return (
                <ItemProdutoMobile
                  history={this.props.history}
                  image={p.urlImagemPrincipalThumbnail}
                  descricao={p.descricao}
                  quantidade={p.quantidade}
                  disponivel={p.disponivel}
                  proibidaVenda={p.proibidaVenda}
                  precosConcorrentes={p.precosConcorrentes}
                  empresa={this.props.empresa}
                  produto={p}
                  idProdutoWeb={p.idProdutoWeb}
                  handlerAddItemCarrinho={(adicionar, quantidade, callback) => {
                    this.adicionarItemNoCarrinho(p, quantidade, adicionar).then(() => {
                      if (callback) {
                        callback(p)
                      }
                    });
                  }}

                />
              )
            })
          }
        </Div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.parametrosPedidoState,
    ...store.empresaState,
    ...store.listasDeCompraState,
    ...store.menusState,
    ...store.anunciosState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { atualizarCarrinho, setProdutos, setEmpresa, setParametrosPedido },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PrincipalMobile);
