import React, { Component } from "react";
import { EmpresaUtils, ProdutoUtils } from "../..";
import {
  Div,
  I,
  If,
  Image,
  Label,
  NavbarTab,
} from "../../../components";
import CarouselImagens from "../../../components/carousel/CarouselImagens";
import ImageWithZoom from "../../../components/image/ImageWithZoom";
import { Color, Mask, Type, Utils } from "../../../utilities";
import TagManagerUtils from "../../../utilities/utils/TagManagerUtils";
import CarrinhoUtils from "../../utils/CarrinhoUtils";
import ProdutoTabsDetalhes from "./ProdutoTabsDetalhes";
//import { FacebookShareButton, WhatsappShareButton, FacebookIcon, WhatsappIcon } from "react-share";

const styles = {
  descricao: {
    fontSize: "30px",
    color: "#878787",
    fontFamily: "MetropolisBold",
  },
  codigoReferencia: {
    fontSize: 11,
    color: "#878787",
  },
  containterPreco: {
    height: window.innerHeight / 3,
  },
  precoVenda: {
    color: Color.ECOMMERCE.LARANJA,
    fontFamily: "MetropolisBold",
    fontSize: 25,
  },
  precoAtacado: {
    color: "#46160E",
    fontSize: 15,
  },
  containerQuantidade: {
    borderRadius: "5px",
    width: "100%",
  },
  botaoAdicionar: {
    color: Color.ECOMMERCE.LARANJA,
    fontSize: 30,
    fontWeight: "bold",
  },
  botaoDiminuir: {
    color: Color.ECOMMERCE.LARANJA,
    fontSize: 30,
    fontWeight: "bold",
  },
  containerBotaoComprar: {
    backgroundColor: Color.EMPRESA.TERCIARIA,
    color: "white",
    fontFamily: "MetropolisSemiBold",
    fontSize: 20,
    borderRadius: "5px",
    textAlign: "center",
    paddingTop: 10,
  },
  containerBotaoColorCarrinho: {
    backgroundColor: Color.EMPRESA.PRIMARIA,
    opacity: 0.9,
    color: "white",
    fontFamily: "MetropolisLight",
    fontSize: 20,
    borderRadius: "5px",
    paddingTop: 10,
    width: "100%",
  },
  containerCompartilhar: {
    opacity: 0.9,
    color: Color.EMPRESA.PRIMARIA,
    fontFamily: "MetropolisLight",
    fontSize: 20,
    paddingTop: 10,
    width: "100%",
    border: `1px solid ${Color.EMPRESA.PRIMARIA}`,
    borderRadius: "5px",
  },
  containerAdicionarMinhaLista: {
    backgroundColor: Color.EMPRESA.SECUNDARIA,
    color: "white",
    fontSize: 20,
    borderRadius: "5px",
    paddingTop: 10,
    width: "100%",
  },
  labelPeso: {
    textAlign: "center",
    backgroundColor: "#f2f2f2",
    width: "50%",
    padding: 10,
    borderRightTop: 10,
  },
  labelPesoActive: {
    textAlign: "center",
    width: "50%",
    padding: 10,
    backgroundColor: Color.ECOMMERCE.AZUL,
    color: Color.ECOMMERCE.WHITE,
    borderRightTop: 10,
  },
  labelUnidade: {
    backgroundColor: "#f2f2f2",
    textAlign: "center",
    padding: 10,
    width: "50%",
  },
  labelUnidadeActive: {
    textAlign: "center",
    padding: 10,
    width: "50%",
    backgroundColor: Color.ECOMMERCE.AZUL,
    color: Color.ECOMMERCE.WHITE,
  },
};

const getImagensProduto = (produto) => {
  let imagens = [];

  if (Utils.isValueValid(produto.imagens)) {
    let imagensProduto = Array.from(produto.imagens);

    imagensProduto.forEach((img, index) => {
      img.active = index === 0;
      img.width = "400px";
      img.height = "400px";
    });

    imagens = imagensProduto;
  } else {
    imagens = [{ url: produto.urlImagemPrincipal }];
  }

  return imagens;
};

class ProdutoDetalhe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantidade: "0",
      imagemProduto: props.produto.urlImagemPrincipal,
      quantidadePeso: this.getQuantidadePeso(props.produto),
      quantidadeUnidade: this.getQuantidadeUnidade(props.produto),
      haderInfoProdutoCombo: "PRODUTOS_COMBO",
      listaSelecionada: null,
      share: false,
      qdt: this.props.qdt,
      detalhes: ""
    };

    this.atualizarProdutoDetalhe = this.atualizarProdutoDetalhe.bind(this);

    this.adicionarItem = this.adicionarItem.bind(this);
    this.removerItem = this.removerItem.bind(this);
  }

  getQuantidadePeso(produto) {
    return (
      produto.quantidadePeso ||
      (produto.possuiQuantidadeFracionada && !produto.quantidadeUnidade)
    );
  }

  getProdutoUrl() {
    let URL = window.location.origin + "/produto/ean/" + this.props.produto.codigoBarras
    return URL
  }

  getQuantidadeUnidade(produto) {
    return (
      produto.quantidadeUnidade ||
      (!produto.possuiQuantidadeFracionada && produto.quantidadeUnidade)
    );
  }

  componentDidMount() {
    let produtoNoCarrinho = this.getProdutoNoCarrinho(
      this.props.produto.codigoBarras
    );
    let produto = Utils.isValueValid(produtoNoCarrinho)
      ? produtoNoCarrinho
      : this.props.produto;

    let quantidadeProduto = Number(
      this.consultarQuantidadeProdutoCarrinho(produto.codigoBarras)
    );

    if (produto.possuiQuantidadeFracionada && produto.quantidadeUnidade) {
      quantidadeProduto =
        Number(quantidadeProduto) / Number(produto.fatorEmGrama);
    }

    if (quantidadeProduto > 0) {
      this.setState({
        quantidade: quantidadeProduto,
        quantidadePeso: this.getQuantidadePeso(produto),
        quantidadeUnidade: this.getQuantidadeUnidade(produto),
      });
    } else {
      this.addQuantidadeItemState(produto);
    }

    TagManagerUtils.tagManagerVizualizouItem(produto)

    this.setState({
      qdt: this.props.qdt
    })

  }

  componentDidUpdate(prevProps) {
    if (prevProps.produto !== this.props.produto) {
      TagManagerUtils.tagManagerVizualizouItem(this.props.produto)
    }

    if(prevProps.qdt !== this.props.qdt) {
      this.setState({
        qdt: this.props.qdt
      })
    }
  }

  getHeaderInfoProdutoCombo = () => {
    return [
      {
        target: "PRODUTOS_COMBO",
        value: "ITENS DO COMBO",
        active: "PRODUTOS_COMBO" === this.state.haderInfoProdutoCombo,
      },
      {
        target: "INFORMACOES",
        value: "INFORMAÇÕES",
        active: "INFORMACOES" === this.state.haderInfoProdutoCombo,
      },
    ];
  };

  renderProdutosCombo = (produtos) => {
    let idParent = Utils.uuidString();
    return (
      <Div col="10" id={idParent}>
        {Array.from(produtos).map((prod, index) => {
          let unidade = prod.possuiQuantidadeFracionada ? "KG" : "UN";
          return (
            <Div key={index}>
              <Div
                inline="between"
                padding="2"
                style={{ width: "100%", borderBottom: "1px solid #f2f2f2" }}
              >
                <Div inline="start" style={{ width: "100%" }}>
                  <Image
                    border
                    src={prod.urlImagemPrincipalThumbnail}
                    width="70px"
                  />
                  <Label
                    className="text-ellipsis"
                    value={`${prod.quantidade} ${unidade} ${Utils.capitalize(
                      prod.descricao
                    )}`}
                    marginLeft="2"
                    family="Light"
                  />
                </Div>
              </Div>
            </Div>
          );
        })}
      </Div>
    );
  };

  UNSAFE_componentWillReceiveProps(props) {
    let produtoNoCarrinho = this.getProdutoNoCarrinho(
      props.produto.codigoBarras
    );
    let produto = Utils.isValueValid(produtoNoCarrinho)
      ? produtoNoCarrinho
      : props.produto;

    let quantidadeProduto = Number(
      this.consultarQuantidadeProdutoCarrinho(produto.codigoBarras)
    );

    if (produto.possuiQuantidadeFracionada && produto.quantidadeUnidade) {
      quantidadeProduto =
        Number(quantidadeProduto) / Number(produto.fatorEmGrama);
    }

    this.setState(
      {
        imagemProduto: produto.urlImagemPrincipal,
        quantidade: quantidadeProduto,
        quantidadePeso: this.getQuantidadePeso(produto),
        quantidadeUnidade: this.getQuantidadeUnidade(produto),
        qdt: this.props.qdt
      },
      () => {
        if (quantidadeProduto === 0) {
          this.addQuantidadeItemState(produto);
        }
      }
    );
  }

  getProdutoNoCarrinho(codigoBarras) {
    let carrinho = CarrinhoUtils.getCarrinho();

    let produto = {};

    if (Utils.isValueValid(carrinho)) {
      let itens = Array.from(carrinho.itens || []);
      let produto = itens.filter(
        (item) => item.codigoBarras === codigoBarras
      )[0];

      return produto;
    }

    return produto;
  }

  consultarQuantidadeProdutoCarrinho(codigoBarras) {
    let carrinho = CarrinhoUtils.getCarrinho();

    let quantidade = 0;

    if (Utils.isValueValid(carrinho)) {
      let itens = Array.from(carrinho.itens || []);
      let produtoNoCarrinho = itens.filter(
        (item) => item.codigoBarras === codigoBarras
      )[0];

      if (Utils.isValueValid(produtoNoCarrinho)) {
        quantidade = produtoNoCarrinho.quantidade;
      }
    }

    return quantidade;
  }

  consultarUnidade(codigoBarras) {
    let carrinho = CarrinhoUtils.getCarrinho();
    let labelUnidade = "";

    if (Utils.isValueValid(carrinho)) {
      let itens = Array.from(carrinho.itens || []);
      let produtoNoCarrinho = itens.filter(
        (item) => item.codigoBarras === codigoBarras
      )[0];

      if (Utils.isValueValid(produtoNoCarrinho)) {
        if (produtoNoCarrinho.possuiQuantidadeFracionada) {
          labelUnidade = this.state.quantidadePeso ? "g" : "un";
        } else {
          labelUnidade = "un";
        }
      } else {
        if (this.props.produto.possuiQuantidadeFracionada) {
          labelUnidade = this.state.quantidadePeso ? "g" : "un";
        } else {
          labelUnidade = "un";
        }
      }
    }

    return labelUnidade;
  }

  atualizarProdutoDetalhe() {
    this.forceUpdate();
  }

  addQuantidadeItemState(item) {
    let quantidade = Number(this.state.quantidade || 0);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      if (this.state.quantidadePeso) {
        quantidade = quantidade + Number(item.fatorEmGrama);
      } else {
        quantidade = quantidade + 1;
      }
    } else {
      quantidade = quantidade + 1;
    }

    this.setState({ quantidade });
  }

  addQuantidadeItemBotaoAdicionar(item) {
    let quantidade = 0;

    if (item.possuiQuantidadeFracionada && this.state.quantidadeUnidade) {
      quantidade = Number(item.fatorEmGrama) * Number(this.state.quantidade);
    } else {
      quantidade = Number(this.state.quantidade);
    }

    item.quantidadePeso = this.state.quantidadePeso;
    item.quantidadeUnidade = this.state.quantidadeUnidade;

    item = ProdutoUtils.calcularValorProduto(item, quantidade);
    this.props.handlerAddItem(item, this.atualizarProdutoDetalhe);
  }

  addQuantidadeItem(item) {
    let quantidade = item.quantidade || 0;

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade + Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade + 1;
    }

    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    this.props.handlerAddItem(item, this.atualizarProdutoDetalhe);
  }

  removeQuantidadeItemState(item) {
    let quantidade = Number(this.state.quantidade || 0);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      if (this.state.quantidadePeso) {
        quantidade = quantidade - Number(item.fatorEmGrama);
      } else {
        quantidade = quantidade - 1;
      }
    } else {
      quantidade = quantidade - 1;
    }

    this.setState({ quantidade });
  }

  removeQuantidadeItem(item) {
    let quantidade = item.quantidade || 0;

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade - Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade - 1;
    }

    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    this.props.handlerAddItem(item, this.atualizarProdutoDetalhe);
  }

  adicionarItem() {
    this.addQuantidadeItem(this.props.produto);
  }

  removerItem() {
    this.removeQuantidadeItem(this.props.produto);
  }

  renderPrecos(corPrimaria) {
    const { produto } = this.props;
    let aceitaKG = Number(produto.fatorEmGrama) > 0;
    let precoPorFator =
      (Number(produto.precoVenda) * Number(produto.fatorEmGrama)) / 1000;

    return (
      <Div paddingTop="5" className="d-flex flex-column">
        <If and value1={produto.possuiQuantidadeFracionada}>
          <Label
            value={`${Mask.formatValueBr(produto.precoVenda)} / KG`}
            style={{ ...styles.precoVenda, color: corPrimaria }}
          />
          <If and value1={aceitaKG}>
            <Label
              value={`${Mask.formatValueBr(
                precoPorFator
              )} cada unidade de ${Number(produto.fatorEmGrama)}g`}
              style={{ ...styles.precoVenda, color: corPrimaria }}
            />
          </If>
        </If>
        <If and value1={!produto.possuiQuantidadeFracionada}>
          <Label
            value={Mask.formatValueBr(produto.precoVenda)}
            style={{ ...styles.precoVenda, color: corPrimaria }}
          />
        </If>
        <If
          and
          value1={Utils.isValueValid(produto.precoAtacado)}
          value2={produto.precoAtacado > 0}
        >
          <Label
            value={`${Mask.formatValueBr(produto.precoAtacado)} à partir de ${produto.quantidadeMinimaAtacado
              } unidades`}
            family="SemiBold"
            style={styles.precoAtacado}
          />
        </If>
      </Div>
    );
  }

  renderLabelsPesoUnidade() {
    let { produto, quantidadePeso, quantidadeUnidade } = this.state;
    return (
      <Div style={styles.containerQuantidade}>
        <Label
          value="Peso"
          style={quantidadePeso ? styles.labelPesoActive : styles.labelPeso}
          pointer
          handlerClick={() => {
            if (this.state.quantidadeUnidade) {
              let quantidadeConvertida =
                this.getQuantidadeProdutoConvertida(true);
              this.setState({
                quantidadePeso: true,
                quantidadeUnidade: false,
                quantidade: quantidadeConvertida,
              });
            }
          }}
        />
        <Label
          value="Unidade"
          style={
            quantidadeUnidade ? styles.labelUnidadeActive : styles.labelUnidade
          }
          pointer
          handlerClick={() => {
            if (this.state.quantidadePeso) {
              let quantidadeConvertida =
                this.getQuantidadeProdutoConvertida(false);
              this.setState({
                quantidadePeso: false,
                quantidadeUnidade: true,
                quantidade: quantidadeConvertida,
              });
            }
          }}
        />
      </Div>
    );
  }

  getQuantidadeProdutoConvertida(converterParaPeso = false) {
    const { quantidadePeso, quantidadeUnidade, quantidade } = this.state;
    let produto = this.props.produto;
    let quantidadeConvertida = 0;

    if (converterParaPeso) {
      quantidadeConvertida = Number(produto.fatorEmGrama) * Number(quantidade);
    } else {
      quantidadeConvertida = Number(quantidade) / Number(produto.fatorEmGrama);
    }

    return quantidadeConvertida;
  }

  render() {
    let { produto} = this.props;
    const imagens = getImagensProduto(produto);

    let detalhes = produto.detalhes
    let filtros = Utils.isNotNull(produto.filtros) ? produto.filtros : []

    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);

    return (
      <Div inline="center" martinTop="5" marginBottom="5">
        <If and value1={Utils.isValueValid(imagens) && imagens.length === 1}>
          <Div>
            <ImageWithZoom
              width="400px"
              height="400px"
              src={this.state.imagemProduto}
              className="img-thumbnail"
              onError={() =>
                this.setState({
                  imagemProduto: ProdutoUtils.URL_IMAGEM_PRODUTO_DEFAULT,
                })
              }
            />
          </Div>
        </If>
        <If and value1={Utils.isValueValid(imagens) && imagens.length > 1}>
          <Div responsive="5">
            <CarouselImagens itens={imagens} />
          </Div>
        </If>
        <Div responsive="4" className="d-flex flex-column">
          <Label
            value={Utils.capitalize(produto.descricao)}
            style={styles.descricao}
          />
          <Div inline="start">
            <Label
              value={`Cód. Referência: ${produto.codigoBarras}`}
              style={styles.codigoReferencia}
              family="Light"
            />
            <If and value1={Utils.isValueValid(produto.marca)}>
              <Label
                value={`Marca: ${produto.marca}`}
                marginLeft="2"
                family="Light"
                style={styles.codigoReferencia}
              />
            </If>
          </Div>
          <Label value={produto.informacoes} style={{ textAlign: "justify" }} />
          {/* <If and value1={!produto.proibidaVenda}>
            {this.renderPrecos(corPrimaria)}
          </If> */}

          {Utils.isValueValid(produto.precosConcorrentes) && (
            <Div
              style={{
                borderRadius: 10,
                backgroundColor: "#f2f2f2",
                paddingLeft: 5,
                paddingRight: 5,
                marginTop: 5,
              }}
              inline="start"
            >
              <Div style={{ width: "50%" }}>
                {Array.from(produto.precosConcorrentes).map(
                  (concorrente, index) => {
                    return (
                      <Div
                        inline="between"
                        key={index}
                        style={{
                          color: Color.ECOMMERCE.CINZA_ESCURO,
                          padding: 1,
                        }}
                      >
                        <Div inline="start">
                          <Image src={concorrente.urlFoto} width="26px" />
                          <Label
                            value={Mask.formatValueBr(concorrente.precoVenda)}
                            family="SemiBold"
                            marginTop="2"
                            marginLeft="2"
                          />
                        </Div>
                      </Div>
                    );
                  }
                )}
              </Div>
              <Div
                style={{
                  color: "#31CDFF",
                  paddingRight: 15,
                  paddingTop: 20,
                  width: "50%",
                }}
              >
                <Div inline="end" style={{ color: "#31CDFF" }}>
                  <Label value="Economize" family="SemiBold" />
                </Div>
                <Div inline="end">
                  <Label
                    value={`${Array.from(produto.precosConcorrentes)[0]
                      .percentualEconomia
                      }%`}
                    style={{ fontSize: 20 }}
                    family="Bold"
                  />
                </Div>
              </Div>
            </Div>
          )}
        </Div>
        <If and value1={!produto.proibidaVenda && produto.disponivel}>
          <Div responsive="3" column>
            <If and value1={produto.possuiQuantidadeFracionada}>
              {this.renderLabelsPesoUnidade()}
            </If>

            <Div
              bg={Color.NODE.LIGHT}
              inline="between"
              padding="2"
              style={styles.containerQuantidade}
              marginTop="2"
            >
              <I
                icon={Type.ICON.MINUS}
                sizeIcon="2"
                style={{ color: corPrimaria }}
                pointer
                handlerClick={() => {
                  this.setState({ qdt: this.state.qdt - 1 }, this.props.removeQuantidadeItem())
                }}
              />

              <Label
                value={`${this.state.qdt}` || "0"}
                family="Light"
                style={{ fontSize: 20 }}
              />

              <I
                icon={Type.ICON.PLUS}
                sizeIcon="2"
                style={{ color: corPrimaria }}
                pointer
                handlerClick={() => {
                  this.setState({ qdt: this.state.qdt + 1 }, this.props.addQuantidadeItem())
                }}
              />
            </Div>
            <Div
              style={{
                ...styles.containerBotaoColorCarrinho,
                backgroundColor: corPrimaria,
              }}
              marginTop="2"
              inline="center"
              pointer
              handlerClick={() => {
                this.props.addQuantidadeItem()  
                this.props.closeModal()              
              }}
            >
              <Label
                pointer
                value={"Adicionar a Lista"}
                className="label-adicionar-carrinho"
              />
            </Div>

            {/* <Div
              style={styles.containerCompartilhar}
              marginTop="2"
              pointer
              inline="center"
              handlerClick={() => { 
                this.setState({
                  share: !this.state.share
                })
              }}
              
            >
              <I
                icon={Type.ICON.SHARE}
                sizeIcon="1"
                style={{ color: corPrimaria, margin: "-5px 10px 0px 10px" }}
                pointer
              />

              <Label
                pointer
                value="Compartilhar"
                className="label-adicionar-carrinho"
              />

            </Div>
            
            <Div marginTop="1" inline="center" invisible={!this.state.share}>
              <FacebookShareButton url={this.getProdutoUrl()} style={{ margin: "0px 10px"}}>
                <FacebookIcon size={40} round={true} />
              </FacebookShareButton>

              <WhatsappShareButton url={this.getProdutoUrl()}>
                <WhatsappIcon size={40} round={true} />
              </WhatsappShareButton>
            </Div> */}

          </Div>
        </If>
        {Utils.isNotNull(detalhes) && (
          <ProdutoTabsDetalhes 
            detalhes={filtros} 
            info={false} 
            infoText={detalhes} 
            active="INFORMACOES"
          />
        )}
        {produto.combo && (
          <Div
            className="d-flex flex-column"
            style={{ width: "100%", marginLeft: 100 }}
            marginTop="3"
          >
            {
              <NavbarTab
                itens={this.getHeaderInfoProdutoCombo()}
                styleItem={{
                  fontSize: 13,
                  color: "#c8c8c8",
                  fontWeight: "bold",
                }}
                handlerClick={(item) =>
                  this.setState({ haderInfoProdutoCombo: item.target })
                }
              />
            }
            {this.state.haderInfoProdutoCombo === "PRODUTOS_COMBO" &&
              this.renderProdutosCombo(produto.itensCombo)}
            {this.state.haderInfoProdutoCombo === "INFORMACOES" && (
              <Label value={produto.informacoes || "Produto sem informação"} />
            )}
          </Div>
        )}
      </Div>
    );
  }
}

export default ProdutoDetalhe;
