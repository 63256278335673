import React, { Component } from "react";
import {
  Checkbox,
  Div,
  I,
  If,
  Label,
  ModalNotification,
} from "../../components";
import { Color, EndPoints, Http, Type, Utils } from "../../utilities";
import "./produtos.css";

import ProdutoDetalhe from "./web/ProdutoDetalhe";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { atualizarCarrinho, setProdutos } from "../../redux/actions";

import { EmpresaUtils, ProdutoUtils } from "..";
import ContatoWhatsapp from "../ContatoWhatsapp";
import CarrinhoUtils from "../utils/CarrinhoUtils";
import UsuarioUtils from "../utils/UsuarioUtils";
import { toast } from "react-toastify";
import ItemProduto from "../ItemProduto";

const styles = {
  title: {
    color: "#878787",
    padding: "20px 0px",
    //  borderBottom: "1px solid rgb(196, 196, 196)",
    marginBottom: "15px",
    width: "100%",
  },
  verMais: {
    display: "flex",
    color: "blue",
    width: "100%",
    margin: "3px",
    cursor: "pointer",
    fontWeight: "600"
  },
  labelFiltros: {
    color: Color.EMPRESA.SECUNDARIA,
    paddingTop: 12,
    paddingLeft: 10,
  },
  labelResultado: {
    color: Color.EMPRESA.SECUNDARIA,
    fontSize: 25,
  },
  labelPrecoActive: {
    border: "2px solid #E94E25",
    width: "100%",
    margin: 3,
    color: "#878787",
  },
  labelPreco: {
    width: "100%",
    margin: 3,
    color: "#878787",
  },
};

class ProdutosPesquisa extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: [],
      produtosFiltrados: [],
      produtosSimilares: [],
      classificacoes: [],
      textoPesquisa: "",

      resultadoPesquisa: {
        termoPesquisado: "",
        totalEncontrado: 1,
        sucessoPesquisa: true,
        listaProdutos: [],
        listaProdutosSugeridos: null,
        filtros: []
      },

      mostrarCategoria: true,
      mostrarMarca: true,
      mostrarPers: true,
      mostrarDepart: true,
      vejaMaisCategoria: true,
      vejaMaisMarca: true,
      vejaMaisPers: true,
      vejaMaisDepart: true,

      ...props.location.state,
    };

    this.adicionarItemNoCarrinho = this.adicionarItemNoCarrinho.bind(this);
    this.listarProdutosSimilares = this.listarProdutosSimilares.bind(this);
  }

  listarProdutosSimilares(produto) {
    Http.get(EndPoints.PRODUTOS_SIMILARES.replace("%s", produto.idProdutoWeb))
      .then(({ status, data }) => {
        if (status) {
          this.setState({ produtosSimilares: data }, () =>
            this.visualizarProduto(produto)
          );
        } else {
          this.setState({ produtosSimilares: [] }, () =>
            this.visualizarProduto(produto)
          );
        }
      })
      .catch((error) => console.log(error));
  }

  sortByTerm = (data, term) => {
    let dataSort = Utils.sort(data, "descricao");
    let termString = String(term).toLowerCase().trim();

    let dataEquals = dataSort.filter((d) => {
      let descricaoFormatada = String(d.descricao)
        .split(" ")[0]
        .toLowerCase()
        .trim();
      return descricaoFormatada === termString && d.disponivel;
    });

    let dataStartsWith = dataSort.filter((d) => {
      let descricaoFormatada = String(d.descricao)
        .split(" ")[0]
        .toLowerCase()
        .trim();
      return descricaoFormatada !== termString && d.disponivel;
    });

    let dataIndisponivel = dataSort.filter((d) => !d.disponivel);

    dataStartsWith.sort(function (a, b) {
      let descricaoFormatada = String(a.descricao)
        .split(" ")[0]
        .toLowerCase()
        .trim();

      return descricaoFormatada.startsWith(termString) && a.disponivel ? -1 : 1;
    });

    return [...dataEquals, ...dataStartsWith, ...dataIndisponivel];
  };

  UNSAFE_componentWillReceiveProps(props) {
    let state = props.location.state;

    if (!state) {
      state = this.state;

      let urlPesquisa = this.props.location.pathname.replace("/produto/ean/", "")

      this.realizarPesquisa(urlPesquisa);

    }

    if (state) {
      let produtos = state.resultadoPesquisa.listaProdutos;
      let produtosFiltrados = this.sortByTerm(produtos, state.textoPesquisa);

      this.setState(
        {
          resultadoPesquisa: state.resultadoPesquisa,
          produtos: produtos,
          empresa: state.empresa,
          produtosFiltrados,
          textoPesquisa: state.textoPesquisa,
          classificacoes: state.classificacoes,
        },
        () => Utils.scrollTop()
      );
    }



  }

  componentDidMount() {
    let produtos = this.state.resultadoPesquisa.listaProdutos;
    let produtosFiltrados = this.sortByTerm(produtos, this.state.textoPesquisa);

    this.setState({
      produtos: produtos,
      produtosFiltrados,
    });

    this.handlerResizeView();
    this.consultarParametrosPedido();
    Utils.scrollTop();
  }

  realizarPesquisa(texto) {
    Http.get(`/busca/${texto}`)
      .then(({ status, data }) => {
        console.log("pesquisa resultado", data)
        if (status) {
          this.setState(
            {
              resultadoPesquisa: data,
              produtos: data.listaProdutos,
              empresa: this.props.empresa,
              produtosFiltrados: this.sortByTerm(data.listaProdutos, texto),
              textoPesquisa: texto,
              classificacoes: this.state.classificacoes,
            },
            () => {
              if (data.listaProdutos.length === 1) {
                this.visualizarProduto(data.listaProdutos[0])
              }
            }
          );
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  handlerResizeView() {
    let abc = this;
    window.onresize = function (event) {
      abc.forceUpdate();
    };
  }

  limparFiltros() {
    let resultadoPesquisa = this.state.resultadoPesquisa;
    Array.from(resultadoPesquisa.filtros).forEach((filtro) => {
      Array.from(filtro.itens).map((item) => (item.active = false));
    });

    this.setState({ resultadoPesquisa }, () => this.aplicarFiltros());
  }

  aplicarFiltros() {
    let produtos = JSON.parse(
      JSON.stringify(this.props.location.state.resultadoPesquisa.listaProdutos)
    );

    let filtroPreco = Array.from(this.state.resultadoPesquisa.filtros).filter(
      (filtro) => filtro.tipo === "PRECO"
    )[0];

    let itensPrecoMarcados = Array.from(filtroPreco.itens).filter(
      (item) => item.active
    );

    let filtroDepartamento = Array.from(
      this.state.resultadoPesquisa.filtros
    ).filter((filtro) => filtro.tipo === "DEPARTAMENTO")[0];

    let itensDepartamentoMarcados = Array.from(filtroDepartamento.itens).filter(
      (item) => item.active
    );

    let filtroSecao = Array.from(this.state.resultadoPesquisa.filtros).filter(
      (filtro) => filtro.tipo === "SECAO"
    )[0];

    let itensSecaoMarcados = Array.from(filtroSecao.itens).filter(
      (item) => item.active
    );

    let filtroMarca = Array.from(this.state.resultadoPesquisa.filtros).filter(
      (filtro) => filtro.tipo === "MARCA"
    )[0];

    let itensMarcaMarcados = Array.from(filtroMarca.itens).filter(
      (item) => item.active
    );

    let produtosFiltrados = [];

    if (Utils.isValueValid(itensDepartamentoMarcados)) {
      let filtros = itensDepartamentoMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (filtros.includes(filtroProduto.departamento)) {
            produtosFiltrados.push(produto);
          }
        });
      }
    }

    if (Utils.isValueValid(itensSecaoMarcados)) {
      let filtros = itensSecaoMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (filtros.includes(filtroProduto.secao)) {
            let produtoExiste = produtosFiltrados.indexOf(produto);
            if (produtoExiste === -1) {
              produtosFiltrados.push(produto);
            }
          }
        });
      }
    }

    if (Utils.isValueValid(itensMarcaMarcados)) {
      let filtros = itensMarcaMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (filtros.includes(filtroProduto.marca)) {
            let produtoExiste = produtosFiltrados.indexOf(produto);
            if (produtoExiste === -1) {
              produtosFiltrados.push(produto);
            }
          }
        });
      }
    }

    if (!Utils.isValueValid(produtosFiltrados)) {
      produtosFiltrados = produtos;
    }

    if (Utils.isValueValid(itensPrecoMarcados)) {
      let filtros = itensPrecoMarcados.map((item) => item.idFiltro);

      let produtosExcluir = [];

      if (Utils.isValueValid(filtros)) {
        produtosFiltrados.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (!filtros.includes(filtroProduto.preco)) {
            produtosExcluir.push(produto);
          }
        });

        produtosExcluir.forEach((produto) => {
          produtosFiltrados.splice(produtosFiltrados.indexOf(produto), 1);
        });
      }
    }

    produtosFiltrados = this.sortByTerm(
      produtosFiltrados,
      this.state.textoPesquisa
    );

    this.setState({
      produtosFiltrados,
    });
  }

  getFiltrosMapeados() {
    let resultadoPesquisa = this.state.resultadoPesquisa;
    const { filtros } = resultadoPesquisa;

    console.log(resultadoPesquisa)

    let templates = [];

    let filtroDepartamento = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "DEPARTAMENTO"
    )[0];
    let filtroSecao = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "SECAO"
    )[0];
    let filtroPreco = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "PRECO"
    )[0];
    let filtroMarca = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "MARCA"
    )[0];
    let filtroPersonalizado = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "PERSONALIZADO"
    )[0];

    if (Utils.isValueValid(filtroDepartamento)) {
      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label
            pointer
            value="Departamentos"
            family="SemiBold"
            style={styles.title}
            handlerClick={() => {
              this.setState({
                mostrarDepart: !this.state.mostrarDepart
              })
            }}
          />
          <I
            pointer
            icon={this.state.mostrarDepart ? Type.ICON.ARROW_CHEV_UP : Type.ICON.ARROW_CHEV_DOWN}
            style={{
              color: Color.ECOMMERCE.CINZA,
              fontSize: 14,
              position: "absolute",
              left: "256px",
              marginTop: "25px",
            }}
            handlerClick={() => {
              this.setState({
                mostrarDepart: !this.state.mostrarDepart
              })
            }}
          />
          <Div style={{ display: this.state.mostrarDepart ? "block" : "none" }}>
            {Utils.sort(filtroDepartamento.itens, "descricao").map(
              (item, index) => {
                if (this.state.vejaMaisDepart && index >= 5) {
                  return
                } else {
                  return (
                    <Checkbox
                      value={item.idFiltro}
                      label={`${item.descricao} (${item.quantidadeEncontrada})`}
                      key={index}
                      danger
                      handlerChange={({ checked }) => {
                        item.active = checked;
                        this.setState({ resultadoPesquisa });
                        this.aplicarFiltros();
                      }}
                      checked={item.active}
                    />
                  );
                }
              }
            )}
          </Div>
          <If and value1={this.state.vejaMaisDepart && filtroDepartamento.itens.length >= 6 }>
            <Div
              style={styles.verMais}
              handlerClick={() => {
                this.setState({
                  vejaMaisDepart: false
                })
              }}
            >
              <Label pointer value="Ver Todos" />
            </Div>
          </If>
        </Div>
      );
    }

    if (Utils.isValueValid(filtroSecao) && !this.state.destaque) {
      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label
            pointer
            value="Categorias"
            family="SemiBold"
            style={styles.title}
            handlerClick={() => {
              this.setState({
                mostrarCategoria: !this.state.mostrarCategoria
              })
            }}
          />
          <I
            pointer
            icon={this.state.mostrarCategoria ? Type.ICON.ARROW_CHEV_UP : Type.ICON.ARROW_CHEV_DOWN}
            style={{
              color: Color.ECOMMERCE.CINZA,
              fontSize: 14,
              position: "absolute",
              left: "256px",
              marginTop: "25px",
            }}
            handlerClick={() => {
              this.setState({
                mostrarCategoria: !this.state.mostrarCategoria
              })
            }}
          />
          <Div style={{ display: this.state.mostrarCategoria ? "block" : "none" }}>
            {Utils.sort(filtroSecao.itens, "descricao").map((item, index) => {
              if (this.state.vejaMaisCategoria && index >= 5) {
                return
              } else {
                return (
                  <Checkbox
                    danger
                    label={`${item.descricao} (${item.quantidadeEncontrada}/${item.quantidadeTotal})`}
                    key={index}
                    handlerChange={({ checked }) => {
                      item.active = checked;
                      this.setState({ filtros, pagina: 1 }, () => {
                        if (
                          item.quantidadeEncontrada === item.quantidadeTotal &&
                          checked
                        ) {
                          this.aplicarFiltrosNaTela();
                        } else {
                          this.listarProdutos();
                        }
                      });
                    }}
                    checked={item.active}
                  />
                );
              }
            })}
          </Div>
          <If and value1={this.state.vejaMaisCategoria && filtroSecao.itens.length >= 6}>
            <Div
              style={styles.verMais}
              handlerClick={() => {
                this.setState({
                  vejaMaisCategoria: false
                })
              }}
            >
              <Label pointer value="Ver Todos" />
            </Div>
          </If>
        </Div>
      );
    }

    if (Utils.isValueValid(filtroMarca)) {
      let itensMarca = filtroMarca.itens;

      if (this.state.destaque) {
        let marcas = Array.from(this.state.produtos).map((p) => p.marca);
        itensMarca = Array.from(itensMarca).filter((i) =>
          marcas.includes(i.idFiltro)
        );
      }

      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label
            pointer
            value="Marca"
            family="SemiBold"
            style={styles.title}
            handlerClick={() => {
              this.setState({
                mostrarMarca: !this.state.mostrarMarca
              })
            }}
          />
          <I
            pointer
            icon={this.state.mostrarMarca ? Type.ICON.ARROW_CHEV_UP : Type.ICON.ARROW_CHEV_DOWN}
            style={{
              color: Color.ECOMMERCE.CINZA,
              fontSize: 14,
              position: "absolute",
              left: "256px",
              marginTop: "25px",
            }}
            handlerClick={() => {
              this.setState({
                mostrarMarca: !this.state.mostrarMarca
              })
            }}
          />
          <Div style={{ display: this.state.mostrarMarca ? "block" : "none" }}>
            {Utils.sort(itensMarca, "descricao").map((item, index) => {
              if (this.state.vejaMaisMarca && index >= 5) {
                return
              } else {
                return (
                  <Checkbox
                    danger
                    label={`${item.descricao} (${item.quantidadeEncontrada}/${item.quantidadeTotal})`}
                    key={index}
                    handlerChange={({ checked }) => {
                      item.active = checked;
                      this.setState({ filtros, pagina: 1 }, () => {
                        if (
                          item.quantidadeEncontrada === item.quantidadeTotal &&
                          checked
                        ) {
                          this.aplicarFiltrosNaTela();
                        } else {
                          this.listarProdutos();
                        }
                      });
                    }}
                    checked={item.active}
                  />
                );
              }
            })}
          </Div>
          <If and value1={this.state.vejaMaisMarca && filtroMarca.itens.length >= 6}>
            <Div
              style={styles.verMais}
              handlerClick={() => {
                this.setState({
                  vejaMaisMarca: false
                })
              }}
            >
              <Label pointer value="Ver Todos" />
            </Div>
          </If>
        </Div>
      );
    }

    if (Utils.isValueValid(filtroPersonalizado)) {
      let itensPers = filtroPersonalizado.itens;

      if (this.state.destaque) {
        let personalizado = Array.from(this.state.produtos).map((p) => p.personalizados);
        itensPers = Array.from(itensPers).filter((i) =>
          personalizado.includes(i.idFiltro)
        );
      }


      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label
            pointer
            value={filtroPersonalizado.descricao}
            family="SemiBold"
            style={styles.title}
            handlerClick={() => {
              this.setState({
                mostrarPers: !this.state.mostrarPers
              })
            }}
          />
          <I
            pointer
            icon={this.state.mostrarPers ? Type.ICON.ARROW_CHEV_UP : Type.ICON.ARROW_CHEV_DOWN}
            style={{
              color: Color.ECOMMERCE.CINZA,
              fontSize: 14,
              position: "absolute",
              left: "256px",
              marginTop: "25px",
            }}
            handlerClick={() => {
              this.setState({
                mostrarPers: !this.state.mostrarPers
              })
            }}
          />
          <Div style={{ display: this.state.mostrarPers ? "block" : "none" }}>
            {Utils.sort(itensPers, "descricao").map((item, index) => {
              if (this.state.vejaMaisPers && index >= 5) {
                return
              } else {
                return (
                  <Checkbox
                    title={item.descricao}
                    danger
                    label={
                      `${item.descricao.substring(0, 26)}${item.descricao.length > 26 ? "..." : ""} (${item.quantidadeEncontrada}/${item.quantidadeTotal})`
                    }
                    key={index}
                    handlerChange={({ checked }) => {
                      item.active = checked;
                      this.setState({ filtros, pagina: 1 }, () => {
                        if (
                          item.quantidadeEncontrada === item.quantidadeTotal &&
                          checked
                        ) {
                          this.aplicarFiltrosNaTela();
                        } else {
                          this.listarProdutos();
                        }
                      });
                    }}
                    checked={item.active}
                  />
                );
              }
            })}
          </Div>
          <If and value1={this.state.vejaMaisPers && filtroPersonalizado.itens.length >= 6}>
            <Div
              style={styles.verMais}
              handlerClick={() => {
                this.setState({
                  vejaMaisPers: false
                })
              }}
            >
              <Label pointer value="Ver Todos" />
            </Div>
          </If>
        </Div>
      );
    }

    return templates;
  }

  getProdutosPorDepartamento = () => {
    let itensParaRenderizar = [];

    for (const item of this.state.produtosFiltrados) {
      let departamento = item[0];
      let produtos = Utils.sort(item[1], "descricao");

      let produtosSeparados = produtos;

      itensParaRenderizar.push({ produtos: produtosSeparados, departamento });

      itensParaRenderizar = Utils.sortReverse(itensParaRenderizar, "tamanho");
    }

    return itensParaRenderizar;
  };

  visualizarProduto = (produto) => {
    ModalNotification.render(
      "Informações do produto",
      <ProdutoDetalhe
        produto={produto}
        produtosSimilares={this.state.produtosSimilares}
        handlerAddItem={(produto, atualizarProdutoDetalhe) => {
          this.adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe);
          toast.info("Produto adicionado no carrinho!", {
            position: "top-right",
          });
        }}
        history={this.props.history}
        empresa={this.state.empresa}
        listasDeCompraCliente={this.props.listasDeCompra}
      />
    );
  };



  renderProdutos() {
    return (
      <Div inline="start">
        {Array.from(this.state.produtosFiltrados).map((prod, index) => {
          return (
            <Div
              className="d-flex justify-content-start align-items-start align-self-start"
              marginTop="2"
              key={index}
            >
              <ItemProduto
                key={index}
                history={this.props.history}
                image={prod.urlImagemPrincipalThumbnail}
                descricao={prod.descricao}
                quantidade={prod.quantidade}
                disponivel={prod.disponivel}
                proibidaVenda={prod.proibidaVenda}
                produto={prod}
                idProdutoWeb={prod.idProdutoWeb}
                handlerAddItemCarrinho={(adicionar, quantidade, callback) => {
                  this.adicionarItemNoCarrinho(prod, quantidade, adicionar).then(() => {
                    if (callback) {
                      callback(prod)
                    }
                  });
                }}
                empresa={this.state.empresa}
              />
            </Div>
          );
        })}
      </Div>
    );
  }

  async adicionarItemNoCarrinho(produto, quantidade, adicionar) {
    let qdt = quantidade || 0

    if (adicionar) {
      qdt = quantidade ? quantidade + 1 : 1
    } else {
      if (produto.quantidade >= 1) {
        qdt = quantidade - 1
      }
    }

    let { carrinho, produtos } = this.props;
    let prod = ProdutoUtils.calcularValorProduto(produto, qdt);

    CarrinhoUtils.adicionarItemNoCarrinho(
      prod,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      null,
      this.props.parametros
    );
  }

  consultarParametrosPedido() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    if (Utils.isValueValid(cliente)) {
      let enderecoPrincipal = Array.from(cliente.enderecos).filter(
        (e) => e.principal
      )[0];

      if (Utils.isValueValid(cliente)) {
        Http.get(
          `pedido/parametros/cliente/${cliente.idCliente}/endereco/entrega/${enderecoPrincipal.idClienteEndereco}`
        ).then(({ status, data }) => {
          if (status) {
            this.setState({ parametros: data });
          }
        });
      }
    } else {
      console.log(
        "Cliente não autenticado, não foi possível consultar os parâmetros"
      );
    }
  }

  getMarginTop() {
    let margin = 30;
    if (window.innerWidth < 1000) margin = 100;

    return margin;
  }

  render() {
    const { textoPesquisa } = this.state;
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.state.empresa);
    return (
      <Div col="12" style={{ margin: this.getMarginTop() }}>
        <Div className="d-flex align-self-start justify-content-start">
          <Div marginLeft="3" marginRight="3">
            <Div inline="center">
              <I
                pointer
                icon={Type.ICON.FILTER}
                style={{
                  color: Color.EMPRESA.SECUNDARIA,
                  fontSize: 20,
                }}
              />
              <Label
                value="FILTROS"
                style={styles.labelFiltros}
                family="SemiBold"
              />
            </Div>
            <Div
              inline="center"
              pointer
              handlerClick={() => this.limparFiltros()}
              className="bg-light border"
              rounded
              padding="2"
            >
              <I
                icon={Type.ICON.CLOSEX}
                colorText={Color.NODE.DANGER}
                paddingBottom="2"
                pointer
                handlerClick={() => this.limparFiltros()}
              />
              <Label
                value="Limpar filtros selecionados"
                family="Light"
                marginLeft="2"
                pointer
                handlerClick={() => this.limparFiltros()}
              />
            </Div>
            {this.getFiltrosMapeados().map((item, index) => (
              <Div key={index}>{item}</Div>
            ))}
          </Div>
          <Div marginLeft="5" style={{ marginTop: "60px" }} responsive="9">
            <Div inline="between">
              <Label
                value={textoPesquisa !== "" ? `Resultados da busca por: "${textoPesquisa}"` : ""}
                style={styles.labelResultado}
                family="SemiBold"
              />

            </Div>


            {this.renderProdutos()}
          </Div>
        </Div>
        <ContatoWhatsapp empresa={this.state.empresa} />
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.listasDeCompraState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ atualizarCarrinho, setProdutos }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProdutosPesquisa);
