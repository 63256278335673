import React, { Component, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Image, Div, I, Row, Label, InputSearchLojaInstantanea } from "../../components";
import { Color, Http, Type, Utils } from "../../utilities";
import { atualizarCarrinho, setProdutos, } from "../../redux/actions";
import Zindex from "../utils/Zindex";
import Sidebar from "react-sidebar";
import Carrinho from "../carrinho/Carrinho";
import CarrinhoUtils from "../utils/CarrinhoUtils";
import { toast } from "react-toastify";


const ID_PESQUISA = Utils.uuidString();

const styles = {
  menuCarrinho: {
    borderRadius: 5,
    marginTop: 0,
    marginRight: 15,
    color: "white",
    padding: 10,
    width: "40px",
  },
  labelValorTotalCarrinho: {
    color: Color.EMPRESA.TERCIARIA,
  },
  labelQuantidadeTotalItens: {
    position: "absolute",
    transform: `translate(-10px, 22px)`,
    width: "26px",
    backgroundColor: Color.EMPRESA.TERCIARIA,
    color: "white",
    borderRadius: "50%",
    padding: 5,
    fontSize: 10,
    textAlign: "center",
  },
};

function IconeCarrinho(props) {
  const [quantidade, setQuantidade] = useState("0")
  const carrinho = useSelector(state => state)

  useEffect(() => {
    let itens = carrinho.carrinhoState.carrinho.itens.length || "0"
    setQuantidade(itens)
  }, [carrinho])

  return (
    <Div
      className="d-flex flex-column align-items-end"
      style={{ width: 88 }}
      handlerClick={() => {
        props.onSetSidebarOpen(true);
      }}
    >
      <Div>
        <I
          icon={Type.ICON.SALE_BASKET}
          style={{
            ...styles.menuCarrinho,
            background: Color.EMPRESA.PRIMARIA,
          }}
          pointer
        />
      </Div>
      <Label
        value={quantidade || "0"}
        style={styles.labelQuantidadeTotalItens}
        family="Bold"
        pointer
      />
      {/*<Label
          value={"10 produtos"}
          style={styles.labelValorTotalCarrinho}
          family="Bold"
          margin="2"
                /> */}
    </Div>
  )
}


class HeaderLojaWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anuncios: [],
      sidebarOpen: false,
      quantidadeItens: 0,
      resultadoPesquisa: [],
      textoPesquisa: ""
    };

  }

  UNSAFE_componentWillReceiveProps() {
    const { carrinho } = this.props

    this.setState({
      quantidadeItens: carrinho.itens.length || 0
    })
  }

  adicionarItemNoCarrinho(produto) {
    let { carrinho, produtos } = this.props;
    CarrinhoUtils.adicionarItemNoCarrinho(
      produto,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      setProdutos,
      null,
      this.state.parametros
    );
  }

  removerItemCarrinho(produto) {
    let { carrinho, produtos } = this.props;

    produto.quantidade = 0;

    CarrinhoUtils.adicionarItemNoCarrinho(
      produto,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      setProdutos,
      null,
      this.state.parametros
    );
  }


  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open }, () => {
      if (open) {
        Utils.toggleScrollBody("hidden");
      } else {
        Utils.toggleScrollBody("auto");
      }
    });
  }

  getPropsSideBarMenuLeft = () => {
    return {
      sidebar: this.renderTemplateMenuLeft(),
      open: this.state.sidebarOpen,
      onSetOpen: open => this.setState({ sidebarOpen: open }, () => {
        if (open) {
          Utils.toggleScrollBody("hidden");
        } else {
          Utils.toggleScrollBody("auto");
        }
      }),
      pullRight: true,
      styles: {
        sidebar: {
          background: "white",
          width: Utils.isViewMobile() ? "80%" : "25%",
          height: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: 1011,
        },
      },
    };
  };


  renderTemplateMenuLeft = () => {

    return (
      <Carrinho
        {...this.props}
        handlerAddItem={(produto) => this.adicionarItemNoCarrinho(produto)}
        handlerRemoveItem={(produto) => this.removerItemCarrinho(produto)}
        handlerFecharMenu={() => this.onSetSidebarOpen(false)}
      />

    );
  };

  fecharMenuBusca(idBtnFecharMenu) {
    let div = document.getElementById(this.props.idPesquisa);
    if (Utils.isValueValid(div) && div.classList.contains("show")) {
      div.classList.toggle("collapsed");
      document.getElementById(idBtnFecharMenu).click();
    }
  }

  realizarPesquisa(texto, idBtnFecharMenu) {
    Http.get(`/busca/${texto}`)
      .then(({ status, data }) => {
        if (status) {
          this.setState(
            {
              resultadoPesquisa: data,
            },
            () => {
              if (data.sucessoPesquisa) {
                this.props.history.push("/resultadopesquisa", {
                  resultadoPesquisa: data,
                  textoPesquisa: texto,
                  menus: this.state.menus,
                  empresa: this.props.empresa,
                });
                this.fecharMenuBusca(idBtnFecharMenu);
              } else {
                toast.error(`Os produtos não foram encontrados para o termo pesquisado: "${texto}"`)
              }

              this.setState({ textoPesquisa: "" });
            }
          );
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  render() {

    const { empresa } = this.props;
    let parametros = Utils.isValueValid(empresa) ? empresa.parametros : {};

    return (
      <Sidebar  {...this.getPropsSideBarMenuLeft()} className="scrollhost">
        <Div
          style={{
            height: 100,
            padding: "20px 20px 10px",
            position: "fixed",
            top: 0,
            zIndex: 1001,
            background: "#FFF"
          }}
          col="12"
          className="border-bottom"
        >
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Div col="3" marginRight="3">
              <Image
                src={`${parametros.urlFoto}`}
                style={{ zIndex: Zindex.DEFAULT }}
                className="imagemlogoinput"
                handlerClick={() => this.props.history.replace("/")}
                pointer
              />
            </Div>
            <Div col="5" style={{ paddingTop: "5px", }}>
              <Div
                style={{ zIndex: 1 }}
                className="input-group container-input-search-lojainstantanea"
              >
                {/* <If and value1={window.innerWidth > 600}>
                  <I
                    pointer
                    handlerClick={(e) => {
                      // if (Utils.isValueValid(this.state.texto)) {
                      //   this.adicionarBusca();
                      //   this.props.handlerChangePesquisa(
                      //     this.state.texto,
                      //     this.props.id
                      //   );
                      // }
                    }}
                    icon={Type.ICON.SEARCH}
                    width="25px"
                    style={{
                      position: "absolute",
                      color: parametros.corPrimaria,
                      left: 5,
                      bottom: 5,
                      zIndex: Zindex.MIL_E_DOIS,
                      transform: `rotate(90deg)`,
                    }}
                  />
                </If> */}

                <Div
                  target={`#${this.props.idPesquisa}`}
                  toggle="collapse"
                  id={this.props.id}
                >

                  <InputSearchLojaInstantanea
                    idPesquisa={this.props.idPesquisa}
                    id={ID_PESQUISA}
                    handlerChangePesquisa={(texto, idBtnFecharMenu) => {
                      this.realizarPesquisa(texto, idBtnFecharMenu);
                    }}
                    handlerFecharPesquisa={(idBtnFecharMenu) =>
                      this.fecharMenuBusca(idBtnFecharMenu)
                    }
                    empresa={this.props.empresa}
                    textoPesquisa={this.state.textoPesquisa}
                    handlerAbrirPesquisa={this.props.handlerAbrirPesquisa}
                  />
                </Div>
              </Div>
            </Div>

            <Div
              col="2"
              style={{
                display: "flex",
                justifyContent: "end"
              }}
            >
              <IconeCarrinho onSetSidebarOpen={this.onSetSidebarOpen} />
            </Div>
          </Row>
        </Div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.empresaState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ atualizarCarrinho, setProdutos }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLojaWeb);