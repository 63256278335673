import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ButtonIconOutLine,
  Div,
  If,
  Label,
  ModalNotification,
  Notification,
  Row,
  Table,
  TextArea,
  Title,
} from "../../components";
import OrcamentoService from "../../service/OrcamentoService";
import { Color, DateUtils, Mask, Pattern, Type, Utils } from "../../utilities";
import MapaSeparacaoPDF from "./MapaSeparacaoPDF";

const styles = {
  boxInfo: {
    marginBottom: "50px",
    padding: "10px 0 20px 0",
    backgroundColor: "#FFF",
    alignItems: "end"
  },
  labelCinza: {
    color: "#878787",
    fontSize: "16px",
    marginBottom: "35px",
  },
  labelInfo: {
    padding: "10px",
    backgroundColor: "#878787",
    color: "#FFF",
    fontSize: "12px",
    margin: "0px 15px",
    borderRadius: "3px"
  },
  valueInfo: {
    margin: "5px"
  },
  imageIcon: {
    marginBottom: 15,
    marginLeft: 5,
    marginRight: 5,
  },
  checkedBox: {
    margin: "8px 5px"
  },
  colorInput: {
    color: "#c5c5c5",
  }
}

class DetalhesPedido extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pedido: {},
      motivoCancelamento: "",
      loading: false
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.idPedido !== 0) {
      this.getOrcamento(this.props.idPedido)
    }

    if (this.props.idPedido === 0) {
      this.setState({
        pedido: {}
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idPedido !== this.props.idPedido) {

      if (this.props.idPedido !== 0) {
        this.getOrcamento(this.props.idPedido)
      }

      if (this.props.idPedido === 0) {
        this.setState({
          pedido: {}
        })
      }
    }
  }

  getOrcamento(idOrcamento) {
    OrcamentoService.getOrcamento(idOrcamento).then(data => {
      if (data) {
        this.setState({
          loading: false,
          pedido: data
        })
      }
    })
  }

  labelButtonPedido() {
    let label = "Separação"

    switch (this.props.posicao) {
      case Type.ORCAMENTO.ABERTO:
        label = "Iniciar Separação"
        break;

      case Type.ORCAMENTO.SEPARACAO:
        label = "Finalizar"
        break;

      default:
        label = "Iniciar Separação"
        break;
    }


    return label
  }

  modalCancelarPedido() {

    ModalNotification.renderMessage({
      title: "Cancelar Orçamento ",
      body: (
        <Div col="12">
          <Label style={{ ...styles.labelCinza, marginBottom: "17px" }} value="Deseja cancelar o orçamento?" />
          <TextArea
            paddingLeft="0"
            paddingRight="0"
            responsive="12"
            placeHolder="Preencha um motivo para o cancelamento"
            style={{ minHeight: "124px" }}
            handlerChange={(e) => {
              this.setState({
                motivoCancelamento: e.target.value
              })
            }}
          />
        </Div>
      ),
      handlerConfirm: () => this.confirmarCancelamento(),
      handlerCancel: () => { },
      labelButtonCancel: "Cancelar",
    });
  }

  confirmarCancelamento() {

    let obj = {
      idlancamentosOrcamento: this.props.idPedido,
      motivoCancelamento: this.state.motivoCancelamento
    }

    if (this.props.idPedido !== 0) {
      OrcamentoService.cancelarOrcamento(obj).then(data => {
        if (data) {
          Notification.success(data.mensagem);
          this.props.setAbasStatus("3")
        } else {
          Notification.danger("Falha ao atualizar cancelar orçamento");
        }
      })
    }
  }

  confirmarPedido() {
    let posicao = this.props.posicao

    let obj = {
      idlancamentosOrcamento: this.props.idPedido
    }

    if (posicao === Type.ORCAMENTO.ABERTO) {
      OrcamentoService.atualizarEmSeparacao(obj).then(data => {
        if (data) {
          Notification.success(data.mensagem);
          this.props.setAbasStatus("3")
        } else {
          Notification.danger("Falha ao atualizar situação do orçamento");
        }
      })
    }

    if (posicao === Type.ORCAMENTO.SEPARACAO) {
      OrcamentoService.atualizarConcluido(obj).then(data => {
        if (data) {
          Notification.success(data.mensagem);
          this.props.setAbasStatus("3")
        } else {
          Notification.danger("Falha ao atualizar situação do orçamento");
        }
      })
    }
  }

  render() {
    const { pedido } = this.state

    let showButton = this.props.posicao === Type.ORCAMENTO.ABERTO || this.props.posicao === Type.ORCAMENTO.SEPARACAO && Utils.isNotObjectEmpty(pedido)

    return (
      <Div
        margin="0"
      >
        <Row col="12" shadow className="border-left-primary" style={styles.boxInfo}>
          <If and value1={showButton && this.props.idPedido !== 0}>
            <Row col="12" style={{ zIndex: 999, alignItems: "end" }}>
              <ButtonIconOutLine
                value={this.labelButtonPedido()}
                style={{ maxWidth: "166px", height: "40px", marginTop: "27px" }}
                btn={Color.NODE.PRIMARY}
                handlerClick={() => this.confirmarPedido()}
                col="2"
                className="custom-btn"
              />

              <ButtonIconOutLine
                value="Cancelar"
                style={{ maxWidth: "166px", height: "40px", marginTop: "27px" }}
                btn={Color.NODE.DANGER}
                handlerClick={() => this.modalCancelarPedido()}
                col="2"
                className="custom-btn"
              />

              <Row
                style={{
                  //margin: "15px 5px 0px 11px",
                }}
              >
                {
                  this.props.posicao === Type.ORCAMENTO.ABERTO &&
                  <PDFDownloadLink
                    document={
                      <MapaSeparacaoPDF pedido={pedido} empresa={this.props.empresa} />
                    }
                    filename="mapa_separacao"
                  >
                    {
                      ({ loading }) => loading ?
                        <ButtonIconOutLine responsive4
                          value="Carregando PDF..."
                          float="right"
                          style={{height: "40px"}}
                          icon={Type.ICON.PDF}
                          btn={Color.NODE.DANGER}
                        // handlerClick={() => ReactPDF.renderToFile(<ProdutosPDF />)}
                        />
                        :
                        <ButtonIconOutLine responsive4
                          value="Imprimir Mapa de Separação"
                          float="right"
                          style={{height: "40px"}}
                          icon={Type.ICON.PDF}
                          btn={Color.NODE.WARNING}
                        // handlerClick={() => ReactPDF.renderToFile(<ProdutosPDF />)}
                        />
                    }
                  </PDFDownloadLink>

                }
              </Row>
            </Row>
          </If>

          <Title
            value="Informações do Orçamento"
            type="h6"
            className="label-valor-total-carrinho"
            style={{
              ...styles.labelCinza,
              marginTop: showButton && this.props.idPedido !== 0 ? "5px" : "0px"
            }}
            family="Bold"
            col="12"
          />


          <If and value1={Utils.isNotObjectEmpty(pedido) && this.props.idPedido !== 0}>
            <Row
              col="12"
              style={{
                margin: "5px 5px 20px 11px",
                fontWeight: "bold",
                color: Color.ECOMMERCE.CINZA

              }}
            >
              <Label
                value={`Pedido: ${pedido && pedido.referencia}`}
                style={styles.valueInfo}
              />

              <Label
                value={`Feito às: ${DateUtils.getDateFormat(pedido && pedido.dataLancamento, Pattern.DATE.DD_MM_YYYY_HH_MM)}`}
                style={styles.valueInfo}
              />
            </Row>
            <Row col="12">
              <Label family="Bold" value="CLIENTE" style={styles.labelInfo} />
              <Label value={pedido && pedido.nomeCliente} style={styles.valueInfo} />
              <Label family="Bold" value="EMAIL" style={styles.labelInfo} />
              <Label value={pedido && pedido.emailCliente} style={styles.valueInfo} />
            </Row>

            <Row col="12" marginTop="4">
              <Label family="Bold" value="CPF/CNPJ" style={styles.labelInfo} />
              <Label value={pedido && pedido.cpfCnpj} style={styles.valueInfo} />
              <Label family="Bold" value="TEL" style={styles.labelInfo} />
              <Label value={Mask.maskValuePhone(pedido && pedido.telefoneCliente)} style={styles.valueInfo} />
            </Row>

            <Row
              col="12"
              style={{
                margin: "15px 5px 10px 11px",
                fontWeight: "bold",
                fontSize: "14px",
                color: Color.ECOMMERCE.CINZA

              }}
            >
              <Label
                value={`Observações: ${pedido && pedido.obs}`}
                style={styles.valueInfo}
              />
            </Row>



            <Div
              padding="2"
              col="12"
              style={{
                maxHeight: "44vh",
                overflow: "scroll",
                paddingTop: "0px !important"
              }}
            >
              <Table
                columns={[
                  { value: "Cod. de Barras", align: "center", },
                  { value: "Descrição", align: "left" },
                  { value: "Quantidade", align: "center", width: "30px" },
                ]}
                fields={[
                  {
                    value: "codigoBarras",
                    type: Type.DADO.STRING,
                    align: "center",
                    col: 2,
                  },
                  {
                    value: "descricao",
                    type: Type.DADO.STRING,
                    align: "center",
                    col: 2,
                  },
                  {
                    value: "quantidade",
                    type: Type.DADO.INTEGER,
                    align: "center",
                    width: "30px",
                    col: 3,
                  },
                ]}
                bordered
                orientation="center"
                data={pedido ? pedido.itens : []}
                error="Nenhum Produto encontrado"
              ></Table>
            </Div>
          </If>

          <If and value1={!Utils.isNotObjectEmpty(pedido) && this.props.idPedido === 0}>
            <Title
              value="Não tem Informações do Orçamentos para serem carregadas!"
              type="h6"
              className="label-valor-total-carrinho"
              style={{
                color: Color.CUSTOM_COLORS.DANGER,
                fontSize: "16px",
                marginBottom: "35px",
              }}
              family="Bold"
              col="12"
            />
          </If>

        </Row>
      </Div>
    )

  }


}

const mapStateToProps = (store) => ({
  ...store.empresaState,
});

export default connect(mapStateToProps)(DetalhesPedido);