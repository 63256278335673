import React, { Component } from "react";
import {
  Div,
  Title,
  Label,
  CheckboxToggle,
  I,
  Notification,
  If,
  Image,
  NavbarTab,
  Row,
  Select,
  ButtonIconOutLine,
  SelectValue,
  ModalNotification,
  InputDefault,
} from "../../components";
import { Color, Http, Utils, Mask, Type, DateUtils } from "../../utilities";
import "./produtosDetalhe.css";
//import ProdutoCataloService from "../../../service/ProdutoCataloService";
import PrecoEstoque from "./PrecoEstoque";
import DepartamentoProdutoDetalhe from "./DepartamentoProdutoDetalhe";
import FiltrosAdicionar from "./FiltrosAdicionar";
import GaleriaFotos from "./GaleriaFotos";
import ProdutoCataloService from "../../service/ProdutoCatalogoService";
import InformacoesAdicionais from "./InformacoesAdicionais";

const styles = {
  labelTitulo: {
    color: "#9e9e9e",
    width: "100%",
    fontSize: "16px",
  },
  labelInfo: {
    fontSize: "16px",
    color: "#9e9e9e",
  }
}

class ProdutoDetalhe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemActive: {
        target: "INFO",
        value: "INFO",
        active: true,
      },
      editarSituacao: false,
      situacaoOptions: [
        new SelectValue(0, "Selecione um opção", true),
        new SelectValue(Type.CATALOGO.BLOQUEADOS, "BLOQUEADO", false),
        new SelectValue(Type.CATALOGO.DISPONIVEL, "PUBLICADO", false),
      ],
      situacaoSelect: Type.CATALOGO.DISPONIVEL,
      produto: {
        idEmpresa: "",
        idFilial: "",
        idProdutoWeb: "",
        codigoProduto: "1",
        codigoBarras: "1",
        descricao: "",
        unidade: "UN",
        marca: "",
        tipoProduto: "NORMAL",
        codigoNcm: "",
        codigoFabricante: null,
        fabricante: null,
        precoUnitario: 1,
        precoAtacado: 0,
        precoVenda: 1,
        precoVendaAtacado: 0,
        fatorConversao: 1,
        pesoBruto: 1,
        pesoLiquido: 1,
        ativo: true,
        dataAtualizacao: "",
        custoReal: 0.7,
        custoContatil: 0.7,
        custoFinanceiro: 0.7,
        valorIcmsEfetivo: 0,
        aliquotaIcmsEfetivo: 0,
        aliquotaRedBaseEfet: 0,
        valorBaseFcpstRetido: 0,
        aliquotaFcpstRetido: 0,
        valorFcpstRetido: 0,
        valorBaseBcr: 0,
        aliquotast: 0,
        codigoFornecedor: "",
        quantidadeMinimaAtacado: 0,
        nomeFornecedor: "",
        situacao: "",
        quantidadeEstoqueAtual: 0,
        urlFotoErpTerceiro: null,
        informacoesTecnicas: null,
        possuiQuantidadeFracionada: false,
        dataAtualizacaoPrecificacao: "",
        descricaoDepartamentoSecao: "",
        descricaoDepartamentoSecaoPersonalizado: null,
        descricaoDepartamentoSecaoProduto: null,
        urlFoto: "",
        produtoPersonalizado: null,
        imagens: [],
        filtros: [],
      },
      loading: false,
      ...this.props.history.location.state,
    };
  }

  UNSAFE_componentWillMount() {
    this.consultarProduto();
  }

  componentDidUpdate(prevState) {
    if (this.state.loading) {
      this.consultarProduto()
    }
  }

  loadingComponente = () => {
    this.setState({
      loading: true,
    })
  }

  consultarProduto() {

    ProdutoCataloService.getProdutos(this.state.produto.idProdutoWeb).then(({ status, data }) => {
      if (status) {
        this.setState({ produto: data, situacaoSelect: data.situacao, loading: false });
      }
    })
      .catch((error) => console.log(error));
  }

  exportarProdutoWeb(produto) {
    let produtoWeb = this.getProdutoWeb(produto);
    this.exportar(produtoWeb);
  }

  exportar(produtoWeb) {
    Http.post("/produtoprecificacaoweb/produto/exportar", produtoWeb)
      .then(({ status, data }) => {
        if (status) {
          Notification.success(data.message);
          this.setState({
            produto: {
              ...this.state.produto,
              situacao: "DISPONIVEL",
              produtoWeb: true,
            },
          });

        } else {
          Notification.danger("Falha ao exportar produto");
        }
      })
      .catch((error) => console.log(error));
  }

  atualizarStatusProdutoWeb(produto) {
    let produtoWeb = this.getProdutoWebAtualizarStatus(produto);
    this.atualizarStatus(produtoWeb);
  }

  atualizarStatus(produtoWeb) {
    produtoWeb.produtoWeb = false;
    Http.post("/produtoprecificacaoweb/produto/status/alterar", produtoWeb)
      .then(({ status, data }) => {
        if (status) {
          Notification.success(data.message);
          //Utils.refreshPage(3000);
          // this.setState({
          //   produto: {
          //     ...this.state.produto,
          //     situacao: "DISPONIVEL",
          //     produtoWeb: false,
          //   },
          // });
          this.consultarProduto()
        } else {
          Notification.danger("Falha ao atualizar situação produto web");
        }
      })
      .catch((error) => console.log(error));
  }

  atualizarControleEstoqueProdutoWeb(produto) {
    let produtoWeb = this.getProdutoWebAtualizarStatus(produto);
    this.atualizarControleEstoque(produtoWeb);
  }

  atualizarControleEstoque(produtoWeb) {
    Http.put("/produtoprecificacaoweb/produto/estoque/controlar", produtoWeb)
      .then(({ status, data }) => {
        if (status) {
          Notification.success(data.message);
          //Utils.refreshPage(3000);
        } else {
          Notification.danger("Falha ao atualizar situação produto web");
        }
      })
      .catch((error) => console.log(error));
  }

  getProdutoWebAtualizarStatus({
    idFilial,
    idProduto,
    descricao,
    situacao,
    idProdutoWeb,
    controlaEstoque,
  }) {
    return {
      idFilial,
      idProduto,
      descricao,
      origem: "MOBIOH",
      idProdutoWeb,
      controlaEstoque,
      situacao
    };
  }

  getProdutoWeb({
    idProduto,
    idEmpresa,
    idFilial,
    descricao,
    precoUnitario,
    quantidadeMinimaAtacado,
    controlaEstoque,
  }) {
    return {
      idProduto,
      idEmpresa,
      idFilial,
      descricao,
      precoUnitario,
      situacao: "DISPONIVEL",
      precoVenda: precoUnitario,
      precoVendaVigente: precoUnitario,
      quantidadeMinimaAtacado,
      origem: "MOBIOH",
      controlaEstoque,
    };
  }

  isProdutoValido(produto) {
    let produtoValido = true;
    produtoValido =
      Utils.isValueValid(produto.precoUnitario) &&
      Number(produto.precoUnitario) > 0 &&
      produtoValido;
    if (!produtoValido) {
      Notification.danger("Produto não possui preço unitário");
      return produtoValido;
    }

    let produtoPossuiCusto =
      (Utils.isValueValid(produto.custoContatil) &&
        Number(produto.custoContatil) > 0) ||
      (Utils.isValueValid(produto.custoReal) && Number(produto.custoReal)) ||
      (Utils.isValueValid(produto.custoFinanceiro) &&
        Number(produto.custoFinanceiro));

    produtoValido = produtoPossuiCusto;

    if (!produtoValido) {
      Notification.danger("Produto não possui custo");
      return produtoValido;
    }

    return produtoValido;
  }

  getItens = () => {
    let item = this.state.itemActive;

    return [
      {
        target: "INFO",
        value: "GERAL",
        active: item.value === "INFO",
      },

      {
        target: "INFO_ADD",
        value: "INFORMAÇÕES ADICIONAIS",
        active: item.value === "INFO_ADD",
      },

      {
        target: "DEPARTAMENTO",
        value: "DEPARTAMENTO E SEÇÕES",
        active: item.value === "DEPARTAMENTO",
      },

      {
        target: "PRECO",
        value: "PREÇOS E ESTOQUES",
        active: item.value === "PRECO",
      },

      {
        target: "FOTOS",
        value: "FOTOS",
        active: item.value === "FOTOS",
      },

      {
        target: "FILTROS",
        value: "FILTROS",
        active: item.value === "FILTROS",
      },
    ];
  };

  validNavAbas(itemActive) {
    // itemActive.stopPropagation();
    // itemActive.preventDefault();
    let item = this.state.itemActive;
    let formValidar = this.validaFormProd();

    if (formValidar) {
      this.setState({ itemActive });
    }

    if (!formValidar) {
      item.value = "INFO";
      item.target = "INFO PRODUTO";
    }
  }

  colorSituacao() {
    let cor = Color.CUSTOM_COLORS.PRIMARY

    switch (this.state.situacaoSelect) {
      case Type.CATALOGO.DISPONIVEL:
        cor = Color.CUSTOM_COLORS.PRIMARY
        break;

      case Type.CATALOGO.BLOQUEADOS:
        cor = Color.CUSTOM_COLORS.DANGER
        break

      case Type.CATALOGO.PENDENTES:
        cor = Color.RGBA.LARANJA
        break

      default:
        cor = Color.CUSTOM_COLORS.PRIMARY
        break;
    }

    return cor
  }


  confirmarSituacao(obj) {
    ProdutoCataloService.alterarSituacao(obj).then(({ status, mensagem }) => {
      if (status) {
        Notification.success(mensagem)
        this.setState({
          editarSituacao: false,
        }, this.consultarProduto())
      } else {
        this.setState({
          situacaoSelect: Type.CATALOGO.DISPONIVEL
        })
        Notification.danger(mensagem)
      }
    })
  }

  mudarSituacao() {
    let obj = [
      {
        idProduto: this.state.produto.idProduto,
        situacao: this.state.situacaoSelect
      },
    ]

    ModalNotification.confirm(
      "Deseja Alterar a situação desse Produto? ",
      `Este produto será alterado para situação ${this.state.situacaoSelect}!`,
      () => this.confirmarSituacao(obj)
    );

  }

  validaFormProd() {
    var valida = true;

    // if (!this.state.tipo) {
    //   Notification.danger("Selecione um tipo de Pesquisa");
    //   return false;
    // }

    // if (this.state.descricao === "") {
    //   Notification.danger("Escreve uma descrição para pesquisa");
    //   return false;
    // }

    // if (!this.state.datavalidade) {
    //   Notification.danger("Selecione uma data de validade");
    //   return false;
    // }

    return valida;
  }

  render() {
    const { produto } = this.state;
    let { target } = this.state.itemActive;

    const produtoWebDisponivel = produto.produtoWeb && produto.situacao === "DISPONIVEL";

    return (

      <Div
        margin="3"
        padding="3"
        borderLeft={Color.NODE.PRIMARY}
        bg={Color.NODE.WHITE}
        shadow
      >
        <NavbarTab
          itens={this.getItens()}
          handlerClick={(itemActive) => {
            this.validNavAbas(itemActive);
          }}
        />

        <If and value1={target === "INFO"}>

          <Div inline="between">
            {produto.codigoBarras && (
              <Div inline="start">
                <Div>
                  <Image src={produto.urlImagemPrincipalThumbnail} className="imgProduct" />
                </Div>
                <Div column>
                  <Label value="Produto" style={styles.labelTitulo} />
                  <Title
                    bold
                    type="h4"
                    className="headerProducts"
                    value={produto.descricao}
                  />
                </Div>
              </Div>
            )}
            <Div column>
              <Label value="Cód. Produto" style={styles.labelTitulo} />
              <Title
                type="h5"
                className="headerProducts"
                value={produto.codigoProduto ? produto.codigoProduto : "N/A"}
              />
            </Div>

            <Div column>
              <Label value="Cód. Barras" style={styles.labelTitulo} />
              <Title
                type="h5"
                className="headerProducts"
                value={produto.codigoBarras ? produto.codigoBarras : "N/A"}
              />
            </Div>
            
          </Div>

          <Div marginBottom="4" marginTop="4" paddingBottom="4" shadow >
            <Div>
              <Div
                padding="1"
                inline="start"
                bg={Color.NODE.WHITE}
                colorText={Color.NODE.DEFAULT}
                className="titulo-precificacao"
              >
                <I icon={Type.ICON.INFO} marginLeft="2" marginBottom="2" />
                <Label bold value="Situação do Produto" rounded marginLeft="2" />
              </Div>

              <Row col="12">
                <Div col="3">
                  <InputDefault
                    //handlerChange={(e) => handlerProduto("descricao", e.target.value)}
                    //info="Descrição que será enviada para o ecommerce"
                    value={DateUtils.getDateFormat(produto.dataAtualizacao, "DD/MM/YYYY")}
                    label="Ult. Data Sincronização"
                    disabled
                  />
                </Div>

                <Div col="3">
                  <InputDefault
                    //handlerChange={(e) => handlerProduto("descricao", e.target.value)}
                    //info="Descrição que será enviada para o ecommerce"
                    value={produto.ativo ? "Ativo" : "Desativado"}
                    label="Situação ERP"
                    disabled
                  />
                </Div>

                <If and value1={!this.state.editarSituacao}>
                  <Div col="3">
                    <InputDefault
                      style={{
                        border: `1px solid ${this.colorSituacao()}`,
                        backgroundColor: "#FFF",
                        color: this.colorSituacao(),
                        fontWeight: "600",

                      }}
                      //handlerChange={(e) => handlerProduto("descricao", e.target.value)}
                      //info="Descrição que será enviada para o ecommerce"
                      value={this.state.situacaoSelect}
                      label="Situação Integração"
                      disabled
                    />
                    <I
                      icon={Type.ICON.EDIT}
                      style={{
                        display: "flex",
                        fontSize: "25px",
                        position: "absolute",
                        top: "36px",
                        right: "26px",
                        zIndex: "999",
                        cursor: "pointer",
                        color: this.colorSituacao()
                      }}
                      handlerClick={() => this.setState({ editarSituacao: true })}
                    />
                  </Div>
                </If>

                <If and value1={this.state.editarSituacao}>
                  <Row
                    col="6"
                    style={{
                      display: "flex",
                    }}
                  >
                    <Select
                      required
                      responsive="6"
                      label="Situação"
                      paddingLeft="0"
                      handlerChange={(e) => this.setState({ situacaoSelect: e.target.value })}
                      values={this.state.situacaoOptions}
                      valueSelected={this.state.situacaoSelect}
                    />

                    <ButtonIconOutLine
                      value="Alterar Situação"
                      style={{ maxWidth: "170px", height: "40px", marginTop: "27px" }}
                      btn={Color.NODE.PRIMARY}
                      handlerClick={() => this.mudarSituacao()}
                      col="2"
                      className="custom-btn"
                    />
                  </Row>
                </If>
              </Row>
            </Div>
          </Div>

          <Div responsive="12" marginTop="3" marginBottom="4" padding="0">
            <Div
              padding="1"
              inline="start"
              bg={Color.NODE.WHITE}
              colorText={Color.NODE.DEFAULT}
              className="titulo-precificacao"
            >
              <I icon={Type.ICON.LIST_ALT} marginLeft="2" marginBottom="2" />
              <Label bold value="Dados Adicionais" rounded marginLeft="2" />
            </Div>
            <Div
              marginTop="2"
              inline="between"
              shadow
              paddingTop="2"
              paddingLeft="4"
              paddingBottom="4"
              paddingRight="4"
            >
              <Div padding="1">
                <I icon={Type.ICON.LIST_ALT} sizeIcon="2" className="iconProduct" ></I>
                <Label
                  value={`Departamento | Seção: `}
                  className="texto-dados-adicionais texto-label"
                />
                <Label
                  value={`${produto.descricaoDepartamento} | ${produto.descricaoSecao}`}
                  className={`
                  texto-dados-adicionais 
                  ${Color.TEXT.SUCCESS} 
                `}
                  style={{ fontWeight: 'bold' }}
                />
              </Div>
              <Div padding="1">
                <I icon={Type.ICON.TAGS} sizeIcon="2" className="iconProduct" ></I>
                <Label
                  value="Marca:"
                  className="texto-dados-adicionais texto-label"
                />
                <Label
                  value={produto.marca}
                  className={`
                  texto-dados-adicionais 
                  ${Color.TEXT.SUCCESS} 
                `}
                  style={{ fontWeight: 'bold' }}
                />
              </Div>
              <Div padding="1">
                <I icon={Type.ICON.SHOPPING_BAG} sizeIcon="2" className="iconProduct" ></I>
                <Label
                  value="Fabricante: "
                  className="texto-dados-adicionais texto-label"
                />
                <Label
                  value={produto.fabricante ? produto.fabricante : "Sem Registro"}
                  className={`
                  texto-dados-adicionais 
                  ${Color.TEXT.SUCCESS} 
                `}
                  style={{ fontWeight: 'bold' }}
                />
              </Div>

              <Row col="12" marginTop="4" padding="0" inline="between">
                <Div col="1" padding="0" >
                  <InputDefault
                    //handlerChange={(e) => handlerProduto("descricao", e.target.value)}
                    //info="Descrição que será enviada para o ecommerce"
                    value={produto.unidade}
                    label="UNIDADE"
                    disabled
                  />
                </Div>

                <Div col="2" padding="0">
                  <InputDefault
                    //handlerChange={(e) => handlerProduto("descricao", e.target.value)}
                    //info="Descrição que será enviada para o ecommerce"
                    value={produto.tipoProduto}
                    label="Tipo"
                    disabled
                  />
                </Div>

                <Div col="3" padding="0">
                  <InputDefault
                    //handlerChange={(e) => handlerProduto("descricao", e.target.value)}
                    //info="Descrição que será enviada para o ecommerce"
                    value={produto.codigoNcm}
                    label="NCM"
                    disabled
                  />
                </Div>

                <Div col="3" padding="0">
                  <InputDefault
                    //handlerChange={(e) => handlerProduto("descricao", e.target.value)}
                    //info="Descrição que será enviada para o ecommerce"
                    value={produto.nomeFornecedor}
                    label="Fornecedor"
                    disabled
                  />
                </Div>
              </Row>
            </Div>
          </Div>


          <Div responsive="12" marginTop="3" marginBottom="4" padding="0">
            <Div
              padding="1"
              inline="start"
              bg={Color.NODE.WHITE}
              colorText={Color.NODE.DEFAULT}
              className="titulo-precificacao"
            >
              <I icon={Type.ICON.USD} marginLeft="2" marginBottom="2" />
              <Label bold value="Preço e Estoque" rounded marginLeft="2" />
            </Div>

            <Div
              marginTop="3"
              padding="3"
              //borderLeft={Color.NODE.DEFAULT}
              bg={Color.NODE.WHITE}
              shadow
            >
              <Div inline="between">
                <Div
                  padding="2"
                  column
                  responsive="2"
                >
                  <Title
                    value={Mask.convertNumberBr(produto.precoUnitario)}
                    type="h2"
                    className={Color.TEXT.SUCCESS + " precoTitulo"}
                  />
                  <Title value="Preço unitário" type="h6" className="textSubTitulo" />
                </Div>
                <Div
                  //bg={Color.NODE.SUCCESS}
                  //colorText={Color.NODE.WHITE}
                  padding="2"
                  column
                  responsive="2"
                >
                  <Title
                    value={Mask.convertNumberBr(produto.precoVenda)}
                    type="h2"
                    className={Color.TEXT.SUCCESS + " precoTitulo"}
                  />
                  <Title value="Preço Venda" type="h6" className="textSubTitulo" />
                </Div>
                <Div
                  //bg={Color.NODE.SUCCESS}
                  //colorText={Color.NODE.WHITE}
                  padding="2"
                  column
                  responsive="2"

                >
                  <Title
                    value={Mask.convertNumberBr(produto.precoAtacado)}
                    type="h2"
                    className={Color.TEXT.SUCCESS + " precoTitulo"}
                  />
                  <Title value="Preço Atacado" type="h6" className="textSubTitulo " />
                </Div>
                {/* <Div
                  //bg={Color.NODE.SUCCESS}
                  //colorText={Color.NODE.WHITE}
                  padding="2"
                  column
                  responsive="2"

                >
                  <Title
                    value={Mask.convertNumberBr(produto.custoFinanceiro)}
                    type="h2"
                    className={Color.TEXT.SUCCESS + " precoTitulo"}
                  />
                  <Title value="Custo Financeiro" type="h6" className="textSubTitulo" />
                </Div> */}

                <Div
                  //bg={Color.NODE.SUCCESS}
                  //colorText={Color.NODE.WHITE}
                  padding="2"
                  column
                  responsive="2"

                >
                  <Title
                    value={ `${produto.estoqueDisponivel}`}
                    type="h2"
                    className={Color.TEXT.SUCCESS + " precoTitulo"}
                  />
                  <Title value="Estoque" type="h6" className="textSubTitulo" />
                </Div>
              </Div>
            </Div>
          </Div>
        </If>

        <If and value1={target === "EXPORTAR"}>
          <Div marginTop="2" shadow className="border" padding="2" inline="start">
            <Div className="border-right" paddingRight="4">
              <Title type="h6" value="Exportar produto WEB" className="titulo-sessao" />

              <I
                icon={Type.ICON.INFO}
                colorText={Color.NODE.INFO}
                marginBottom="2"
              />
              <Label
                value="Ao ativar a exportação, este produto será disponibilizado para a WEB."
                marginLeft="2"
              />
            </Div>
            <Div inline="start" paddingTop="4">
              <Div marginLeft="4">
                <CheckboxToggle
                  labelLeft="Disponível"
                  checked={produtoWebDisponivel}
                  handlerChange={({ checked }) => {
                    produto.situacao = checked ? "DISPONIVEL" : "INDISPONIVEL";
                    if (checked && this.isProdutoValido(produto)) {
                      if (!produto.produtoWeb) {
                        this.exportarProdutoWeb(produto);
                      } else {
                        this.atualizarStatusProdutoWeb(produto);
                      }
                    } else {
                      this.atualizarStatusProdutoWeb(produto);
                    }
                  }}
                />
              </Div>
              <If and value1={produtoWebDisponivel}>
                <Div marginLeft="4">
                  <CheckboxToggle
                    labelLeft="Controlar estoque"
                    checked={produto.controlaEstoque}
                    handlerChange={({ checked }) => {
                      produto.controlaEstoque = checked;
                      this.setState(
                        {
                          produto: {
                            ...produto
                          },
                        },
                        () => this.atualizarControleEstoqueProdutoWeb(produto)
                      );
                    }}
                  />
                </Div>
              </If>
            </Div>
          </Div>

          {/* <If and value1={produtoWebDisponivel}>
            <CadastroProdutoPrecificacaoWeb
              {...this.props}
              idProdutoWeb={produto ? produto.idProdutoWeb : null}
              layoutProduto={true}
            />
          </If> */}
        </If>

        <If and value1={target === "PRECO"}>
          <PrecoEstoque produto={produto} />
        </If>

        <If and value1={target === "DEPARTAMENTO"}>
          <DepartamentoProdutoDetalhe
            padrao={produto.descricaoDepartamentoSecao}
            personalizado={produto.descricaoDepartamentoSecaoPersonalizado}
            produtoPersonalizado={produto.descricaoDepartamentoSecaoProduto}
            idProduto={produto.idProduto}
            idFilial={this.props.idFilial}
            idEmpresa={this.props.idEmpresa}
            consultarProduto={this.loadingComponente}
            produto={produto}
            personalizacao={produto.produtoPersonalizado}
          />
        </If>

        <If and value1={target === "FILTROS"}>
          <FiltrosAdicionar 
            idProdutoWeb={produto.idProdutoWeb}
            filtros={produto.filtros}
            consultarProduto={this.loadingComponente}
          />
        </If>

        <If and value1={target === "FOTOS"}>
          <GaleriaFotos 
            idProdutoWeb={produto.idProdutoWeb}  
            imagens={produto.imagens}
            consultarProduto={this.loadingComponente}
          />
        </If>
        <If and value1={target === "INFO_ADD"}>
          <InformacoesAdicionais
            idProdutoWeb={produto.idProdutoWeb}  
            produto={produto}
            consultarProduto={this.loadingComponente}
          />
        </If>

      </Div>
    );
  }
}



export default ProdutoDetalhe;

