import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CarouselBannerGenerico, Div, I, If, Label, ModalNotification } from "../components";
import { atualizarCarrinho, setEmpresa, setParametrosPedido, setProdutos, } from "../redux/actions";
import { Color, EndPoints, Http, Type, Utils } from "../utilities";
import ItemProduto from "./ItemProduto";
import CarrinhoUtils from "./utils/CarrinhoUtils";
import EmpresaUtils from "./utils/EmpresaUtils";
import ProdutoUtils from "./utils/ProdutoUtils";

class Principal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anuncios: [],
      lista: [],
      loading: false,
      produtos: []
    };

    this.adicionarItemNoCarrinho = this.adicionarItemNoCarrinho.bind(this);

  }

  componentDidMount() {
    this.listarProdutos()
    this.setTitlePage()
    this.setFavIcon()
  }

  setFavIcon = () => {
    let iconFiveIcon = document.querySelector("link[rel~='icon']");
    let urlFavIcon = EmpresaUtils.getParametro(
        this.props.empresa,
        "urlFavIcon"
    );
    if (!iconFiveIcon) {
      iconFiveIcon = document.createElement("link");
      iconFiveIcon.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(iconFiveIcon);
    }
    iconFiveIcon.href = `${urlFavIcon}?${Utils.uuidString()}`;
  };

  setTitlePage = () => {
    document.title = EmpresaUtils.getParametro(
        this.props.empresa,
        "tituloPagina"
    );
  };

  listarProdutos() {
    let param = {
      "numeroPagina": 1,
      "descricao": "",
      "departamentos": [],
      "secoes": [],
      "marcas": [],
      "destaque": true
    }

    Http.post(EndPoints.PRODUTO_BUSCA_FILTRO, param)
      .then(({ status, data }) => {
        if (status) {
          this.setState(
            {
              produtos: [...data.listaProdutos],
            }
          );
        }
      })
      .catch((erro) => console.log(erro));
  }


  visualizarProdutosAnuncio({
    titulo,
    idAnuncioOferta,
    idDepartamentoSecaoFiltro,
    tipoDepartamentoSecao,
    marcaFiltro,
    possuiProdutos,
    estatico,
  }) {
    if (!estatico) {
      this.props.history.push("/anuncio/produtos", {
        titulo,
        idAnuncioOferta,
        idDepartamentoSecaoFiltro,
        tipoDepartamentoSecao,
        marcaFiltro,
        empresa: this.props.empresa,
        possuiProdutos,
      });
    }
  }

  renderVideo(item) {
    ModalNotification.renderMessage({
      title: item.titulo,
      body: (
        <Div inline="center">
          <video src={item.urlVideo} controls style={{ width: "100%" }} />
        </Div>
      ),
      responsive: "6",
      handlerCancel: () => {
        ModalNotification.removeModal();
      },
      labelButtonCancel: "Fechar",
    });
  }

  async adicionarItemNoCarrinho(produto, quantidade, adicionar) {
    let qdt = quantidade || 0

    if (adicionar) {
      qdt = quantidade ? quantidade + 1 : 1
    } else {
      if (produto.quantidade >= 1) {
        qdt = quantidade - 1
      }
    }

    let { carrinho, produtos } = this.props;
    let prod = ProdutoUtils.calcularValorProduto(produto, qdt);

    CarrinhoUtils.adicionarItemNoCarrinho(
      prod,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      null,
      this.props.parametros
    );
  }


  getItensBannerTopo() {
    const anuncios = this.props.anuncios;
    let itens = [];

    let itensTopo = Array.from(anuncios.banner || []);

    if (Utils.isValueValid(itensTopo)) {
      itensTopo.forEach((item) => {
        itens.push(
          <>
            {item.exibirVideo ? (
              <img
                src={item.urlFoto}
                onClick={() => this.renderVideo(item)}
                width="100%"
                className="cursor-pointer"
              />
            ) : (
              <img
                src={item.urlFoto}
                width="100%"
                className="cursor-pointer"
                onClick={() => this.visualizarProdutosAnuncio(item)}
              />
            )}
          </>
        );
      });
    }

    return itens;
  }

  render() {

    let itensBannerTopo = this.getItensBannerTopo();


    return (
      <>
        <If and value1={Utils.isValueValid(itensBannerTopo)}>
          <Div className="w-100 overflow-hidden" marginBottom="3">
            <CarouselBannerGenerico
              timeSlide={7000}
              itens={itensBannerTopo}
              slidesPerPage={1}
              dots
            />
          </Div>
        </If>
        <Div style={{ width: 1150 }}>
          {/* <Div inline="start" col="12">
          <I
            icon={Type.ICON.STAR}
            style={{
              fontSize: 20,
              color: Color.ECOMMERCE.CINZA_ESCURO,
              marginBottom: 10,
              marginLeft: 15
            }}
          />
          <Label
            value="NOSSO CATÁLOGO"
            style={{
              color: Color.ECOMMERCE.CINZA_ESCURO,
              fontSize: 20,
              marginLeft: 10,
            }}
            family="Bold"
          />
        </Div> */}
          <Div col="12" inline="start">
            <I
              icon={Type.ICON.STAR}
              style={{
                fontSize: 25,
                color: Color.ECOMMERCE.CINZA_ESCURO,
                marginBottom: 15,
              }}
            />
            <Label
              style={{
                color: "#878787",
                fontSize: "25px",
                marginLeft: 10,
              }}
              family="Bold"
              value="PRODUTOS EM OFERTA"
            />
          </Div>
          <Div style={{
            display: "flex",
            justifyContent: "center"
          }}>
            <Div
              marginTop="2"
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {
                this.state.produtos.map(p => {
                  return (
                    <ItemProduto
                      history={this.props.history}
                      image={p.urlImagemPrincipalThumbnail}
                      descricao={p.descricao}
                      quantidade={p.quantidade}
                      disponivel={p.disponivel}
                      proibidaVenda={p.proibidaVenda}
                      precosConcorrentes={p.precosConcorrentes}
                      empresa={this.props.empresa}
                      produto={p}
                      idProdutoWeb={p.idProdutoWeb}
                      handlerAddItemCarrinho={(adicionar, quantidade, callback) => {
                        this.adicionarItemNoCarrinho(p, quantidade, adicionar).then(() => {
                          if (callback) {
                            callback(p)
                          }
                        });
                      }}

                    />
                  )
                })
              }
            </Div>
          </Div>
        </Div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.parametrosPedidoState,
    ...store.listasDeCompraState,
    ...store.empresaState,
    ...store.anunciosState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { atualizarCarrinho, setProdutos, setEmpresa, setParametrosPedido },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Principal);
