import React, { Component } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";

import App from "../App";
import {
  Principal,
  ErrorApplication,
  ProdutosPorDepartamento,
  ProdutosPesquisa,
  EmpresaUtils,
  ErrorPaginaPrincipalNaoEncontrada,
  AguardeMobile,
  Aguarde,
  PrincipalMobile,
  ProdutosPorDepartamentoMobile,
  ProdutosPorAnuncioOferta,
  ProdutosPorAnuncioMobile,
  ProdutosPesquisaMobile,
  FinalizacaoPedido,
  FinalizacaoPedidoMobile
} from "../views";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { atualizarCarrinho, setProdutos, setEmpresa } from "../redux/actions";
import { Http, Keys, Utils } from "../utilities";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import {
  Cadastrar,
  ConfigGerais,
  Dashboard,
  DepartamentoSecao,
  DetalhesPedidoListagem,
  GestorOrcamento,
  InformacoesGerais,
  LoginAdmin,
  MenuDepartamento,
  OrcamentoDashboard,
  ProdutosDashboard,
  ProdutosDetalheDashboard
} from "../viewsDashboard";
import AppMobile from "../AppMobile";

const initMap = () => {
  let map = new Map();
  map.set(
    Keys.VIEW_PRINCIPAL,
    Utils.detectMob() ? PrincipalMobile : Principal
  );

  return map;
};

class Routes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      empresa: {},
      views: initMap(),
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    Utils.scrollTop();
  }

  getRoute(path, component) {
    return (
      <Route
        path={path}
        render={(props) => {
          return Utils.detectMob() ? (
            <AppMobile {...props}>{component} </AppMobile>
          ) : (
            <App {...props}>{component}</App>
          );
        }}
      />
    );
  }


  consultarEmpresa() {
    Http.get("empresa")
      .then(({ status, data }) => {
        if (status) {
          this.props.setEmpresa(data);
        }
      })
      .catch((erro) => console.log(erro));
  }

  getPrincipal() {
    if (Utils.isValueValid(this.props.empresa)) {
      let paginaPrincipal = EmpresaUtils.getPaginaPrincipal(this.props.empresa);

      if (Utils.isValueValid(paginaPrincipal)) {
        return React.createElement(this.state.views.get(paginaPrincipal), {
          showRodape: true,
          showHeader: true,
          ...this.props,
        });
      } else {
        return (
          <ErrorPaginaPrincipalNaoEncontrada
            {...this.props}
            showHeader={false}
            showRodape={false}
          />
        );
      }

      //return  <Principal showHeader={true} showRodape={true} {...this.props} />;
    } else {
      return Utils.isViewMobile() ? <AguardeMobile /> : <Aguarde />;
    }
  }

  componentDidMount() {
    this.consultarEmpresa();
  }

  render() {
    return (
      <Switch>
        <Redirect from="/admin/horarios" exact to="/admin/horarios/entregas" />
        <Redirect from="/admin/personalizacao" exact to="/admin/personalizacao/geral" />
        <Redirect from="/admin/pesquisas" exact to="/admin/pesquisas/listar" />
        <Redirect from="/admin" exact to="/admin/dashboard" />

        <PublicRoute restricted={true} component={LoginAdmin} path="/admin/login" exact />

        <PrivateRoute Component={Dashboard} path="/admin/dashboard" exact />

        <PrivateRoute Component={InformacoesGerais} path="/admin/personalizacao/geral" exact />
        <PrivateRoute Component={ConfigGerais} path="/admin/personalizacao/cores" exact />
        <PrivateRoute Component={MenuDepartamento} path="/admin/personalizacao/menu" exact />

        <PrivateRoute Component={ProdutosDashboard} path="/admin/produtos" exact />
        <PrivateRoute Component={ProdutosDetalheDashboard} path="/admin/produtos/:id" exact />
        <PrivateRoute Component={Cadastrar} path="/admin/produtos/cadastrar" exact />

        <PrivateRoute Component={OrcamentoDashboard} path="/admin/orcamentos/" exact />
        <PrivateRoute Component={GestorOrcamento} path="/admin/orcamentos/gestor" exact />
        <PrivateRoute Component={DetalhesPedidoListagem} path="/admin/orcamentos/:idorcamento" exact />

        <PrivateRoute Component={DepartamentoSecao} path="/admin/departamentos" exact />

        {this.getRoute(
          "/produto/departamento/:descricao",
          Utils.detectMob() ? (
            <ProdutosPorDepartamentoMobile
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          ) : (
            <ProdutosPorDepartamento
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          )
        )}

        {this.getRoute(
          "/anuncio/produtos",
          Utils.isViewMobile() ? (
            <ProdutosPorAnuncioMobile
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          ) : (
            <ProdutosPorAnuncioOferta
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          )
        )}
        {this.getRoute(
          "/resultadopesquisa",
          !Utils.isViewMobile() ? (
            <ProdutosPesquisa
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          ) : (
            <ProdutosPesquisaMobile
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          )
        )}

        {this.getRoute(
          "/finalizacao",
          Utils.isViewMobile() ? (
            <FinalizacaoPedidoMobile
              showHeader={false}
              showRodape={false}
              {...this.props}
            />
          ) : (
            <FinalizacaoPedido
              showHeader={false}
              showRodape={false}
              {...this.props}
            />
          )
        )}
        
        {this.getRoute("/", this.getPrincipal())}
        {this.getRoute("/*", this.getPrincipal())}
        {this.getRoute("/error", <ErrorApplication {...this.props} />)}
      </Switch>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.empresaState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ atualizarCarrinho, setProdutos, setEmpresa }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Routes));
