import { Http } from '../utilities';

class DepartamentoService {
  static async getDepartamentoSecao(idFilial) {

    let res = null;
    await Http.get(`/departamentosecao/filial/${idFilial}`).then(
      response => {
        res = response.data;
      },
    );
    return res;
  }

  static async createDepartamentoPers(obj) {
    let res = null;

    await Http.post('/departamentosecao/departamento/personalizado', obj).then(response => {
      res = response.data;
    });

    return res;

  }

  static async createSecao(obj) {
    let res = null;

    await Http.post('/departamentosecao/secao/personalizado', obj).then(response => {
      res = response.data;
    });

    return res;

  }

  static async editarDepartamento(obj) {
    let res = null;

    await Http.put('/departamentosecao/personalizado', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async removeDepartamentoPers(obj) {
    let res = null;

    await Http.delete('/departamentosecao/personalizado', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async removeSecaoPers(obj) {
    let res = null;

    await Http.delete('/departamentosecao/personalizado', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async vincularSecao(obj) {
    let res = null;

    await Http.put('/departamentosecao/vincular', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async removerVinculo(obj) {
    let res = null;

    await Http.put('/departamentosecao/vincular', obj).then(response => {
      res = response.data;
    });

    return res;
  }
}

export default DepartamentoService;
