import React, { Component } from "react";

import {
  Div,
  Label,
  I,
  InputDefault,
  InputNumberBR,
  TextAreaCustom,
  ButtonIconOutLine,
  ModalNotification,
} from "../../components";


import { Color, Type } from "../../utilities";



class InformacoesAdicionais extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoAdd: "",
    }
  }

  handleChanceTextArea = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  salvarInfoADD() {
    console.log("teste")
    // const { selectDep, selectSec, idProdutoWebDepartametoSecao, editar } = this.state

    // let obj = {
    //   idProdutoWebDepartamentoSecao: idProdutoWebDepartametoSecao,
    //   idDepartamentoWeb: selectDep,
    //   idSecaoWeb: selectSec
    // }

    // if(editar) {
    //   ProdutoCataloService.alterarDepartamentoSec(obj).then((data) =>{
    //     if (Utils.isNotNull(data)) {
    //       Notification.success(data.mensagem)
    //       this.setState({
    //         loading: true,
    //         editar: false
    //       })
    //     } else {
    //       Notification.danger(Utils.isNotNull(data) ? data.mensagem : "Não Foi Possivel Atualizar Departamento e Seção")
    //     }
    //   })
    // }


  }


  modalInfoADD() {

    ModalNotification.renderMessage({
      title: "Salvar Informações Adicionais ",
      body: (
        `Deseja SALVAR novas Informações adicionais para esse produto?`
      ),
      handlerConfirm: () => this.salvarInfoADD(),
      handlerCancel: () => { },
      labelButtonCancel: "Cancelar",
    });
  }

  render() {
    const { produto } = this.props;

    return (
      <Div marginBottom="2">
        <Div>
          <Div
            padding="1"
            inline="start"
            bg={Color.NODE.WHITE}
            colorText={Color.NODE.DEFAULT}
            className="titulo-precificacao"
          >
            <I icon={Type.ICON.INFO} marginLeft="2" marginBottom="2" />
            <Label bold value="Informações Adicionais Para o Produto" rounded marginLeft="2" />
          </Div>
          <Div>
            <Div col="12" shadow inline="start" paddingTop="2" paddingBottom="2" >
              <Div col="12">
                <TextAreaCustom
                  col="12"
                  value={this.state.infoAdd}
                  handleChance={e => this.handleChanceTextArea("infoAdd", e)}
                />
              </Div>
            </Div>
          </Div>

          <Div col="12" inline="end">
            {/* <ButtonIconOutLine
              value="Cancelar"
              style={{ maxWidth: "170px", height: "40px", marginTop: "27px" }}
              btn={Color.NODE.SECONDARY}
              handlerClick={() => this.setState({ editCustomDep: false })}
              col="2"
              className="custom-btn"
            /> */}

            <ButtonIconOutLine
              value="Salvar"
              style={{ maxWidth: "170px", height: "40px", marginTop: "27px" }}
              btn={Color.NODE.PRIMARY}
              handlerClick={() => this.modalInfoADD()}
              col="2"
              className="custom-btn"
            />

          </Div>
        </Div>
      </Div>
    );
  }
}

export default InformacoesAdicionais;