import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Checkbox, Div, I, If, Input, InputDefault, Label, ModalNotification, Notification, Row, Title } from "../../components";
import { Color, Type, Utils } from "../../utilities";
import Tree from 'react-animated-tree'
import DepSecDashboardService from "../../service/DepSecDashboardService";
import NovaSecao from "./NovaSecao";
import NovoDepartamento from "./NovoDepartamento";
import EditarSecao from "./EditarSecao";
import VincularSecao from "./VincularSecao";
import DepartamentoService from "../../service/DepartamentoService";

const treeStyles = {
  color: "rgb(62, 65, 73)",
  width: '100%',
  marginLeft: "15px",
  paddingRight: "25px",
  paddingTop: "15px",
  fontWeight: "bolder",

}

const treeDepart = {
  padding: "10px 5px 10px 10px",
  border: "1px solid #dee2e6",
  borderRadius: "11px",
  marginBottom: "15px"

}

const treeSec = {
  color: "rgb(158, 158, 158)",
  padding: "10px 0px 10px 10px",
  borderTop: "1px solid #dee2e6",
  fontWeight: "400",
  fontSize: "14px"

}

const styles = {
  label: {
    color: "#9e9e9e",
    fontSize: "17px",
    fontWeight: "bold"
  },
  labelVinculo: {
    color: Color.CUSTOM_COLORS.PRIMARY,
    border: `1px solid ${Color.CUSTOM_COLORS.PRIMARY}`,
    padding: "3px",
    borderRadius: "3px",
    position: "absolute",
    fontSize: "10px",
    top: "-3px",
    cursor: "pointer",
    left: "21px"
  }
}


class DepartamentoSecao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newDep: false,
      newSec: false,
      editSec: false,
      loading: false,
      vincular: false,
      active: false,
      indexSec: 0,
      descricaoSec: "",
      departamentoCustom: "",
      secaoCustom: "",
      checked: false,
      principal: [],
      principalFilter: [],
      openTree: true,
      custom: [
        { 
          descricao: "departamento",
          codigoClassificacao: "123",
          secao: [
            {
              descricao: "secao",
              codigoClassificacao: "123",
            }
          ]
        }
      ],
      departamentoSelecionado: {},
      objEdicao: {},
      secaoVincular: {},
    }

  }

  componentDidMount() {
    this.getDepartamentoSecao()
  }

  componentDidUpdate() {
    if (this.state.loading) {
      this.getDepartamentoSecao()
    }
  }

  getDepartamentoSecao() {
    DepartamentoService.adminGetDepartamentos().then((data) => {
      if (data) {
        this.setState({
          loading: false,
          custom: data,
        })
      }
    })
  }

  confirmeDeleteDepartamento(dep) {

    let removerDepartamento = {
      idEmpresa: dep.idEmpresa,
      idFilial: dep.idFilial,
      idClassificacaoWeb: dep.idClassificacaoWeb,
      tipoClassificacao: "DEPARTAMENTO"
    }

    DepSecDashboardService.removeDepartamentoPers(removerDepartamento).then(({ status, message }) => {
      if (status) {
        Notification.success(message)
        this.getDepartamentoSecao()
      } else {
        Notification.danger(message)
      }
    })
  }

  deletarDepartamento(departamento) {
    if (departamento.secoes.length > 0) {
      Notification.danger("Existem Seções no Departamento!")
    } else {
      ModalNotification.renderMessage({
        title: "Deseja remover esse Departamento?",
        body: (
          "Este Departamento será removido do sistema!"
        ),
        handlerConfirm: () => this.confirmeDeleteSecao(departamento),
        handlerCancel: () => { },
        labelButtonCancel: "Fechar",
      });
    }
  }


  confirmeDeleteSecao(sec) {

    let removerSecao = {
      idDepartamentoSecao : sec.idDepartamentoSecao,
      tipoClassificacao : "SECAO"    
    }

    DepartamentoService.adminDelDepartamentoSec(removerSecao).then((data) => {
      
      if (data.status) {
        Notification.success(data.mensagem)
        this.getDepartamentoSecao()
      } else {
        Notification.danger(data.mensagem)
      }
    })
  }

  modalDeletarSecao(Secao) {
    ModalNotification.renderMessage({
      title: "Deseja remover essa Seção do Departamento?",
      body: (
        "Este Seção personalizada será removido do sistema!"
      ),
      handlerConfirm: () => this.confirmeDeleteSecao(Secao),
      handlerCancel: () => { },
      labelButtonCancel: "Fechar",
    });
  }


  confirmeRemoverVinculo(sec) {

    let removerSecao = [{
      idEmpresa: sec.idEmpresa,
      idFilial: sec.idFilial,
      idClassificacao: sec.idClassificacao,
      idClassificacaoWeb: null,
      idDepartamentoWeb: null
    }]

    DepSecDashboardService.removerVinculo(removerSecao).then(({ status, message }) => {
      if (status) {
        Notification.success(message)
        this.getDepartamentoSecao()
      } else {
        Notification.danger(message)
      }
    })
  }

  removerVinculo(Secao) {

    ModalNotification.confirm(
      "Deseja remover o vínculo dessa Seção? ",
      "O vínculo dessa seção será removido do sistema!",
      () => this.confirmeRemoverVinculo(Secao)
    );

  }

  handleChange = (e) => {
    e.stopPropagation();
    let { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  getClassSearch() {
    return this.state.active ? "active" : "";
  }

  handleChangeSearch(e) {
    let { value } = e.target;
    let { principal, principalFilter } = this.state;

    let newFilter = [...principalFilter]

    if (value.trim() !== "") {
      newFilter = principal.filter(item =>
        (item.descricao !== null && item.descricao.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        (item.codigoClassificacao !== null && item.codigoClassificacao.toLowerCase().indexOf(value.toLowerCase()) > -1)
      )

      this.setState({
        principal: newFilter
      })
    } else {
      this.setState({
        principal: principalFilter
      })
    }


  }

  searchPrincipal = () => {
    return (
      <Div className="input-group" margin="2" marginBottom="3">
        <Div col="12" style={{ paddingRight: "42px" }} className={`translateXmenos15 translateXmais15 input-group-prepend searchbar d-flex ${this.getClassSearch()}`}>
          <I
            icon="fa fa-search search-table"
            bg="secondary"
            colorText="white"
            handlerClick={e => {
              this.setState({
                active: !this.state.active
              });
            }}
            className="inputgrouptext d-flex"
          ></I>
          <Input
            display="block"
            type="search"
            placeholder="Pesquisar Departamento"
            className="inputsearch formcontrol"
            handlerChange={(e) => { this.handleChangeSearch(e) }}
          ></Input>
        </Div>
      </Div>
    )
  }


  renderTreePrincipal = (modal = false) => {
    const { principal } = this.state;

    let iconCheckbox = (
      <>
        <span
          style={{
            position: "absolute",
            margin: "-9px 0px 0px -10px"
          }}
        >
          <Checkbox
            handlerChange={(e) =>
              this.setState({
                checked: e.checked
              })
            }
            margin="2"
            checked={this.state.checked}
          //danger
          />
        </span>
        <span
          style={{
            marginRight: "15px"
          }}
        > </span>
      </>
    )

    let labelVinculo = secao => (
      <>
        <span
          style={{
            position: "absolute",
            left: "30px",
            top: "43px",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <span
            title={secao.descricaoVinculo}
            style={styles.labelVinculo}
          >
            {`${secao.descricaoVinculo} `}
          </span>
          <I
            pointer
            icon={Type.ICON.DELETE}
            title="Remover Vinculo"
            style={{
              color: Color.CUSTOM_COLORS.DANGER,
              fontSize: 18,
              marginRight: "-11px"
            }}
            handlerClick={() => this.removerVinculo(secao)}
          />
        </span>
        <span
          style={{
            marginRight: "-15px"
          }}
        > </span>
      </>
    )

    return (
      <Div col="12" shadow className="border" style={{ padding: "15px" }}>
        {this.searchPrincipal()}
        <Tree content="Departamentos Principais" open={this.state.openTree} style={treeStyles}>
          {
            principal.map(departamento => {
              return (
                <Tree
                  type={modal ? iconCheckbox : <span style={{ marginRight: "-15px" }}> </span>}
                  content={departamento.codigoClassificacao + " - " + departamento.descricao}
                  style={treeDepart}
                >
                  {Utils.isNotNull(departamento.secoes) &&
                    <>
                      {
                        departamento.secoes.map(secao => {
                          return (
                            <Tree
                              title={secao.descricao}
                              type={
                                modal ? iconCheckbox :
                                  secao.descricaoVinculo ? labelVinculo(secao) : <span style={{ marginRight: "-15px" }}> </span>
                              }
                              content={`${secao.codigoClassificacao} - ${secao.descricao} `}
                              style={
                                secao.descricaoVinculo ?
                                  { ...treeSec, paddingBottom: "32px" } :
                                  treeSec
                              }
                            />
                          )
                        })
                      }
                    </>
                  }
                </Tree>
              )
            })
          }
        </Tree>
      </Div>
    )
  }

  closeComponente = () => {
    this.setState({
      newSec: false,
      newDep: false,
      editSec: false,
      openTree: true,
      loading: true,
      vincular: false,
    })
  }

  bodyNovaSecao(index, descricao) {
    const { secaoCustom, newSec } = this.state;

    return (
      <If and value1={newSec}>
        <InputDefault
          responsive="12"
          label={`Nova Seção a ${descricao}`}
          name="secaoCustom"
          placeholder="Escreva uma Descrição para a Seção"
          required
          handlerChange={(e) => this.handleChange(e)}
          value={secaoCustom}
        />
        <Div style={{ margin: "15px" }}>
          <Label value="Vincular Seção a Categoria Principal" style={styles.label} />
          {this.renderTreePrincipal(true)}
        </Div>

        <Div style={{ margin: "15px" }}>
          <Button
            handlerClick={() => this.confirmCustomSecao(index)}
            value="Confirmar"
            btn="primary"
            marginRight="3"
          />

          <Button
            handlerClick={
              (e) => this.setState({
                newSec: false,
                secaoCustom: "",
              })
            }
            value="Cancelar"
            btn="secondary"
          />
        </Div>
      </If>
    )
  }

  renderTreeCustom = () => {
    const { custom, newDep, newSec, editSec, vincular } = this.state;

    let iconAddDepartamento = (
      <>
        <span
          style={{
            position: "absolute",
            right: "50px",
          }}
        >
          <I
            pointer
            icon={Type.ICON.PLUS_CIRCLE}
            title="Novo Departamento"
            style={{
              color: Color.CUSTOM_COLORS.PRIMARY,
              fontSize: 23,
              marginRight: "-15px"
            }}
            handlerClick={() =>
              this.setState({
                newDep: !newDep,
                newSec: false,
                editSec: false,
                openTree: newDep
              })
            }
          />
        </span>
        
        <span
          style={{
            marginRight: "-15px"
          }}
        > </span>
      </>
    )

    let iconAddSecao = (index, departamento) => (
      <>
        <span
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <I
            pointer
            title="Nova Seção"
            icon={Type.ICON.PLUS_CIRCLE}
            style={{
              color: Color.CUSTOM_COLORS.PRIMARY,
              fontSize: 23,
              marginRight: "10px"
            }}
            handlerClick={() => {
              this.setState({
                newSec: !newSec,
                editSec: false,
                newDep: false,
                openTree: newSec,
                indexSec: index,
                departamentoSelecionado: departamento
              })
            }}
          />
          <I
            pointer
            title="Editar Departamento"
            icon={Type.ICON.EDIT}
            style={{
              color: Color.RGBA.LARANJA,
              fontSize: 23,
              marginRight: "10px"
            }}
            handlerClick={() => {
              this.setState({
                newSec: false,
                editSec: !editSec,
                newDep: false,
                openTree: editSec,
                indexSec: index,
                objEdicao: departamento
              })
            }}
          />
          <I
            title="Remover Departamento"
            pointer
            icon={Type.ICON.DELETE}
            style={{
              color: Color.CUSTOM_COLORS.DANGER,
              fontSize: 23,
              marginRight: "10px"
            }}
            handlerClick={() => this.deletarDepartamento(departamento)}
          />
        </span>
        <span
          style={{
            marginRight: "-15px"
          }}
        > </span>
      </>
    )


    let iconEditarSecao = (index, secao) => (
      <>
        <span
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <I
            title="Vincular Seção"
            pointer
            icon={Type.ICON.TAG}
            style={{
              color: Color.CUSTOM_COLORS.PRIMARY,
              fontSize: 23,
              marginRight: "10px"
            }}
            handlerClick={() => {
              this.setState({
                newSec: false,
                editSec: false,
                vincular: !vincular,
                newDep: false,
                openTree: vincular,
                indexSec: index,
                secaoVincular: secao
              })
            }}
          />
          <I
            title="Editar Seção"
            pointer
            icon={Type.ICON.EDIT}
            style={{
              color: Color.RGBA.LARANJA,
              fontSize: 23,
              marginRight: "10px"
            }}
            handlerClick={() => {
              this.setState({
                newSec: false,
                editSec: !editSec,
                newDep: false,
                openTree: editSec,
                indexSec: index,
                objEdicao: secao
              })
            }}
          />
          <I
            title="Remover Seção"
            pointer
            icon={Type.ICON.DELETE}
            style={{
              color: Color.CUSTOM_COLORS.DANGER,
              fontSize: 23,
              marginRight: "5px"
            }}
            handlerClick={() => { this.modalDeletarSecao(secao) }}
          />
        </span>
        <span
          style={{
            marginRight: "-15px"
          }}
        > </span>
      </>
    )

    return (
      <Div col="12" shadow className="border" style={{ padding: "15px" }}>

        <Tree content="Departamentos e Seções" type={iconAddDepartamento} open={this.state.openTree} style={treeStyles}>
          {
            custom.map((departamento, index) => {
              return (
                <Tree
                  content={departamento.idDepartamentoSecaoExterno + " - " + departamento.descricao}
                  type={
                    <span>
                      {iconAddSecao(index, departamento)}
                    </span>
                  }
                  style={treeDepart}
                >
                  {Utils.isNotNull(departamento.secoes) &&
                    <>
                      {
                        departamento.secoes.map(secao => {
                          return (<Tree
                            content={secao.idDepartamentoSecaoExterno + " - " + secao.descricao}
                            type={
                              <span>
                                {iconEditarSecao(index, secao)}
                              </span>
                            }
                            style={treeSec}
                          />)
                        })
                      }
                    </>
                  }
                </Tree>
              )
            })
          }
        </Tree>

        <If and value1={custom.length === 0}>
          <Div style={{
            padding: "10px 5px 10px 10px",
            border: "1px solid #dee2e6",
            borderRadius: "11px",
            margin: "15px 15px 15px 35px"
          }}>
            <Label value="Nenhum Departamento Cadastrado" style={styles.label} />
          </Div>
        </If>
      </Div>
    )
  }

  confirmCustomDepartamento = () => {
    const { custom } = this.state;
    let newCustom = [...custom]

    newCustom.push(
      {
        descricao: this.state.departamentoCustom,
        secao: []
      }
    )

    this.setState({
      custom: newCustom,
      departamentoCustom: "",
      secaoCustom: "",
      newDep: false,
      newSec: false
    })
  }

  confirmCustomSecao = (index) => {
    const { custom, secaoCustom } = this.state;
    let newCustom = [...custom]

    newCustom[index].secao.push(
      {
        descricao: this.state.secaoCustom,
      }
    )

    this.setState({
      custom: newCustom,
      departamentoCustom: "",
      secaoCustom: "",
      newDep: false,
      newSec: false,
      vincular: false
    })
  }

  render() {

    const { principal, departamentoSelecionado, objEdicao } = this.state;

    return (
      <Div
        margin="3"
        shadow
        bg={Color.NODE.WHITE}
        borderLeft={Color.NODE.PRIMARY}
        style={{
          padding: "20px 10px"
        }}
      >
        <Title
          value="Departamento e Seção"
          type="h4"
          paddingLeft="3"
          paddingRight="4"
          paddingTop="3"
        /> 

        <Row>
          {/* <Div
            col="5"
            paddingLeft="3"
            style={{
              padding: "20px 10px",
              overflowY: "scroll",
              maxHeight: "65vh"
            }}
          >
            {this.renderTreePrincipal()}
          </Div> */}

          <Div
            col="12"
            paddingLeft="3"
            style={{
              padding: "20px 10px",
              overflowY: "scroll",
              maxHeight: "65vh"
            }}
          >
            {this.renderTreeCustom()}
          </Div>
        </Row>

        <If and value1={this.state.newDep}>
          <Row col="12">
            <NovoDepartamento
              closeComponente={this.closeComponente}
              getDepartamentoSecao={this.getDepartamentoSecao}
              departamentos={principal} />
          </Row>
        </If>

        <If and value1={this.state.vincular}>
          <Row col="12">
            <VincularSecao
              closeComponente={this.closeComponente}
              secaoVincular={this.state.secaoVincular}
              departamentos={principal}
            />
          </Row>
        </If>

        <If and value1={this.state.newSec}>
          <Row col="12">
            <NovaSecao
              closeComponente={this.closeComponente}
              departamentos={principal}
              departamentoSelecionado={departamentoSelecionado}
            />
          </Row>
        </If>

        <If and value1={this.state.editSec}>
          <Row col="12">
            <EditarSecao
              closeComponente={this.closeComponente}
              objEdicao={objEdicao}
            />
          </Row>
        </If>
      </Div>
    )
  }

}

const mapStateToProps = (store) => ({
  idEmpresa: store.loginState.user.idEmpresa,
  idFilial: store.loginState.user.idFilial,
});

export default connect(mapStateToProps)(DepartamentoSecao);