import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./App.css";
import { Div, If, Parent } from "./components";
import { EndPoints, Http, Keys, Utils } from "./utilities";

import {
  atualizarCarrinho,
  setProdutos,
  setListasDeCompra,
  setEmpresa,
  setMenus,
  setAnuncios,
  setParametrosPedido,
} from "./redux/actions";
import Rodape from "./views/rodape/Rodape";
import PathImage from "./views/utils/PathImage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HeaderLojaWebMobile } from "./views";


class AppMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menus: [],
      abrirMenuDepartamentos: false,
      portrait: true,
    };

    this.atualizarPaginaNovaVersao();

    this.rotateScreen();
  }

  rotateScreen() {
    if ("onorientationchange" in window) {
      window.addEventListener(
        "orientationchange",
        (event) => {
          Utils.refreshPage();
        },
        false
      );
    }
  }

  atualizarPaginaNovaVersao() {
    let reloadPage = localStorage.getItem(Keys.KEY_RELOAD_PAGE);
    if (
      !Utils.isValueValid(reloadPage) ||
      String(reloadPage) !== Keys.KEY_VERSION_PAGE
    ) {
      localStorage.setItem(Keys.KEY_RELOAD_PAGE, Keys.KEY_VERSION_PAGE);
      localStorage.removeItem(Keys.KEY_CARRINHO_LOJA_WEB);
      localStorage.removeItem(Keys.KEY_USUARIO_LOJA_WEB);
      window.location.reload(true);
    }
  }

  componentDidMount() {
    this.listarMenus();
    this.consultarProdutos();
    this.consultarAnuncios();
  }


  consultarAnuncios() {
    Http.get("oferta")
      .then(({ status, data }) => {
        if (status) {
          this.props.setAnuncios(data);
        }
      })
      .catch((erro) => console.log(erro));
  }

  consultarProdutos() {
    Http.get("produto")
      .then(({ status, data }) => {
        if (status) {
          let imagensIndisponiveis = [
            PathImage.IMAGEM_INDISPONIVEL,
            PathImage.IMAGEM_INDISPONIVEL_TUMB,
          ];
          let produtos = Array.from(data).filter(
            (p) => !imagensIndisponiveis.includes(p.urlImagemPrincipalThumbnail)
          );
          this.props.setProdutos(produtos);
        }
      })
      .catch((erro) => console.log(erro));
  }

  listarMenus() {
    Http.get(EndPoints.MENU)
      .then(({ status, data }) => {
        if (status) {
          this.props.setMenus(data);
        }
      })
      .catch((erro) => console.log(erro));
  }

  render() {
    let showHeader = this.props.children[0].props.showHeader;

    return (
      <Parent>
        <ToastContainer position="bottom-right" />
        <If
          and
          value1={showHeader}
          value2={Utils.isValueValid(this.props.empresa)}
        >
          <HeaderLojaWebMobile
            empresa={this.props.empresa}
            menus={this.props.menus}
            history={this.props.history}
            handlerToggleDepartamentos={() =>
              this.setState({
                abrirMenuDepartamentos: !this.state.abrirMenuDepartamentos,
              })
            }
            handlerAbrirPesquisa={() => {
              this.setState({
                abrirMenuDepartamentos: false,
              });
            }}
          />
        </If>


        <Div inline="center" style={{ marginTop: "160px" }}>
          {React.Children.map(this.props.children, (child, i) => {
            return child;
          })}
        </Div>

        <If
          and
          value1={this.props.children[0].props.showRodape}
          value2={Utils.isValueValid(this.props.empresa)}
        >
          <Rodape empresa={this.props.empresa} />
        </If>
      </Parent>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.listasDeCompraState,
    ...store.menusState,
    ...store.empresaState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      atualizarCarrinho,
      setProdutos,
      setListasDeCompra,
      setEmpresa,
      setMenus,
      setAnuncios,
      setParametrosPedido,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppMobile);
