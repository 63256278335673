import React from 'react'
import { Component } from 'react';
import { Div, Label } from '../../components';

class ErrorApplication extends Component {


    render() {
        return (
            <Div className="text-center" column>
                <Label
                    value="Página principal não encontrada"
                    family="Bold"
                    style={{ fontSize: 30 }}
                />
                <Label
                    value="As configurações básicas do site não foram aplicadas!"
                    family="Bold"
                    style={{ fontSize: 15 }}
                />
            </Div>
        )
    }

}

export default ErrorApplication;