import React, { Component } from "react";
import { Div, Button } from "../../components";
import { Color, Utils } from "../../utilities";
import If from "../if/If";
import "./modal.css";

class Modal extends Component {
  DBLOCK = "d-block";
  ID_MODAL = "modal-mobioh";

  constructor(props) {
    super(props);

    this.state = {
      ...props.state,
      show: props.show,
    };

    this.closed = props.closed || false;
  }

  componentDidMount() {
    Utils.pararScroolTelaPrincipal();
  }

  UNSAFE_componentWillReceiveProps(props) {
    Utils.pararScroolTelaPrincipal();
    this.setState({ show: props.show });
  }

  componentWillUnmount() {
    Utils.ativarScroolTelaPrincipal();
  }

  getClassModal = () => {
    let classe = "modal ";
    classe = classe.concat(this.state.show ? this.DBLOCK : "d-none");
    return classe;
  };

  onDismiss() {
    document.getElementById(this.ID_MODAL).classList.toggle(this.DBLOCK);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.closed === false;
  }

  componentDidUpdate(props, state) {
    if (!this.closed) {
      this.onDismiss();
    }
  }

  cancel(event) {
    this.onDismiss();
    this.componentWillUnmount();
    if (this.props.handlerCancel) {
      this.props.handlerCancel(event);
    }
  }

  propsCancel = () => {
    this.setState({ show: false }, () => this.cancel())
  }

  confirm(event) {
    this.onDismiss();
    this.props.handlerConfirm(event);
    this.setState({ show: false }, () => this.cancel())
  }

  render() {
    return (
      <Div className={this.getClassModal()} id={this.ID_MODAL}>
        <Div
          inline="center"
          className="modal-default vertical-center table-wrapper-scroll-y mx-auto"
          style={{ overflow: "auto", height: "100%" }}
          responsive={this.props.responsive ? this.props.responsive : "6"}
        >
          <Div className="modal-content" style={{ height: "97vh", overflow: "scroll" }}>
            <If and value1={Utils.isValueValid(this.props.title)}>
              <Div className="modal-header">
                <h5 className="modal-title">{this.props.title}</h5>
                <Button
                  className="close"
                  handlerClick={(e) =>
                    this.setState({ show: false }, () => this.cancel(e))
                  }
                >
                  <span aria-hidden="true">&times;</span>
                </Button>
              </Div>
            </If>
            <If and value1={!Utils.isValueValid(this.props.title)}>
              <Div inline="end">
                <Button
                  className="close m-2"
                  handlerClick={(e) =>
                    this.setState({ show: false }, () => this.cancel(e))
                  }
                >
                  <span aria-hidden="true">&times;</span>
                </Button>
              </Div>
            </If>
            <Div className="modal-body">
              { this.props.body(this.propsCancel) || this.props.children}
            </Div>
            <If and value1={!Utils.isValueValid(this.props.hide)}>
              <Div className="modal-footer ">
                <Button
                  handlerClick={(e) => this.setState({ show: false }, () => this.cancel(e))}
                  value="Cancelar"
                  btn="secondary"
                />
                <Button
                  handlerClick={(e) => this.confirm(e)}
                  value="Confirmar"
                  btn="primary"
                />
              </Div>
            </If>
          </Div>
        </Div>
      </Div>
    );
  }
}

export default Modal;
