import React, { Component, useEffect, useState } from "react";
import { EmpresaUtils, ProdutoUtils } from "..";
import { Div, I, If, Image, Label, ModalNotification } from "../../components";
import { Color, Mask, Type, Utils } from "../../utilities";
import CarrinhoUtils from "../utils/CarrinhoUtils";
import LazyLoad from "react-lazyload";


import "./produtos.css";
import ProdutoDetalhe from "./web/ProdutoDetalhe";
import { useSelector } from "react-redux";

const BASE = Utils.isViewMobileLarguraPequeno() ? 2.9 : 3.5;
const WIDTH = window.innerWidth / BASE;
const WIDTH_TABLET = window.innerWidth / 6;
const HEIGHT = 95;

const getWidth = () => {
  return Utils.isViewTabletGrande()
    ? window.innerWidth / 8
    : Utils.isViewTablet() || Utils.isViewMobileGrande()
      ? WIDTH_TABLET
      : WIDTH;
};

const styles = {
  descricao: {
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: "12px",
    lineHeight: "12px",
    height: 25,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  precoUnitario: {
    marginLeft: 10,
    color: Color.ECOMMERCE.LARANJA,
    width: 100,
    borderRadius: 5,
    lineHeight: "5px",
    fontSize: 10,
    textDecoration: "line-through",
  },
  precoVenda: {
    marginLeft: 10,
    borderRadius: 5,
    lineHeight: "5px",
    color: Color.ECOMMERCE.LARANJA,
    width: 100,
    fontSize: 12,
  },
  precoVendaIndisponivel: {
    textDecoration: "line-through",
  },
  precoAtacado: {
    fontWeight: "bold",
    color: "#46160E",
    fontSize: 10,
    marginLeft: 10,
  },
  label: {
    marginLeft: 10,
    marginTop: 100,
    color: Color.ECOMMERCE.LARANJA,
    width: getWidth(),
    fontWeight: "bold",
  },
  containerPercentualEconomia: {
    backgroundColor: "#31CDFF",
    color: "white",
    borderRadius: 50,
    lineHeight: "8px",
    paddingLeft: 5,
    paddingRight: 5,
    position: "absolute",
    textAlign: "center",
  },
};

export function ItemProdutoMobile(props) {

  const [quantidade, setQuantidade] = useState(0)
  const [imagemDefault, setImagemDefault] = useState(null)
  const [indisponivel] = useState(false)
  
  const carrinho = useSelector(state => state)

  useEffect(() => {
    let itens = carrinho.carrinhoState.carrinho.itens || []

    let produtoEncontrado = itens.filter(
      (p) => p.idProdutoWeb === props.idProdutoWeb
    )[0];

    if (Utils.isNotNull(produtoEncontrado)) {
      setQuantidade(produtoEncontrado.quantidade)
    } else {
      setQuantidade(0)
    }
  }, [carrinho])

  function renderProdutoIndisponivel() {
    return (
      <Div
        className={
          indisponivel
            ? "view-opaca-item-mobile-indisponivel"
            : "view-opaca-item-mobile"
        }
        style={{
          width: getWidth(),
          borderRadius: 10,
          height: HEIGHT,
        }}
      >
        <Div
          style={{
            fontSize: 11,
            transform: `translateY(${HEIGHT / 2.5}px)`,
          }}
          column
        >
          <Label
            value="Indisponível"
            style={{ color: Color.ECOMMERCE.WHITE, textAlign: "center" }}
            family="Bold"
            pointer
          />
        </Div>
      </Div>
    );
  }

  function getQuantidadeLabel() {
    //let quantidade = quantidade || 0;

    let labelQuantidade = `${quantidade} ${props.possuiQuantidadeFracionada ? "g" : "un"}`;

    return quantidade > 0 ? labelQuantidade : "0";
  }

  const addQuantidadeItem = () => {
    props.handlerAddItemCarrinho(true, quantidade, p => {
      setQuantidade(p.quantidade)
    })
  }

  const removeQuantidadeItem = () => {
    props.handlerAddItemCarrinho(false, quantidade, p => {
      setQuantidade(p.quantidade)
    })
  }


  const visualizarProduto = (produto) => {
    ModalNotification.render(
      "Informações do produto",
      e =>
        <ProdutoDetalhe
          produto={produto}
          addQuantidadeItem={addQuantidadeItem}
          removeQuantidadeItem={removeQuantidadeItem}
          qdt={quantidade}
          closeModal={e}
          history={props.history}
          empresa={props.empresa}
        />
    );
  };

  const {
    precoAtacado,
    precoUnitario,
    precoVenda,
    descricao,
    disponivel,
    percentualEconomia,
    quantidadeMinimaAtacado,
    precosConcorrentes,
    empresa,
    proibidaVenda,
    produto,
    image
  } = props;


  let corPrimaria = EmpresaUtils.getCorPrimaria(empresa);

  return (
    <Div
      style={{ width: getWidth(), margin: 5 }}
      rounded
      // handleMouseEnter={() =>
      //   this.setState({ show: true }, () =>
      //     this.consultarProdutoCarrinho(codigoBarras)
      //   )
      // }
      // handleMouseLeave={() => this.setState({ show: false })}
    >
      <Div style={{ margin: 5 }} inline="center" padding="2" rounded>
        <If and value1={quantidade > 0}>
          <Label
            style={{
              position: "absolute",
              transform: `translateY(15px)`,
              backgroundColor: Color.EMPRESA.TERCIARIA,
              color: "white",
              padding: 2,
              width: 40,
              fontSize: 10,
              textAlign: "center",
              borderRadius: 5,
            }}
            value={getQuantidadeLabel()}
          />
        </If>
        <If and value1={!disponivel}>
          {renderProdutoIndisponivel()}
        </If>
        <LazyLoad>
          <img
            src={imagemDefault || image}
            className="border-bottom"
            alt={`Imagem do produto ${descricao}`}
            onClick={() => visualizarProduto(produto)}
            style={{
              width: getWidth(),
              height: HEIGHT,
            }}
            onError={() =>
              setImagemDefault(ProdutoUtils.URL_IMAGEM_PRODUTO_DEFAULT)
            }
          />
        </LazyLoad>

        <Div style={{ width: "100%" }} inline="between" marginTop="1">
          <I
            icon={Type.ICON.MINUS}
            style={{
              color: corPrimaria,
              fontSize: Utils.isViewTabletAll() ? 30 : 20,
            }}
            pointer
            handlerClick={() => {
              removeQuantidadeItem(quantidade)
            }}
          />

          <I
            icon={Type.ICON.PLUS}
            style={{
              color: corPrimaria,
              fontSize: Utils.isViewTabletAll() ? 30 : 20,
            }}
            pointer
            handlerClick={() => {
              addQuantidadeItem(quantidade)
            }}
          />
        </Div>
      </Div>
      <Div>
        <If and value1={Utils.isValueValid(descricao)}>
          <Label
            value={Utils.capitalize(String(descricao).slice(0, 50))}
            style={styles.descricao}
            family="Light"
          />
        </If>
        <Div className="d-flex flex-column align-self-start">
          <If and value1={Number(percentualEconomia) > 0}>
            <Label
              value={Mask.formatValueBr(precoUnitario)}
              style={styles.precoUnitario}
              family="SemiBold"
            />
          </If>
          <If
            and
            value1={Utils.isValueValid(precoVenda)}
            value2={!proibidaVenda}
          >
            <Label
              value={Mask.formatValueBr(precoVenda)}
              style={
                indisponivel
                  ? {
                    ...styles.precoVenda,
                    ...styles.precoVendaIndisponivel,
                    color: corPrimaria,
                  }
                  : { ...styles.precoVenda, color: corPrimaria }
              }
              family="SemiBold"
            />
          </If>
        </Div>
        <If
          and
          value1={Utils.isValueValid(precoAtacado)}
          value2={precoAtacado > 0}
        >
          <Label
            value={`${Mask.formatValueBr(
              precoAtacado
            )} à partir de ${quantidadeMinimaAtacado} unidades`}
            style={styles.precoAtacado}
            family="SemiBold"
          />
        </If>
        {Utils.isValueValid(precosConcorrentes) && (
          <Div
            style={{
              borderRadius: 10,
              backgroundColor: "#f2f2f2",
              paddingLeft: 3,
              paddingRight: 3,
              margin: 5,
            }}
            column
          >
            <Div>
              {Array.from(precosConcorrentes).map((concorrente, index) => {
                return (
                  <Div
                    inline="between"
                    key={index}
                    style={{
                      color: Color.ECOMMERCE.CINZA_ESCURO,
                      padding: 1,
                    }}
                  >
                    <Div inline="start">
                      <Image src={concorrente.urlFoto} width="15px" />
                      <Label
                        value={Mask.formatValueBr(concorrente.precoVenda)}
                        family="SemiBold"
                        marginLeft="1"
                        marginTop="3"
                        style={{
                          fontSize: 8,
                        }}
                      />
                    </Div>
                  </Div>
                );
              })}
              <Div
                style={{
                  color: "#31CDFF",
                }}
                column
              >
                <Label
                  value="Economize"
                  family="SemiBold"
                  style={{ color: "#31CDFF", fontSize: 12 }}
                />

                <Label
                  value={`${Array.from(precosConcorrentes)[0].percentualEconomia
                    }%`}
                  style={{ fontSize: 15 }}
                  family="Bold"
                />
              </Div>
            </Div>
          </Div>
        )}
      </Div>
    </Div>
  );
}