import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AppDashboard from '../AppDashboard';

function isLogin() {
  if (sessionStorage.getItem("auth")) {
    return true
  }
  return false;
}

function getRoutePrivate(path, component, title) {
  if (sessionStorage.getItem("auth")) {
    return (
      <Route
        exact
        path={path}
        render={(props) => {
          return (
            <AppDashboard {...props} title={title}>
              {component}
            </AppDashboard>
          );
        }}
      />
    )
  }
  return (<Redirect to="/admin/login" />);
}


const PrivateRoute = ({ Component, ...rest }) => {
  return (

    isLogin() ? <Route {...rest} render={props => {
      return (
        <AppDashboard {...props}>
          <Component history={props.history} />
        </AppDashboard>
      );
    }

    } /> : <Redirect to="/admin/login" />
  );
};

export default PrivateRoute;