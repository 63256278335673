import React, { Component } from "react";
import {
  Checkbox,
  Div,
  I,
  If,
  Input,
  Label,
  MessageSpinner,
} from "../../../components";
import { Color, EndPoints, Http, Type, Utils } from "../../../utilities";

import "../produtos.css";

import { ProdutoUtils } from "../..";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  atualizarCarrinho,
  setProdutos,
  setParametrosPedido,
} from "../../../redux/actions";
import CarouselBannerGenerico from "../../../components/carousel/CarouselBannerGenerico";
import ContatoWhatsapp from "../../ContatoWhatsapp";
import CarrinhoUtils from "../../utils/CarrinhoUtils";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import TagManagerUtils from "../../../utilities/utils/TagManagerUtils";
import ItemProduto from "../../ItemProduto";

const styles = {
  title: {
    color: "#878787",
    padding: "20px 0px",
    //  borderBottom: "1px solid rgb(196, 196, 196)",
    marginBottom: "15px",
    width: "100%",
  },
  verMais: {
    display: "flex",
    color: "blue",
    width: "100%",
    margin: "3px",
    cursor: "pointer",
    fontWeight: "600"
  },
  labelFiltros: {
    color: Color.EMPRESA.SECUNDARIA,
    paddingTop: 12,
    paddingLeft: 10,
  },
  labelResultado: {
    color: Color.EMPRESA.SECUNDARIA,
    fontSize: 15,
  },
  labelPrecoActive: {
    border: "2px solid #E94E25",
    width: "100%",
    margin: 3,
  },
  labelPreco: {
    width: "100%",
    margin: 3,
  },
  containerVerMais: {
    backgroundColor: Color.ECOMMERCE.LARANJA,
    color: Color.ECOMMERCE.WHITE,
    padding: 10,
    fontSize: 20,
    width: "100%",
    textAlign: "center",
    zIndex: 1000,
  },
  input: {
    width: "100%",
    height: 30,
    border: "none",
    fontSize: 25,
    paddingLeft: 40,
    backgroundColor: "transparent",
    borderBottom: "1px solid #C4C4C4",
  },
};

class ProdutosPorDepartamento extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: [],
      produtosFiltrados: [],
      parametros: {},
      filtros: [],
      pagina: 1,
      sizePagina: 100,
      verMais: true,
      pesquisando: false,
      consulta: false,
      banners: [],
      textoPesquisa: "",
      idTop: Utils.uuidString(),
      departamento: {
        descricao: "departamento",
        chavePesquisa: this.props.location.pathname.replace("/produto/departamento/", ""),
        tipo: "DESTAQUES",
      },
      isSecao: this.props.location.pathname.includes("/produto/secao/"),
      isMarca: this.props.location.pathname.includes("/produto/marca/"),
      empresa: this.props.empresa,
      mostrarCategoria: true,
      mostrarMarca: true,
      mostrarPers: true,
      vejaMaisCategoria: true,
      vejaMaisMarca: true,
      vejaMaisPers: true,
      ...props.location.state,
    };

    this.adicionarItemNoCarrinho = this.adicionarItemNoCarrinho.bind(this);
  }

  componentDidMount() {
    this.setState({ pesquisando: true }, () => {
      this.listar();
    });
  }

  async listar() {
    this.listarBannersPorDepartamento();
    this.listarProdutos();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.scrollTop();

    let state = nextProps.location.state;

    if (!state) {

      state = this.state;
      let chavePesquisa = this.props.location.pathname.replace("/produto/departamento/", "")

      if (this.state.isSecao) {
        state.departamento.tipo = "SECAO"
        chavePesquisa = this.props.location.pathname.replace("/produto/secao/", "")
      }

      if (this.state.isMarca) {
        state.departamento.tipo = "MARCA"
        chavePesquisa = this.props.location.pathname.replace("/produto/marca/", "");
      }

      state.departamento.chavePesquisa = chavePesquisa
    }

    this.setState(
      {
        departamento: state.departamento,
        destaque: state.departamento.chavePesquisa === "DESTAQUES",
        empresa: state.empresa,
        pagina: 1,
        pesquisando: true,
        consulta: true,
        textoPesquisa: "",
        produtosFiltrados: [],
        produtos: [],
        filtros: [],
        banners: [],
      },
      () => {
        this.listarProdutos();
        this.listarBannersPorDepartamento();
      }
    );
  }


  scrollTop() {
    window.scrollTo(0, 0);
  }

  getFiltrosMapeados() {
    const { filtros } = this.state;

    let templates = [];

    let filtroSecao = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "SECAO"
    )[0];
    let filtroMarca = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "MARCA"
    )[0];

    let filtroPersonalizado = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "PERSONALIZADO"
    )[0];

    if (Utils.isValueValid(filtroSecao) && !this.state.destaque) {
      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label
            pointer
            value="Categorias"
            family="SemiBold"
            style={styles.title}
            handlerClick={() => {
              this.setState({
                mostrarCategoria: !this.state.mostrarCategoria
              })
            }}
          />
          <I
            pointer
            icon={this.state.mostrarCategoria ? Type.ICON.ARROW_CHEV_UP : Type.ICON.ARROW_CHEV_DOWN}
            style={{
              color: Color.ECOMMERCE.CINZA,
              fontSize: 14,
              position: "absolute",
              right: "29px",
              marginTop: "23px",
            }}
            handlerClick={() => {
              this.setState({
                mostrarCategoria: !this.state.mostrarCategoria
              })
            }}
          />
          <Div style={{ display: this.state.mostrarCategoria ? "block" : "none" }}>
            {Utils.sort(filtroSecao.itens, "descricao").map((item, index) => {
              if (this.state.vejaMaisCategoria && index >= 5) {
                return
              } else {
                return (
                  <Checkbox
                    danger
                    label={`${item.descricao} (${item.quantidadeEncontrada}/${item.quantidadeTotal})`}
                    key={index}
                    handlerChange={({ checked }) => {
                      item.active = checked;
                      this.setState({ filtros, pagina: 1 }, () => {
                        if (
                          item.quantidadeEncontrada === item.quantidadeTotal &&
                          checked
                        ) {
                          this.aplicarFiltrosNaTela();
                        } else {
                          this.listarProdutos();
                        }
                      });
                    }}
                    checked={item.active}
                  />
                );
              }
            })}
          </Div>
          <If and value1={this.state.vejaMaisCategoria && filtroSecao.itens.length >= 6}>
            <Div
              style={styles.verMais}
              handlerClick={() => {
                this.setState({
                  vejaMaisCategoria: false
                })
              }}
            >
              <Label pointer value="Ver Todos" />
            </Div>
          </If>
        </Div>
      );
    }

    if (Utils.isValueValid(filtroMarca)) {
      let itensMarca = filtroMarca.itens;

      if (this.state.destaque) {
        let marcas = Array.from(this.state.produtos).map((p) => p.marca);
        itensMarca = Array.from(itensMarca).filter((i) =>
          marcas.includes(i.idFiltro)
        );
      }

      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label
            pointer
            value="Marca"
            family="SemiBold"
            style={styles.title}
            handlerClick={() => {
              this.setState({
                mostrarMarca: !this.state.mostrarMarca
              })
            }}
          />
          <I
            pointer
            icon={this.state.mostrarMarca ? Type.ICON.ARROW_CHEV_UP : Type.ICON.ARROW_CHEV_DOWN}
            style={{
              color: Color.ECOMMERCE.CINZA,
              fontSize: 14,
              position: "absolute",
              right: "29px",
              marginTop: "23px",
            }}
            handlerClick={() => {
              this.setState({
                mostrarMarca: !this.state.mostrarMarca
              })
            }}
          />
          <Div style={{ display: this.state.mostrarMarca ? "block" : "none" }}>
            {Utils.sort(itensMarca, "descricao").map((item, index) => {
              if (this.state.vejaMaisMarca && index >= 5) {
                return
              } else {
                return (
                  <Checkbox
                    danger
                    label={`${item.descricao} (${item.quantidadeEncontrada}/${item.quantidadeTotal})`}
                    key={index}
                    handlerChange={({ checked }) => {
                      item.active = checked;
                      this.setState({ filtros, pagina: 1 }, () => {
                        if (
                          item.quantidadeEncontrada === item.quantidadeTotal &&
                          checked
                        ) {
                          this.aplicarFiltrosNaTela();
                        } else {
                          this.listarProdutos();
                        }
                      });
                    }}
                    checked={item.active}
                  />
                );
              }
            })}
          </Div>
          <If and value1={this.state.vejaMaisMarca && filtroMarca.itens.length >= 6}>
            <Div
              style={styles.verMais}
              handlerClick={() => {
                this.setState({
                  vejaMaisMarca: false
                })
              }}
            >
              <Label pointer value="Ver Todos" />
            </Div>
          </If>
        </Div>
      );
    }

    if (Utils.isValueValid(filtroPersonalizado)) {
      let itensPers = filtroPersonalizado.itens;

      if (this.state.destaque) {
        let personalizados = Array.from(this.state.produtos).map((p) => p.personalizados);
        itensPers = Array.from(itensPers).filter((i) =>
          personalizados.includes(i.idFiltro)
        );
      }


      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label
            pointer
            value={filtroPersonalizado.descricao}
            family="SemiBold"
            style={styles.title}
            handlerClick={() => {
              this.setState({
                mostrarPers: !this.state.mostrarPers
              })
            }}
          />
          <I
            pointer
            icon={this.state.mostrarPers ? Type.ICON.ARROW_CHEV_UP : Type.ICON.ARROW_CHEV_DOWN}
            style={{
              color: Color.ECOMMERCE.CINZA,
              fontSize: 14,
              position: "absolute",
              right: "29px",
              marginTop: "23px",
            }}
            handlerClick={() => {
              this.setState({
                mostrarPers: !this.state.mostrarPers
              })
            }}
          />
          <Div style={{ display: this.state.mostrarPers ? "block" : "none" }}>
            {itensPers.map((item, index) => {
              if (this.state.vejaMaisPers && index >= 5) {
                return
              } else {
                return (
                  <Checkbox
                    title={item.descricao}
                    danger
                    label={
                      `${item.descricao.substring(0, 26)}${item.descricao.length > 26 ? "..." : ""} (${item.quantidadeEncontrada}/${item.quantidadeTotal})`
                    }
                    key={index}
                    handlerChange={({ checked }) => {
                      item.active = checked;
                      this.setState({ filtros, pagina: 1 }, () => {
                        if (
                          item.quantidadeEncontrada === item.quantidadeTotal &&
                          checked
                        ) {
                          this.aplicarFiltrosNaTela();
                        } else {
                          this.listarProdutos();
                        }
                      });
                    }}
                    checked={item.active}
                  />
                );
              }
            })}
          </Div>
          <If and value1={this.state.vejaMaisPers && filtroPersonalizado.itens.length >= 6}>
            <Div
              style={styles.verMais}
              handlerClick={() => {
                this.setState({
                  vejaMaisPers: false
                })
              }}
            >
              <Label pointer value="Ver Todos" />
            </Div>
          </If>
        </Div>
      );
    }

    return templates;
  }

  visualizarProdutosAnuncio({
    titulo,
    idAnuncioOferta,
    idDepartamentoSecaoFiltro,
    tipoDepartamentoSecao,
    marcaFiltro,
    possuiProdutos,
    estatico,
  }) {
    if (!estatico) {
      this.props.history.push("/anuncio/produtos", {
        titulo,
        idAnuncioOferta,
        idDepartamentoSecaoFiltro,
        tipoDepartamentoSecao,
        marcaFiltro,
        possuiProdutos,
        empresa: this.state.empresa,
      });
    }
  }

  getUrlListarBanners(departamento) {
    if (departamento.tipo === "DESTAQUES") {
      return EndPoints.ANUNCIO_DESTAQUE;
    } else if (departamento.tipo === "SECAO") {
      return EndPoints.ANUNCIO_DESTAQUE;
    } if (departamento.tipo === "MARCA") {
      return EndPoints.ANUNCIO_DESTAQUE;
    } {
      return EndPoints.BANNERS_POR_DEPARTAMENTO.replace(
        "%s",
        departamento.chavePesquisa
      );
    }
  }

  listarBannersPorDepartamento() {
    let departamento = this.state.departamento;
    Http.get(this.getUrlListarBanners(departamento))
      .then(({ status, data }) => {
        if (status) {
          this.setState({
            banners: data,
          });
        } else {
          this.setState({ banners: [] });
        }
      })
      .catch((erro) => console.log(erro));
  }

  getFiltrosPaginados(filtrosNovaPagina) {
    let filtros = Array.from(this.state.filtros);

    if (Utils.isValueValid(filtrosNovaPagina)) {
      Array.from(filtrosNovaPagina).forEach((filtro) => {
        let filtroPorTipo = filtros.filter((f) => f.tipo === filtro.tipo)[0];
        if (Utils.isValueValid(filtroPorTipo)) {
          let itens = Array.from(filtroPorTipo.itens);
          let itensNovaPagina = Array.from(filtro.itens);

          if (Utils.isValueValid(itensNovaPagina)) {
            itensNovaPagina.forEach((item) => {
              let filtroJaAdicionado = itens.filter(
                (i) => i.idFiltro === item.idFiltro
              )[0];
              if (!Utils.isValueValid(filtroJaAdicionado)) {
                itens.push(item);
              } else {
                if (this.state.pagina > 1) {
                  filtroJaAdicionado.quantidadeEncontrada =
                    Number(filtroJaAdicionado.quantidadeEncontrada) +
                    Number(item.quantidadeEncontrada);
                } else {
                  filtroJaAdicionado.quantidadeEncontrada = Number(
                    item.quantidadeEncontrada
                  );
                }
              }
            });
          }

          if (filtro.tipo === "MARCA") {
            filtros[filtros.indexOf(filtroPorTipo)] = filtro;
          }

          if (filtro.tipo === "PERSONALIZADO") {
            filtros[filtros.indexOf(filtroPorTipo)] = filtro;
          }
        } else {
          filtros.push(filtro);
        }
      });
    }

    return filtros;
  }

  getParamListarProdutos(carregarMais) {
    const { pagina, departamento, textoPesquisa } = this.state;

    let param = {
      numeroPagina: pagina,
      descricao: textoPesquisa,
      departamentos: [],
      secoes: [],
      marcas: [],
      personalizados: [],
      destaque: false
    };

    let tipo = departamento.tipo

    if (tipo === "DEPARTAMENTO") {
      param.departamentos.push({ idFiltro: departamento.chavePesquisa });
    } else if (tipo === "SECAO") {
      param.secoes.push({ idFiltro: departamento.chavePesquisa });
    } else if (tipo === "MARCA") {
      param.marcas.push({ idFiltro: departamento.chavePesquisa });
    } else if (tipo === "PERSONALIZADO") {
      param.personalizados.push(departamento.chavePesquisa)
      param.destaque = false;
    } else if (tipo === "DESTAQUES") {
      param.destaque = true;
    }

    this.setFiltrosSelecionados(param);

    if (
      Utils.isValueValid(param.secoes) &&
      Utils.isValueValid(param.marcas) &&
      !carregarMais
    ) {
      param.numeroPagina = 0;
    }

    if (this.state.destaque) {
      param.destaque = this.state.destaque;
    }

    return param;
  }

  listarProdutos(carregarMais = false) {
    let param = this.getParamListarProdutos(carregarMais);

    Http.post(EndPoints.PRODUTO_BUSCA_FILTRO, param)
      .then(({ status, data }) => {
        if (status && Utils.isValueValid(data.listaProdutos)) {
          if (this.state.pesquisando) {
            TagManagerUtils.vizualizouListadeItens(data.listaProdutos)
          }
          if (this.state.pagina > 1) {
            this.setState(
              {
                produtos: [...this.state.produtos, ...data.listaProdutos],
                filtros: this.getFiltrosPaginados(data.filtros),
                verMais: true,
                pesquisando: false,
                departamento: {
                  ...this.state.departamento,
                  descricao:  data.filtros[0].itens[0].descricao
                }
              },
              () => this.aplicarFiltros(param)
            );

            if (!this.state.isMarca && !this.state.isSecao) {
              window.history.replaceState(null, "", "/produto/departamento/" + data.filtros[0].itens[0].descricao)
            }

          } else {
            this.setState(
              {
                produtos: data.listaProdutos,
                filtros: this.getFiltrosPaginados(data.filtros),
                verMais: true,
                pesquisando: false,
                departamento: {
                  ...this.state.departamento,
                  descricao: data.filtros[0].itens[0].descricao
                }
              },
              () => this.aplicarFiltros(param)
            );

            if (!this.state.isMarca && !this.state.isSecao) {
              window.history.replaceState(null, "", "/produto/departamento/" + data.filtros[0].itens[0].descricao)
            }
          }
        } else {
          if (this.state.pagina === 1) {
            this.setState({
              produtos: [],
              produtosFiltrados: [],
              // filtros: [],
              verMais: false,
              pesquisando: false,
            });
          } else {
            this.setState({ verMais: false, pesquisando: false });
          }
        }
      })
      .catch((erro) => console.log(erro));
  }

  limparFiltros() {
    let filtros = this.state.filtros;
    Array.from(filtros).forEach((filtro) => {
      Array.from(filtro.itens).map((item) => (item.active = false));
    });

    this.setState({ filtros, textoPesquisa: "" }, () => this.listarProdutos());
  }

  setFiltrosSelecionados(param) {
    let secoes = [...param.secoes];
    let marcas = [...param.marcas];
    let personalizados = [...param.personalizados];

    if (Utils.isValueValid(this.state.filtros)) {
      let filtroSecao = Array.from(this.state.filtros).filter(
        (filtro) => filtro.tipo === "SECAO"
      )[0];

      let itensSecaoMarcados = Array.from(filtroSecao.itens).filter(
        (item) => item.active
      );

      let filtroMarca = Array.from(this.state.filtros).filter(
        (filtro) => filtro.tipo === "MARCA"
      )[0];

      let itensMarcaMarcados = Array.from(filtroMarca.itens).filter(
        (item) => item.active
      );

      let filtroPersonalizado = Array.from(this.state.filtros).filter(
        (filtro) => filtro.tipo === "PERSONALIZADO"
      )[0];

      let itensPersonalizadoMarcados = Array.from(filtroPersonalizado.itens).filter(
        (item) => item.active
      );

      if (Utils.isValueValid(itensSecaoMarcados)) {
        param.destaque = false
        itensSecaoMarcados
          .map((item) => item.idFiltro)
          .forEach((secao) => {
            secoes.push({ idFiltro: secao });
          });
      }

      if (Utils.isValueValid(itensMarcaMarcados)) {
        param.destaque = false
        itensMarcaMarcados
          .map((item) => item.idFiltro)
          .forEach((marca) => {
            marcas.push({ idFiltro: marca });
          });
      }

      if (Utils.isValueValid(itensPersonalizadoMarcados)) {
        param.destaque = false
        itensPersonalizadoMarcados
          .map((item) => item.idFiltro)
          .forEach((personalizado) => {
            personalizados.push({ idFiltro: personalizado });
          });
      }
    }

    param.secoes = secoes;
    param.marcas = marcas;
    param.personalizados = personalizados;
  }

  aplicarFiltros(params) {
    let produtos = JSON.parse(JSON.stringify(this.state.produtos));

    let filtros = Array.from(this.state.filtros);

    if (Utils.isValueValid(params)) {
      Array.from(params.secoes).forEach((secao) => {
        let filtroSecao = filtros.filter((f) => f.tipo === "SECAO")[0];
        if (Utils.isValueValid(filtroSecao)) {
          let filtroSelecionado = Array.from(filtroSecao.itens).filter(
            (f) => String(f.idFiltro) === String(secao.idFiltro)
          )[0];

          if (Utils.isValueValid(filtroSelecionado)) {
            filtroSelecionado.active = true;
          }
        }
      });
      Array.from(params.marcas).forEach((marca) => {
        let filtroMarca = filtros.filter((f) => f.tipo === "MARCA")[0];
        if (Utils.isValueValid(filtroMarca)) {
          let filtroSelecionado = Array.from(filtroMarca.itens).filter(
            (f) => String(f.idFiltro) === String(marca.idFiltro)
          )[0];

          if (Utils.isValueValid(filtroSelecionado)) {
            filtroSelecionado.active = true;
          }
        }
      });

      Array.from(params.personalizados).forEach((personalizado) => {
        let filtroPersonalizado = filtros.filter((f) => f.tipo === "PERSONALIZADO")[0];

        if (Utils.isValueValid(filtroPersonalizado)) {

          let filtroSelecionado = Array.from(filtroPersonalizado.itens).filter(
            (f) => String(f.idFiltro) === String(personalizado.idFiltro)
          )[0];

          if (Utils.isValueValid(filtroSelecionado)) {
            filtroSelecionado.active = true;
          }
        }
      });
    }

    if (Utils.isValueValid(filtros)) {
      let filtroPreco = filtros.filter((filtro) => filtro.tipo === "PRECO")[0];

      let itensPrecoMarcados = Array.from(filtroPreco.itens).filter(
        (item) => item.active
      );

      let produtosFiltrados = [];

      if (!Utils.isValueValid(produtosFiltrados)) {
        produtosFiltrados = produtos;
      }

      if (Utils.isValueValid(itensPrecoMarcados)) {
        let filtros = itensPrecoMarcados.map((item) => item.idFiltro);

        let produtosExcluir = [];

        if (Utils.isValueValid(filtros)) {
          produtosFiltrados.forEach((produto) => {
            let filtroProduto = produto.filtro;
            if (!filtros.includes(filtroProduto.preco)) {
              produtosExcluir.push(produto);
            }
          });

          produtosExcluir.forEach((produto) => {
            produtosFiltrados.splice(produtosFiltrados.indexOf(produto), 1);
          });
        }
      }

      this.setState({
        produtosFiltrados,
        filtros,
      });
    } else {
      this.setState({ produtosFiltrados: produtos });
    }
  }

  aplicarFiltrosNaTela() {
    let produtos = JSON.parse(JSON.stringify(this.state.produtos));

    let filtroPreco = Array.from(this.state.filtros).filter(
      (filtro) => filtro.tipo === "PRECO"
    )[0];

    let itensPrecoMarcados = [];
    let itensSecaoMarcados = [];
    let itensMarcaMarcados = [];
    let itensPersonalizadosMarcados = [];

    if (Utils.isValueValid(filtroPreco)) {
      itensPrecoMarcados = Array.from(filtroPreco.itens).filter(
        (item) => item.active
      );
    }

    let filtroSecao = Array.from(this.state.filtros).filter(
      (filtro) => filtro.tipo === "SECAO"
    )[0];

    if (Utils.isValueValid(filtroSecao)) {
      itensSecaoMarcados = Array.from(filtroSecao.itens).filter(
        (item) => item.active
      );
    }

    let filtroMarca = Array.from(this.state.filtros).filter(
      (filtro) => filtro.tipo === "MARCA"
    )[0];

    if (Utils.isValueValid(filtroMarca)) {
      itensMarcaMarcados = Array.from(filtroMarca.itens).filter(
        (item) => item.active
      );
    }

    let filtroPersonalizado = Array.from(this.state.filtros).filter(
      (filtro) => filtro.tipo === "PERSONALIZADO"
    )[0];

    if (Utils.isValueValid(filtroPersonalizado)) {
      itensPersonalizadosMarcados = Array.from(filtroPersonalizado.itens).filter(
        (item) => item.active
      );
    }

    let produtosFiltrados = [];

    if (Utils.isValueValid(itensSecaoMarcados)) {
      let filtros = itensSecaoMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (filtros.includes(filtroProduto.secao)) {
            produtosFiltrados.push(produto);
          }
        });
      }
    }

    if (Utils.isValueValid(itensMarcaMarcados)) {
      produtosFiltrados = [];
      let filtros = itensMarcaMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (filtros.includes(filtroProduto.marca)) {
            produtosFiltrados.push(produto);
          }
        });
      }
    }

    if (Utils.isValueValid(itensPersonalizadosMarcados)) {
      produtosFiltrados = [];
      let filtros = itensPersonalizadosMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;

          if (filtroProduto.personalizados.includes(filtros[0].toLowerCase())) {
            produtosFiltrados.push(produto);
          }
        });
      }
    }

    if (!Utils.isValueValid(produtosFiltrados)) {
      produtosFiltrados = produtos;
    }

    if (Utils.isValueValid(itensPrecoMarcados)) {
      let filtros = itensPrecoMarcados.map((item) => item.idFiltro);

      let produtosExcluir = [];

      if (Utils.isValueValid(filtros)) {
        produtosFiltrados.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (!filtros.includes(filtroProduto.preco)) {
            produtosExcluir.push(produto);
          }
        });

        produtosExcluir.forEach((produto) => {
          produtosFiltrados.splice(produtosFiltrados.indexOf(produto), 1);
        });
      }
    }

    return produtosFiltrados;
  }

  addQuantidadeItem(item) {
    let quantidade = item.quantidade;

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade + Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade + 1;
    }

    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    this.props.handlerAddItem(item, this.atualizarProdutoDetalhe);
  }

  removeQuantidadeItem(item) {
    let quantidade = item.quantidade;

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade - Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade - 1;
    }

    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    this.props.handlerAddItem(item, this.atualizarProdutoDetalhe);
  }

  renderProdutos(produtos) {
    return (
      <Div inline="start">
        {Utils.sort(produtos, "descricao").map((prod, index) => {
          return (
            <Div className="align-self-start" >
              <ItemProduto
                key={index}
                history={this.props.history}
                image={prod.urlImagemPrincipalThumbnail}
                descricao={prod.descricao}
                quantidade={prod.quantidade}
                disponivel={prod.disponivel}
                proibidaVenda={prod.proibidaVenda}
                produto={prod}
                idProdutoWeb={prod.idProdutoWeb}
                handlerAddItemCarrinho={(adicionar, quantidade, callback) => {
                  this.adicionarItemNoCarrinho(prod, quantidade, adicionar).then(() => {
                    if (callback) {
                      callback(prod)
                    }
                  });
                }}
                empresa={this.state.empresa}
              />
            </Div>
          );
        })}
      </Div>
    );
  }

  async adicionarItemNoCarrinho(produto, quantidade, adicionar) {

    let qdt = quantidade || 0

    if (adicionar) {
      qdt = quantidade ? quantidade + 1 : 1
    } else {
      if (produto.quantidade >= 1) {
        qdt = quantidade - 1
      }
    }

    let { carrinho, produtos } = this.props;
    let prod = ProdutoUtils.calcularValorProduto(produto, qdt);

    CarrinhoUtils.adicionarItemNoCarrinho(
      prod,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      null,
      this.props.parametros
    );
  }

  getItensBannerTopo() {
    const { banners } = this.state;
    let itens = [];

    let itensTopo = Array.from(banners || []);

    if (Utils.isValueValid(itensTopo)) {
      itensTopo.forEach((item) => {
        itens.push(
          <img
            src={item.urlFoto}
            style={{ width: "100%" }}
            className="cursor-pointer"
            onClick={() => this.visualizarProdutosAnuncio(item)}
          />
        );
      });
    }

    return itens;
  }

  getMsgNenhumProdutoEncontrado() {
    let textoPesquisa = this.state.textoPesquisa;
    let msgDefault =
      "Nenhum produto encontrado para o departamento / filtro selecionado";

    if (Utils.isValueValid(textoPesquisa)) {
      msgDefault = `Nenhum produto encontrado para o termo: '${textoPesquisa}'`;
    }

    return msgDefault;
  }

  getProdutosPorDepartamento = () => {
    let itensParaRenderizar = [];

    let produtosPorDepartamento = Utils.groupBy(
      this.state.produtosFiltrados,
      "descricaoSecao"
    );

    for (const item of produtosPorDepartamento) {
      let itens = [];

      let departamento = item[0];
      let produtos = Array.from(item[1]);

      let produtosSeparados = produtos;

      itensParaRenderizar.push({
        tamanho: produtosSeparados.length,
        produtos: produtosSeparados,
        departamento,
      });

      itensParaRenderizar = Utils.sortReverse(itensParaRenderizar, "tamanho");
    }

    return itensParaRenderizar;
  };

  render() {
    const { departamento, banners, textoPesquisa } = this.state;

    let produtosFiltrados = this.aplicarFiltrosNaTela();
    let corPrimaria = Color.EMPRESA.PRIMARIA

    return (
      <>
        <If and value1={Utils.isValueValid(banners)}>
          <CarouselBannerGenerico
            timeSlide={7000}
            itens={this.getItensBannerTopo()}
            slidesPerPage={1}
            dots
          />
        </If>
        <If and value1={true}>
          <Div
            responsive="3"
            className="align-self-start"
            style={{ marginBottom: 100, marginTop: 25 }}
          >
            <Div inline="center" marginTop="2">
              <Div inline="center" col="6">
                <I
                  pointer
                  icon={Type.ICON.FILTER_TASK}
                  style={{
                    color: Color.EMPRESA.SECUNDARIA,
                    fontSize: 20,
                  }}
                />
                <Label
                  value="FILTROS"
                  style={styles.labelFiltros}
                  family="SemiBold"
                />
              </Div>
              <Div
                inline="center"
                pointer
                handlerClick={() => this.limparFiltros()}
                rounded
                col="6"
                marginTop="2"
                style={{ color: Color.ECOMMERCE.LARANJA }}
              >
                <I
                  icon={Type.ICON.CLOSEX}
                  paddingBottom="1"
                  pointer
                  handlerClick={() => this.limparFiltros()}
                />
                <Label
                  value="Limpar filtros"
                  family="Light"
                  marginLeft="1"
                  marginTop="1"
                  pointer
                  handlerClick={() => this.limparFiltros()}
                />
              </Div>
            </Div>
            <Div marginTop="2" marginBottom="2">
              <Input
                handlerChange={(e) =>
                  this.setState({ textoPesquisa: e.target.value })
                }
                handlerKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    this.setState(
                      {
                        pagina: 1,
                      },
                      () => this.listarProdutos()
                    );
                  }
                }}
                handlerBlur={() => {
                  this.setState(
                    {
                      pagina: 1,
                    },
                    () => this.listarProdutos()
                  );
                }}
                name="textoPesquisa"
                value={textoPesquisa}
                placeholder="Pesquisar"
                style={styles.input}
              />
              <I
                icon={Type.ICON.SEARCH}
                style={{
                  position: "absolute",
                  left: 15,
                  fontSize: 25,
                  color: corPrimaria,
                }}
              />
            </Div>
            <Div

            >
              {this.getFiltrosMapeados().map((item, index) => (
                <Div
                  style={{
                  }}
                  key={index}
                >
                  {item}
                </Div>
              ))}
            </Div>
          </Div>
        </If>
        <Div
          responsive="9"
          className="align-self-start"
          style={{ paddingBottom: 100, marginTop: 25 }}
        >
          <Div
            className="d-flex align-self-start justify-content-start"
            style={{ marginTop: "60px" }}
          >
            <Div responsive="12">
              <Div inline="between">
                <Label
                  value={`Resultados da busca por: "${departamento.descricao
                    }" ${Utils.isValueValid(textoPesquisa)
                      ? `e termo "${textoPesquisa}"`
                      : ""
                    }`}
                  style={styles.labelResultado}
                  family="SemiBold"
                />
              </Div>

              <If
                and
                value1={!Utils.isValueValid(produtosFiltrados)}
                value2={!this.state.pesquisando}
              >
                <Div inline="center">
                  <I
                    icon={Type.ICON.INFO}
                    sizeIcon="3"
                    style={{ color: Color.ECOMMERCE.LARANJA }}
                    margin="2"
                  />
                  <Label
                    value={this.getMsgNenhumProdutoEncontrado()}
                    family="Light"
                    style={{ fontSize: 25 }}
                    margin="2"
                  />
                </Div>
              </If>
              <If and value1={this.state.pesquisando}>
                <Div inline="center" margin="2">
                  <MessageSpinner
                    style={{ backgroundColor: Color.EMPRESA.PRIMARIA }}
                    colorText={Color.NODE.WHITE}
                  >
                    <Label
                      value="Consultando produtos, aguarde"
                      family="Light"
                      style={{ fontSize: 30 }}
                      margin="2"
                    />
                  </MessageSpinner>
                </Div>
              </If>
              <If
                and
                //value1={Utils.isValueValid(produtosFiltrados)}
                value1={!this.state.pesquisando}
              >
                {this.renderProdutos(produtosFiltrados)}
              </If>
            </Div>
          </Div>
        </Div>
        <ContatoWhatsapp empresa={this.state.empresa} />
        <BottomScrollListener
          onBottom={() => {
            if (this.state.verMais) {
              this.setState({ pagina: this.state.pagina + 1 }, () =>
                this.listarProdutos(true)
              );
            }
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.listasDeCompraState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { atualizarCarrinho, setProdutos, setParametrosPedido },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProdutosPorDepartamento);
