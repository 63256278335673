import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Div, I, InputDefault, Label, Notification, Row, Title } from "../../components";
import DepartamentoService from "../../service/DepartamentoService";
import { Color, Type } from "../../utilities";

class NovoDepartamento extends Component {
  constructor(props) {
    super(props);

    this.state = {
      departamentos: [],
      secao: [],
      secoes: [],
      departamentoCustom: "",
      secaoCustom: "",
      secoesSelectedVincular: [],
    };
  }


  handleChange = (e) => {
    e.stopPropagation();
    let { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  adicionarSecao(nome) {
    const { secoes, } = this.state;

    if (nome.trim() !== "") {
      this.setState({
        secaoCustom: "",
        secoes: [
          ...secoes,
          { descricao: nome }
        ]
      })
    }

  }

  removerSecao(index) {
    const { secoes } = this.state;

    let newSecoes = []
    newSecoes = secoes.filter(s => s.descricao !== secoes[index].descricao)

    this.setState({
      secoes: [
        ...newSecoes
      ]
    })
  }

  getObjectDepartamento() {

    let newSecao = []
    this.state.secoes.forEach(secao => {
      newSecao.push(
        {
          idDepartamentoSecaoExterno: null,
          descricao: secao.descricao,
        }
      )
    })

    return {
      idDepartamentoSecaoExterno: null,
      descricao: this.state.departamentoCustom,
      secoes: newSecao
    }
  }

  validacao() {
    const { secoes, departamentoCustom } = this.state;
    let valido = true

    if (secoes.length < 1) {
      valido = false
      Notification.danger("Nenhuma Seção Confirmada!")
    }


    if (departamentoCustom.replace(/^\s+|\s+$/gm, '') === "") {
      valido = false
      Notification.danger("Escreva a descrição do Departamento")
    }

    return valido
  }

  confirmNovoDepartamento() {
    let novoDepartamento = this.getObjectDepartamento()

    if (this.validacao()) {
      DepartamentoService.adminCreateDepartamentoSec(novoDepartamento).then((data) => {
        if (data.status) {
          Notification.success(data.mensagem)
          this.props.closeComponente()
        } else {
          Notification.danger("Falha ao Criar Departamento/Seção")
        }
      })
    }

  }

  bodyNovoDepartamento() {
    const { departamentoCustom, secaoCustom, secoes } = this.state;
    return (
      <>
        <Row col="12" padding="0" >

          <Div col="9" padding="0">
            <InputDefault
              maxLength={30}
              responsive="12"
              label="Descrição do departamento"
              name="departamentoCustom"
              placeholder="Escreva uma Descrição para o Departamento"
              required
              handlerChange={(e) => this.handleChange(e)}
              value={departamentoCustom}
            />
          </Div>


        </Row>

        <Row col="12" padding="0" style={{ alignItems: "center" }}>
          <InputDefault
            maxLength={30}
            responsive="6"
            name="secaoCustom"
            placeholder="Escreva uma Descrição para a Seção"
            required
            handlerChange={(e) => this.handleChange(e)}
            value={secaoCustom}
          />
          <Div col="4" style={{ marginTop: "8px" }}>
            <Button
              handlerClick={() => this.adicionarSecao(secaoCustom)}
              value="Adicionar"
              btn="primary"
              marginRight="3"
            />
          </Div>
        </Row>

        <Div style={{ paddingLeft: "15px" }}>
          {
            secoes.map((secao, index) => {
              return (
                <>
                  <Label
                    className="border"
                    value={secao.descricao}
                    style={{
                      color: "#9e9e9e",
                      fontSize: "15px",
                      padding: "10px 30px 10px 17px",
                      marginRight: "10px"
                    }}
                  />
                  <I
                    pointer
                    icon={Type.ICON.CLOSEX}
                    style={{
                      color: Color.RGBA.CINZA,
                      fontSize: 21,
                      position: "absolute",
                      margin: "12px 0px 0px -36px"
                    }}
                    handlerClick={() => {
                      this.removerSecao(index)
                    }}
                  />
                </>
              )
            })
          }
        </Div>

        <Div style={{
          margin: "25px 15px",
          display: "flex",
          justifyContent: "end"
        }}>
          <Button
            handlerClick={(e) => this.confirmNovoDepartamento()}
            value="Confirmar"
            btn="primary"
            marginRight="3"
          />

          <Button
            handlerClick={
              (e) => this.setState({
                departamentoCustom: "",
                secaoCustom: "",
              },
                this.props.closeComponente()
              )
            }
            value="Cancelar"
            btn="secondary"
          />
        </Div>
      </>
    )
  }

  render() {

    return (
      <Div style={{ width: '100%', margin: "25px 10px 0px 0px" }} shadow className="border">
        <Title
          value="Novo Departamento Personalizado"
          type="h4"
          paddingLeft="3"
          paddingRight="4"
          paddingTop="3"
        />

        <Div
          col="12"
          padding="0"
          style={{
            margin: "35px 0px",
          }}
        >
          {this.bodyNovoDepartamento()}
        </Div>

      </Div>
    )
  }

}

const mapStateToProps = (store) => ({
  idEmpresa: store.loginState.user.idEmpresa,
  idFilial: store.loginState.user.idFilial,
  idFuncionario: store.loginState.user.idFuncionario
});

export default connect(mapStateToProps)(NovoDepartamento);
