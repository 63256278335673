import React from "react";
import { Div, Label, ModalNotification } from "../../components";
import { Keys, Utils } from "../../utilities";
//import ProdutoDetalheMobile from "./mobile/ProdutoDetalheMobile";

export default class ProdutoUtils {
  static URL_IMAGEM_PRODUTO_DEFAULT =
    "https://www.maiitre.com.br/imgs/geral/icones/imgindisponivel_thumb.webp";

  static eventChangeProduto = (data) => {
    return new CustomEvent("alterarQuantidadeProduto", {
      detail: { ...data },
    });
  };

  static visualizarProduto = (titulo, template) => {
    ModalNotification.render(titulo, template);
  };

  static validarEstoqueDisponivel = (produto, msg) => {
    let valido = Number(produto.estoqueDisponivel) > 0;

    return this.validar(valido, msg);
  };

  static validarPreco = (produto, msg) => {
    let valido = Number(produto.precoVenda) > 0 || Number(produto.preco) > 0;

    return this.validar(valido, msg);
  };

  static validar = (valido, msg) => {
    if (!valido) {
      ModalNotification.renderMessage({
        title: "Mensagem",
        body: (
          <Div>
            <Div>
              <Label
                value={msg}
                family="Bold"
                style={{ fontSize: 30, padding: 30 }}
              />
            </Div>
          </Div>
        ),
        handlerConfirm: null,
        handlerCancel: () => {},
        labelButtonCancel: "Fechar",
        responsive: "5",
      });
    }

    return valido;
  };

  static adicionarBusca = (texto) => {
    let buscas = Array.from(
      JSON.parse(localStorage.getItem(Keys.KEY_BUSCAS_DIGITADAS) || "[]")
    );

    if (Utils.isValueValid(buscas)) {
      let buscaJaDigitada = Array.from(buscas).filter(
        (b) => String(b).toUpperCase() === String(texto).toUpperCase()
      )[0];
      if (!Utils.isValueValid(buscaJaDigitada)) {
        if (buscas.length === 8) buscas.splice(0, 1);
        buscas.push(texto);
      }
    } else {
      buscas.push(texto);
    }

    localStorage.setItem(Keys.KEY_BUSCAS_DIGITADAS, JSON.stringify(buscas));
  };

  static calcularValorProduto(produto, quantidade) {
    let isAtacado =
      Number(produto.quantidadeMinimaAtacado) > 0 &&
      Number(produto.precoAtacado) > 0 &&
      Number(quantidade) >= Number(produto.quantidadeMinimaAtacado);
    let precoAplicado = isAtacado ? produto.precoAtacado : produto.precoVenda;

    produto.quantidade = quantidade;
    if (produto.possuiQuantidadeFracionada) {
      produto.valorSubTotalItem = (
        (Number(quantidade) / Number(1000)) *
        Number(precoAplicado)
      ).toFixed(2);
    } else {
      produto.valorSubTotalItem = (
        Number(quantidade) * Number(precoAplicado)
      ).toFixed(2);
    }

    produto.selecionado = true;

    return produto;
  }

  static getNovoValorProduto(produto, quantidade) {
    let isAtacado =
      Number(produto.quantidadeMinimaAtacado) >= Number(quantidade);
    let precoAlicado = isAtacado ? produto.precoAtacado : produto.precoVenda;

    let novoValor = 0.0;

    produto.quantidade = quantidade;
    if (produto.possuiQuantidadeFracionada) {
      novoValor = (
        (Number(quantidade) / Number(1000)) *
        Number(precoAlicado)
      ).toFixed(2);
    } else {
      novoValor = (Number(quantidade) * Number(precoAlicado)).toFixed(2);
    }

    return novoValor;
  }

  // static visualizarProdutoMobile(
  //   produto,
  //   handlerAddItem,
  //   history,
  //   empresa,
  //   listasDeCompra,
  //   handlerAdicionarProdutoLista
  // ) {
  //   ModalNotification.render(
  //     produto.combo ? "Informações do combo" : "Informações do produto",
  //     <ProdutoDetalheMobile
  //       produto={produto}
  //       handlerAddItem={handlerAddItem}
  //       history={history}
  //       empresa={empresa}
  //       listasDeCompraCliente={listasDeCompra}
  //       handlerAdicionarProdutoLista={handlerAdicionarProdutoLista}
  //     />
  //   );
  // }

  static getValorSubTotalItemPeloPrecoUnitario(produto) {
    return Number(produto.precoUnitario) * Number(produto.quantidade);
  }

  static getValorSubTotalItemPeloPrecoVenda(produto) {
    return Number(produto.precoVenda) * Number(produto.quantidade);
  }

  static getQuantidadeItem(produto) {
    return produto.possuiQuantidadeFracionada
      ? Number(produto.quantidade) / Number(1000)
      : Number(produto.quantidade);
  }

  static getQuantidadeItemView(produto) {
    return produto.possuiQuantidadeFracionada
      ? Number(produto.quantidade) * Number(1000)
      : Number(produto.quantidade);
  }
}
