import React, { Component } from "react";
import { Div, ModalNotification } from "../../../components";

import { Utils } from "../../../utilities";

import "../produtos.css";
import ProdutoDetalhe from "../web/ProdutoDetalhe";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { atualizarCarrinho, setProdutos } from "../../../redux/actions";
import ItemProduto from "../../ItemProduto";
import CarouselProdutosGenericoMobile from "../../../components/carousel/CarouselProdutosGenericoMobile";

class ProdutosSugestaoPesquisaMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: props.produtos || [],
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      produtos: props.produtos,
    });
  }

  visualizarProduto = (produto) => {
    ModalNotification.render(
      "Informações do produto",
      <ProdutoDetalhe
        produto={produto}
        quantidadeProduto={1}
        handlerAddItem={(produto, callback) =>
          this.adicionarItemNoCarrinho(produto, callback)
        }
      />
    );
  };

  adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe) {
    let { carrinho, produtos } = this.props;

    let itens = Array.from(carrinho.itens);

    let produtoSelecionado = itens.filter(
      (item) => item.codigoBarras === produto.codigoBarras
    )[0];

    let produtoDaLista = Array.from(produtos).filter(
      (item) => item.codigoBarras === produto.codigoBarras
    )[0];

    if (Utils.isValueValid(produtoDaLista)) {
      produtoDaLista = produto;
    }

    if (Utils.isValueValid(produtoSelecionado)) {
      if (produtoSelecionado.quantidade > 0) {
        produtoSelecionado = produto;
      } else {
        itens.splice(itens.indexOf(produtoSelecionado), 1);
      }
    } else {
      itens.push(produto);
    }

    carrinho.itens = itens;

    this.props.atualizarCarrinho(carrinho);
    this.props.setProdutos(produtos);

    if (Utils.isFunction(atualizarProdutoDetalhe)) {
      atualizarProdutoDetalhe();
    }
  }

  getProdutos = () => {
    let itens = [];

    // let produtosSeparados = Utils.isValueValid(this.props.produtos)
    //   ? Utils.quebrarArray(this.props.produtos,  Utils.isViewMobileLarguraPequeno() ? 3 : 4)
    //: [];

    let produtosSeparados = this.state.produtos;

    console.log(this.props.produtos);

    Array.from(produtosSeparados).forEach((produtos, index) => {
      itens.push({
        value: (
          <Div inline="center" key={index}>
            {Array.from(produtos).map((prod, index) => {
              return (
                <ItemProduto
                  key={index}
                  image={prod.urlImagemPrincipalThumbnail}
                  descricao={prod.descricao}
                  precoUnitario={prod.precoUnitario}
                  precoVenda={prod.precoVenda}
                  precoAtacado={prod.precoAtacado}
                  codigoBarras={prod.codigoBarras}
                  handlerClick={() => this.visualizarProduto(prod)}
                  quantidade={prod.quantidade}
                  handlerAddItemCarrinho={(quantidade) => {
                    prod.quantidade = quantidade;
                    prod.valorSubTotalItem = (
                      Number(quantidade) * Number(prod.precoVenda)
                    ).toFixed(2);
                    prod.selecionado = true;
                    this.adicionarItemNoCarrinho(prod);
                  }}
                />
              );
            })}
          </Div>
        ),
        active: index === 0,
      });
    });

    return itens;
  };

  render() {
    console.log(this.props.produtos);
    return (
      <Div>
        <CarouselProdutosGenericoMobile itens={this.getProdutos()} />
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ atualizarCarrinho, setProdutos }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProdutosSugestaoPesquisaMobile);
